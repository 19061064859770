import React from 'react';
import classes from './PartnerTeam.module.scss';
import cx from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { getIcon } from './constants';

const RoleInfoTooltip = ({ role, abilities, target, className, shortName }) => {
  return (
    <UncontrolledTooltip
      hideArrow
      placement="left-start"
      className={classes.tooltip}
      target={target}
    >
      <div className={cx(classes.tooltipRole, classes[className])}>
        <div className={classes.content}>
          <div className={classes.title}>
            {getIcon(role)} <h4>{className}</h4>
          </div>
          {abilities?.allowed?.length !== 0 && (
            <div className={classes.privileges}>
              <h5>
                <i className="fa fa-check" aria-hidden="true"></i>
                {shortName}s can:{' '}
              </h5>
              <ul className={classes.list}>
                {abilities?.allowed?.map((privileges, index) => (
                  <li key={index}>{privileges}</li>
                ))}
              </ul>
            </div>
          )}
          {abilities?.denied?.length !== 0 && (
            <div className={classes.prohibitions}>
              <h5>
                <i className="fa fa-times" aria-hidden="true"></i>
                {shortName}s can't:
              </h5>
              <ul className={classes.list}>
                {abilities?.denied?.map((privileges, index) => (
                  <li key={index}>{privileges}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </UncontrolledTooltip>
  );
};

export default RoleInfoTooltip;
