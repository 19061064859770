import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';

import classes from './PartnerDashboard.module.scss';
import StatusChart from 'views/pages/dashboards/PartnerDashboard/StatusChart';
import PartnersTable from 'views/pages/dashboards/PartnerDashboard/PartnersTable';
import _ from 'lodash';
import {
  fetchPartnerDashboard,
  clearReferralCommentsData,
} from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import millify from 'millify';
import queryString from 'query-string';
import Loading from 'components/Loading';
import { useLocation } from 'react-router';

import LeadModal from './LeadModal';
import history from 'helpers/history';
import SubmitReferrals from 'components/Sidebar/SubmitReferral';
import PartnerOnBoarding from 'views/pages/PartnerOnBoarding';
const PartnerDashboard = () => {
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [isModal, setIsModal] = useState(false);
  const [leadId, setLeadId] = useState('');
  const [activeComment, setActiveComment] = useState(null);
  const [modalType, setModalType] = useState('referral');
  const [currentTab, setCurrentTab] = useState('comment');
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [isSubmitReferralFormOpen, setSubmitReferralFormOpen] = useState(false);

  const parentId = useSelector(({ auth }) => auth.user.company.id, null);

  const loading = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.isInProgress', false)
  );

  const inProgressData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.active_referrals', [])
  );
  const closedData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.closed', [])
  );
  const needsAttentionData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.needs_attention', [])
  );
  const disqualifiedData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.disqualified', [])
  );
  const counts = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.counts', {})
  );
  const estimatedEarnings = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.estimated_earnings', '$0')
  );
  const lifetimeEarnings = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.lifetime_earnings', '$0')
  );
  const opportunity_types = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.opportunity_types', {})
  );
  const showEarning = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.show_earning', true)
  );
  useEffect(() => {
    if (
      inProgressData.length > 0 ||
      needsAttentionData.length > 0 ||
      disqualifiedData > 0 ||
      _.some(_.values(counts), c => c && c > 0) ||
      (estimatedEarnings != '$0.00' && estimatedEarnings != 0) ||
      (lifetimeEarnings != '$0.00' && lifetimeEarnings != 0) ||
      _.some(_.flatMap(opportunity_types), c => c.RnD > 0 || c.ertc > 0)
    ) {
      setIsEmptyState(false);
    } else {
      setIsEmptyState(true);
    }
  }, [
    counts,
    disqualifiedData,
    estimatedEarnings,
    inProgressData.length,
    lifetimeEarnings,
    needsAttentionData.length,
    opportunity_types,
    showEarning,
  ]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPartnerDashboard());
  }, [dispatch]);

  useEffect(() => {
    const { action = '', lead_id = '' } = queryProps;
    if (action === 'lead_activity' && lead_id) {
      setLeadId(lead_id);
      setIsModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { lead = '', tab = '', type = '', comment } = queryProps;
    if (lead) {
      setLeadId(lead);
      setCurrentTab(tab);
      setModalType(type);
      setIsModal(true);
      setActiveComment(comment);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps.lead]);

  useEffect(() => {
    const { action = '', lead_id = '' } = queryProps;
    if (action === 'lead_activity' && lead_id) {
      setLeadId(lead_id);
      setIsModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps]);

  const getFormattedCurrency = value => {
    return millify(parseFloat(value.substring(1).replaceAll(',', '')), {
      lowercase: true,
      precision: 0,
    });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {isModal ? (
        <LeadModal
          parentId={parentId}
          leadId={leadId}
          isOpen={isModal}
          modalType={modalType}
          closeModal={() => {
            setIsModal(false);
            dispatch(clearReferralCommentsData());
            history.push('?');
          }}
          currentTab={currentTab}
          comment={activeComment}
        />
      ) : null}
      {isEmptyState ? (
        <Container className={classes.emptyData}>
          <div className={classes.emptyDataHeading}>Referrals</div>
          <div className={classes.emptyText}>
            Use this section to track and review your Client and Partner
            Referrals. See where
            <br /> they are at in the process and what may need your attention.
          </div>
          <Button color="info" onClick={() => setSubmitReferralFormOpen(true)}>
            Submit Referral
          </Button>
        </Container>
      ) : (
        <Container fluid className={classes.partnerDashboard}>
          <div className={classes.header}>
            <span className={classes.title}>Client Referral Tracker</span>
            {/*<div className={classes.dropdownWrapper}>*/}
            {/*  <span className={classes.dropdownLabel}>DATE RANGE</span>*/}
            {/*  <Dropdowns*/}
            {/*    dropdownClasses={cs('ml-auto', classes.customDropdown)}*/}
            {/*    className="m-0 p-1 ml-1"*/}
            {/*    text={*/}
            {/*      <div className={classes.caret}>*/}
            {/*        <span>{period.text}</span> <i className="fas fa-caret-down" />{' '}*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*    active={period.value}*/}
            {/*    size="sm"*/}
            {/*    role="button"*/}
            {/*    caret={false}*/}
            {/*    color=""*/}
            {/*    options={periodOptions}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
          <div className={classes.infoWrapper}>
            <div>
              <p>Total Active Referrals</p>
              <p>{counts?.active_referrals || 0}</p>
            </div>
            <div>
              <p>Total Opportunities</p>
              <p>{counts?.opportunities || 0}</p>
            </div>
            {showEarning && (
              <div>
                <p> Total Paid Earnings</p>
                <p>${getFormattedCurrency(lifetimeEarnings)}</p>
              </div>
            )}
            <div>
              <p>Days Since Last Referral</p>
              <p>{counts?.days_since_last_referral}</p>
            </div>
          </div>
          <StatusChart counts={counts} opportunityTypes={opportunity_types} />
          <div className={classes.tables}>
            <PartnersTable
              type="in_progress_type"
              headerText="Active Referrals"
              data={inProgressData}
            />
            <PartnersTable
              type="needs_attention_type"
              headerText="Needs Attention"
              data={needsAttentionData}
            />
            <PartnersTable
              type="closed_type"
              headerText="Closed"
              data={closedData}
            />
            <PartnersTable
              type="disqualified_type"
              headerText="Disqualified"
              data={disqualifiedData}
            />
          </div>
        </Container>
      )}
      <SubmitReferrals
        isOpenSubmitReferrals={isSubmitReferralFormOpen}
        closeModal={() => setSubmitReferralFormOpen(false)}
      ></SubmitReferrals>
      <PartnerOnBoarding isOpen={true} />
    </>
  );
};
export default PartnerDashboard;
