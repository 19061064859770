import React from 'react';
import classes from './TabELReview.module.scss';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import CommentFeature from 'views/pages/DealDesk/DealDeskModal/CommentFeature';
import moment from 'moment';

export const TabELReview = () => {
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data?.el_review;

  const addressKeys = [
    'Address line 1',
    'Address line 2',
    'City',
    'State',
    'Zipcode',
  ];

  const showContent = (data, key) => {
    const dateFields = [
      'Date of Incorporation',
      'R&D - Estimated Delivery Date',
      'ERTC - Estimated Delivery Date',
    ];
    if (dateFields.includes(key)) {
      return moment(data[key]).format('MM/DD/YYYY');
    }
    return data[key];
  };

  return (
    <div className={classes.tabELReview}>
      <Row>
        {addressKeys.map((key, index) => {
          if (!get(data, key, '')) return '';

          return (
            <Col md={6} key={index}>
              <div className={classes.label}>
                {key}{' '}
                <CommentFeature
                  field={`el_review.${key}`}
                  label={key}
                  value={showContent(data, key)}
                />
              </div>
              <div className={classes.value}>{data[key]}</div>
            </Col>
          );
        })}

        {data &&
          Object.keys(data)
            .filter(key =>
              [...addressKeys, 'Notes'].includes(key) ? false : true
            )
            .map((key, index) => (
              <Col md={6} key={index}>
                <div className={classes.label}>
                  {key}{' '}
                  <CommentFeature
                    field={`el_review.${key}`}
                    label={key}
                    value={showContent(data, key)}
                  />
                </div>
                <div className={classes.value}>{showContent(data, key)}</div>
              </Col>
            ))}
      </Row>
      <Row>
        {get(data, 'Notes') && (
          <Col md={12}>
            <div className={classes.label}>
              Notes{' '}
              <CommentFeature
                field={`el_review.Notes`}
                label="Notes"
                value={data['Notes']}
              />
            </div>
            <div
              className={classes.value}
              dangerouslySetInnerHTML={{ __html: data['Notes'] }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
