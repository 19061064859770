import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import history from 'helpers/history';
import SubmitReferralForm from 'components/Sidebar/SubmitReferralForm';
import CalendlyModal from 'components/Sidebar/CalendlyModal';

const SubmitReferrals = ({ isOpenSubmitReferrals, closeModal }) => {
  const [isSubmitReferralFormOpen, setSubmitReferralFormOpen] = useState(
    isOpenSubmitReferrals
  );
  const [isScheduleMeetingModal, setIsScheduleMeetingModal] = useState(false);
  const [calendlyLink, setCalendlyLink] = useState('');
  const isCalendlyLoading = useSelector(
    ({ partner }) =>
      get(partner, 'calendlyDetails.isInProgress', false) || false
  );
  useEffect(() => {
    setSubmitReferralFormOpen(isOpenSubmitReferrals);
  }, [isOpenSubmitReferrals]);
  return (
    <>
      <SubmitReferralForm
        isOpen={isSubmitReferralFormOpen}
        closeModal={() => {
          setSubmitReferralFormOpen(false);
          closeModal();
        }}
        openMeetingModal={link => {
          setIsScheduleMeetingModal(true);
          setCalendlyLink(link);
        }}
      />
      <CalendlyModal
        link={calendlyLink}
        isOpen={isScheduleMeetingModal}
        isLoading={isCalendlyLoading}
        modalClose={() => {
          setIsScheduleMeetingModal(false);
          history.push('?');
        }}
      />
    </>
  );
};
export default SubmitReferrals;
