import Request from './request';

export const getTaxReturnsSummary = async () => {
  return Request.call({
    url: ``,
    method: 'GET',
  });
};

export const postTaxReturnsFormValidation = async data => {
  return Request.call({
    url: '/financial-year-documents/form',
    method: 'POST',
    data,
  });
};
export const deleteTaxReturnsFormValidation = async id => {
  return Request.call({
    url: `/financial-year-documents/form/${id}`,
    method: 'DELETE',
  });
};
export const getTaxReturnStatus = async documentId => {
  return Request.call({
    url: `financial-year-documents/document/${documentId}/status`,
    method: 'GET',
  });
};
export const postTaxReturnStatus = async (documentId, data) => {
  return Request.call({
    url: `financial-year-documents/document/${documentId}/status`,
    method: 'POST',
    data,
  });
};
export const getFormTypes = async () => {
  return Request.call({
    url: `financial-year-documents/forms`,
    method: 'GET',
  });
};

export const getFilledForm = async documentId => {
  return Request.call({
    url: `/financial-year-documents/form/${documentId}`,
    method: 'GET',
  });
};
