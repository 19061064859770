import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import _ from 'lodash';

import classes from './earnings.module.scss';
import EstimatedChart from './EstimatedChart';
import EarningTable from './EarningTable';
import { withCurrency } from 'helpers/constants';
import { fetchEarnings } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';
import useBreakpoint from 'helpers/useBreakPoint';
import SubmitReferrals from 'components/Sidebar/SubmitReferral';

const Earnings = () => {
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [isSubmitReferralFormOpen, setSubmitReferralFormOpen] = useState(false);

  const dispatch = useDispatch();
  const isMobile = useBreakpoint('xs', 'down');
  const earningsInProgress = useSelector(({ partner }) =>
    get(partner, 'earnings.isInProgress', false)
  );
  const estimatedEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.estimated_earnings', {})
  );

  const estimatedBreakdown = useSelector(({ partner }) =>
    get(partner, 'earnings.data.earnings_breakdown', {})
  );

  const payments = useSelector(({ partner }) =>
    get(partner, 'earnings.data.payments', {})
  );
  const receivedPayouts = useSelector(({ partner }) =>
    get(partner, 'earnings.data.received_payouts', [])
  );
  const pendingPayouts = useSelector(({ partner }) =>
    get(partner, 'earnings.data.pending_payouts', [])
  );
  const referralEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.referral_earnings', [])
  );
  const partnerEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.partner_earnings', [])
  );

  useEffect(() => {
    dispatch(fetchEarnings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      _.some(_.values(estimatedBreakdown), c => c && c > 0) ||
      referralEarnings.length > 0 ||
      pendingPayouts.length > 0
    ) {
      setIsEmptyState(false);
    } else {
      setIsEmptyState(true);
    }
  }, [
    estimatedBreakdown,
    pendingPayouts,
    receivedPayouts,
    referralEarnings,
    estimatedEarnings,
  ]);

  if (earningsInProgress) {
    return <Loading />;
  }
  return (
    <>
      {isEmptyState ? (
        <Container className={classes.emptyData}>
          <div className={classes.emptyDataHeading}>Earnings</div>
          <div className={classes.emptyText}>
            View projections on what you will earn when Strike completes their
            work and track
            <br /> what you have been paid!
          </div>
          <Button color="info" onClick={() => setSubmitReferralFormOpen(true)}>
            Submit Referral
          </Button>
        </Container>
      ) : (
        <Container fluid className={classes.earnings}>
          <p className={classes.title}>Earnings</p>
          <div className="row">
            <div className="col-md-8">
              <div className={classes.infoWrapper}>
                <div className="col-md-6">
                  <div className={classes.boxInfo}>
                    <p>
                      Most Recent
                      <br />
                      Payout
                    </p>
                    <p>
                      {payments.last_payment
                        ? withCurrency(payments.last_payment, {
                            isShorted: true,
                          })
                        : '$0'}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={classes.boxInfo}>
                    <p>
                      Total Pending <br /> Payouts{' '}
                    </p>
                    <p>{payments.next_payment || '$0'}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={classes.boxInfo}>
                    <p>
                      Paid <br /> (year to date)
                    </p>
                    <p>
                      {payments?.year_to_date_payments
                        ? withCurrency(payments?.year_to_date_payments, {
                            isShorted: true,
                          })
                        : '$0'}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={classes.boxInfo}>
                    <p>
                      Paid <br />
                      (all time)
                    </p>
                    <p>
                      {payments?.total_payments
                        ? withCurrency(payments?.total_payments, {
                            isShorted: true,
                          })
                        : '$0'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <EstimatedChart
                futureData={estimatedEarnings}
                comparedData={estimatedEarnings}
                estimatedBreakdown={estimatedBreakdown}
              />
            </div>
          </div>
          <EarningTable
            header={'Your Earnings by Client'}
            type="client_earnings"
            data={referralEarnings}
          />
          <EarningTable
            header={'Your Earnings by Partner'}
            type="partner_earnings"
            data={partnerEarnings}
          />
          <div className="col-6">
            <EarningTable
              header={'Payouts Disbursed'}
              type="payouts_disbursed"
              data={receivedPayouts}
            />
          </div>
          <SubmitReferrals
            isOpenSubmitReferrals={isSubmitReferralFormOpen}
            closeModal={() => setSubmitReferralFormOpen(false)}
          ></SubmitReferrals>
        </Container>
      )}
    </>
  );
};
export default Earnings;
