import Types from '../types/partnerTeams';
const initialState = {
  partnerTeamState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  partnerTeamRoleState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  partnerTeamStatusState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  inviteTeamMember: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateTeamMember: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  member: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  memberStatus: {
    isInProgress: false,
    isError: false,
    status: '',
    message: '',
  },
};

export default function PartnerTeams(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PARTNER_TEAM_INPROGRESS:
      return {
        ...state,
        partnerTeamState: {
          ...state.partnerTeamState,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNER_TEAM_SUCCESS:
      return {
        ...state,
        partnerTeamState: {
          ...state.partnerTeamState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNER_TEAM_FAILURE:
      return {
        ...state,
        partnerTeamState: {
          ...state.partnerTeamState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNER_TEAM_ROLE_INPROGRESS:
      return {
        ...state,
        partnerTeamRoleState: {
          ...state.partnerTeamRoleState,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNER_TEAM_ROLE_SUCCESS:
      return {
        ...state,
        partnerTeamRoleState: {
          ...state.partnerTeamRoleState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNER_TEAM_ROLE_FAILURE:
      return {
        ...state,
        partnerTeamRoleState: {
          ...state.partnerTeamRoleState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNER_TEAM_STATUS_INPROGRESS:
      return {
        ...state,
        partnerTeamStatusState: {
          ...state.partnerTeamStatusState,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNER_TEAM_STATUS_SUCCESS:
      return {
        ...state,
        partnerTeamStatusState: {
          ...state.partnerTeamStatusState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNER_TEAM_STATUS_FAILURE:
      return {
        ...state,
        partnerTeamStatusState: {
          ...state.partnerTeamStatusState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_INVITE_TEAM_MEMBER_INPROGRESS:
      return {
        ...state,
        inviteTeamMember: {
          ...state.inviteTeamMember,
          isInProgress: true,
        },
      };
    case Types.POST_INVITE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        inviteTeamMember: {
          ...state.inviteTeamMember,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.POST_INVITE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        inviteTeamMember: {
          ...state.inviteTeamMember,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_TEAM_MEMBER_INPROGRESS:
      return {
        ...state,
        updateTeamMember: {
          ...state.updateTeamMember,
          isInProgress: true,
        },
      };
    case Types.UPDATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        updateTeamMember: {
          ...state.updateTeamMember,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        updateTeamMember: {
          ...state.updateTeamMember,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MEMBER_INPROGRESS:
      return {
        ...state,
        member: {
          ...state.member,
          isInProgress: true,
        },
      };
    case Types.FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        member: {
          ...state.member,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_MEMBER_FAILURE:
      return {
        ...state,
        member: {
          ...state.member,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_MEMBER_STATUS_INPROGRESS:
      return {
        ...state,
        memberStatus: {
          ...state.memberStatus,
          isInProgress: true,
        },
      };
    case Types.POST_MEMBER_STATUS_SUCCESS:
      return {
        ...state,
        memberStatus: {
          ...state.memberStatus,
          isInProgress: false,
          status: action.data,
        },
      };
    case Types.POST_MEMBER_STATUS_FAILURE:
      return {
        ...state,
        memberStatus: {
          ...state.memberStatus,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
