import React from 'react';
import { Badge } from 'reactstrap';
import { ReactComponent as StatusChange } from 'assets/img/icons/repeat-filled.svg';
import { ReactComponent as Upload } from 'assets/img/icons/upload-filled.svg';
import { ReactComponent as Comment } from 'assets/img/icons/comment-filled.svg';

import cx from 'classnames';
import classes from './Interactions.module.scss';

import { useDispatch } from 'react-redux';
import ContentEditor from 'components/ContentEditor';
import Avatar from 'components/Avatar';
import { withTime } from 'helpers/times';

const InteractionItem = props => {
  const dispatch = useDispatch();
  const { interaction, handleMessageClick, user, userTimezone } = props;

  const getMessageIcon = () => {
    switch (interaction?.type) {
      case 'FileAdded':
        return <Upload />; //DocumentStatusChanged
      case 'DocumentStatusChanged':
        return (
          <StatusChange
            fill={getStatusColor(interaction?.data?.newStatus?.label)}
          />
        );
      case 'DocumentCommentAdded':
      case 'CommentAdded':
      default:
        return <Comment />;
    }
  };

  const getMessage = interaction => {
    switch (interaction?.type) {
      case 'FileAdded':
        return (
          <ContentEditor
            className={classes.fileAdded}
            content={interaction?.data?.document?.name}
          />
        );
      case 'DocumentCommentAdded':
      default:
        return (
          <ContentEditor
            className={classes.documentCommentAdded}
            content={interaction?.data?.comment?.message || ''}
          />
        );
    }
  };

  const userName = (
    <b style={{ color: '#000' }}>
      {user?.id === interaction.user?.id ? 'You' : interaction?.user?.name}
    </b>
  );

  const getStatusColor = label => {
    switch (label) {
      case 'Not Started':
        return '#32325D';
      case 'Ready For Review':
        return '#1DA1F2';
      case 'Needs Attention':
        return '#EC0C38';
      case 'In Review':
        return '#E9B411';
      case 'Completed':
        return '#24A46D';
      default:
        return '#32325D';
    }
  };

  const getActivityText = () => {
    const date = withTime(interaction?.created_at, {
      tz: userTimezone,
      format: 'date-time',
    });
    switch (interaction?.type) {
      case 'FileAdded':
        return (
          <span className={classes.container}>
            <span>
              {userName} uploaded a file to{' '}
              <b
                onClick={() => handleMessageClick(interaction)}
                style={{ color: '#000', cursor: 'pointer' }}
              >
                {interaction?.data?.documentType?.name}
              </b>{' '}
            </span>
            <span className={classes.date}>{date}</span>
          </span>
        );
      case 'CommentAdded':
        return (
          <span className={classes.container}>
            <span>
              {userName} messaged in{' '}
              <b
                onClick={() => handleMessageClick(interaction)}
                style={{ color: '#000', cursor: 'pointer' }}
              >
                {interaction?.data?.project?.name}
              </b>{' '}
            </span>
            <span className={classes.date}>{date}</span>
          </span>
        );
      case 'DocumentStatusChanged':
        return (
          <span className={classes.container}>
            <span className={classes.infoSection}>
              {userName} changed status of{' '}
              <b
                onClick={() => handleMessageClick(interaction)}
                style={{ color: '#000', cursor: 'pointer' }}
              >
                {interaction?.data?.documentType?.name}
              </b>{' '}
            </span>
            <span className={classes.statusSection}>
              <Badge
                className="badge-dot mr-2"
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  color: '#000',
                  padding: '8px 6px',
                }}
              >
                <i
                  style={{
                    backgroundColor: getStatusColor(
                      interaction?.data?.newStatus?.label
                    ),
                  }}
                />
                <span className={classes.statusLabel}>
                  <b>{interaction?.data?.newStatus?.label}</b>
                </span>
                <div
                  style={{
                    position: 'absolute',
                    inset: 0,
                    opacity: 0.2,
                    backgroundColor: getStatusColor(
                      interaction?.data?.newStatus?.label
                    ),
                  }}
                />
              </Badge>
              <span className={classes.date}>{date}</span>
            </span>
          </span>
        );
      case 'DocumentCommentAdded':
      default:
        return (
          <span className={classes.container}>
            <span>
              {userName} messaged in{' '}
              <b
                onClick={() => handleMessageClick(interaction)}
                style={{ color: '#000', cursor: 'pointer' }}
              >
                {interaction?.data?.documentType?.name}
              </b>{' '}
            </span>
            <span className={classes.date}>{date}</span>
          </span>
        );
    }
  };

  const Activity = ({ interaction, isGrouped = false }) => {
    return (
      <div
        onClick={() => handleMessageClick(interaction)}
        className={cx(classes.activityCard, { [classes.grouped]: isGrouped })}
      >
        {!isGrouped && (
          <Avatar className={classes.avatar} url={interaction?.user?.avatar} />
        )}
        <div className={cx(classes.content, { [classes.grouped]: isGrouped })}>
          {isGrouped && <i className="far fa-regular fa-file"></i>}
          <div className={classes.cardText}>{getMessage(interaction)}</div>
          <div className={classes.messageOpenAction}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    );
  };

  const renderActivity = () => {
    if (
      interaction?.grouped_activities?.length > 1 &&
      interaction?.type === 'FileAdded'
    ) {
      return (
        <div className={classes.groupedActivities}>
          {interaction?.grouped_activities?.map((interaction, index) => {
            if (interaction?.type !== 'FileAdded') return;
            return (
              <Activity
                key={index}
                interaction={interaction}
                isGrouped={true}
              />
            );
          })}
        </div>
      );
    } else {
      return <Activity interaction={interaction} />;
    }
  };

  return (
    <>
      <div className={classes.activityWrapper}>
        <div className={classes.activity}>
          <div className={classes.avatarWrapper}>
            <div className={classes.timelineWrapper}>
              {getMessageIcon()}
              <div className={classes.timeline} />
            </div>
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.activityText}>{getActivityText()}</div>
          </div>
        </div>
        {interaction?.type !== 'DocumentStatusChanged' && (
          <div className={classes.activityAction}>
            <div></div>
            <div className={classes.messageCardWrapper}>
              {' '}
              {renderActivity()}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InteractionItem;
