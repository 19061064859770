import Button from 'components/Button';
import { FormikInput } from 'components/FormFields/Input';
import React, { useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/img/icons/info-circle-dark-icon.svg';
import { isEqual, get, map } from 'lodash';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from 'reactstrap';
import { components } from 'react-select';
import * as Yup from 'yup';
import cx from 'classnames';
import classes from './EditTeamMember.module.scss';
import RSelect from 'components/FormFields/RSelect';
import { Field, Formik } from 'formik';
import { phoneNumberRegex } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTimezones } from 'store/actions/metadata';
import { getIcon } from '../constants';
import { AlertPopupHandler } from 'components/AlertPopup';
import RoleInfoTooltip from '../RoleInfoTooltip';
import history from 'helpers/history';
import {
  fetchMember,
  postMemberStatus,
  updateTeamMember,
} from 'store/actions/partnerTeams';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import Loading from 'components/Loading';

const selectOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const OptionComponent = ({
  innerProps = {},
  additionalClassName = '',
  data,
  selectedRole,
}) => {
  const { name, description, abilities = {}, shortName, className } = data;
  return (
    <div
      {...innerProps}
      className={cx(
        additionalClassName,
        'd-flex align-items-center',
        classes.option
      )}
    >
      <div
        className={cx(classes.role, classes[className], {
          [classes.active]: name === selectedRole?.name,
        })}
      >
        <div className={classes.icon}>{getIcon(name)}</div>
        <div className={classes.content}>
          <div className={classes.details}>
            <h3>{className}</h3>
            <p>{description}</p>
          </div>
          <div className={classes.tooltip}>
            <InfoIcon id={`member-${name}ToolTip`} />
            <RoleInfoTooltip
              className={className}
              shortName={shortName}
              role={name}
              abilities={abilities}
              target={`member-${name}ToolTip`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EditTeamMember = props => {
  const dispatch = useDispatch();
  const memberId = get(props, 'match.params.id');

  const timezones = useSelector(({ metadata }) =>
    get(metadata, 'timezones.data', {})
  );
  const loading = useSelector(({ partnerTeam }) =>
    get(partnerTeam, 'updateTeamMember.isInProgress', false)
  );
  const memberLoading = useSelector(({ partnerTeam }) =>
    get(partnerTeam, 'member.isInProgress', false)
  );
  const member = useSelector(({ partnerTeam }) =>
    get(partnerTeam, 'member.data', {})
  );

  const status = get(member, 'status.status');

  const [memberStatus, setMemberStatus] = useState(status);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (status === 'Pending') {
      setMemberStatus('Active');
    } else {
      setMemberStatus(status);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    dispatch(fetchMember(memberId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  const timezonesData = Object.entries(timezones).map(([id, name]) => ({
    value: id,
    label: name,
  }));

  const handleStatusChange = async memberStatus => {
    const { status = 0, data } = await dispatch(
      postMemberStatus(member.id, memberStatus)
    );
    if (status === 1) {
      setMemberStatus(data?.status?.status);
    }
  };

  const getAdditionalRoleProps = name => {
    switch (name) {
      case 'Partner_Representative':
        return {
          shortName: 'rep',
          className: 'representative',
          inviteButtonText: 'Invite a Rep',
          abilities: {
            allowed: [
              'Make and track their own referrals.',
              'See earnings they sourced* (*if authorized by an Admin)',
            ],
            denied: [
              'View or manage their teammates.',
              'Add or deactivate users',
            ],
          },
        };
      case 'Partner_Team_Manager':
        return {
          shortName: 'manager',
          className: 'manager',
          inviteButtonText: 'Invite a Manager',
          abilities: {
            allowed: [
              'Invite Reps to their team.',
              'View referrals, earnings, and performance of their team.',
              'Deactivate Reps from their team.',
            ],
            denied: [
              'View or manage other teams.',
              'Add or deactivate Admins.',
            ],
          },
        };
      case 'Partner_Administrator':
      default:
        return {
          shortName: 'admin',
          className: 'admin',
          inviteButtonText: 'Invite an Admin',
          abilities: {
            allowed: [
              'Invite Admins, Managers, and Reps to the team and assign user roles.',
              'Assign Supervisors to Reps.',
              'View the organization’s referrals, earnings, and performance.',
              'Deactivate team members.',
            ],
            denied: [],
          },
        };
    }
  };

  let memberRoles = useSelector(partnerTeam => {
    const applicableRoles = get(
      partnerTeam,
      'partnerTeam.partnerTeamRoleState.data',
      []
    );
    return map(applicableRoles, role => {
      switch (role.name) {
        case 'Partner_Administrator':
          return {
            ...role,
            ...getAdditionalRoleProps('Partner_Administrator'),
          };
        case 'Partner_Team_Manager':
          return {
            ...role,
            ...getAdditionalRoleProps('Partner_Team_Manager'),
          };
        case 'Partner_Representative':
          return {
            ...role,
            ...getAdditionalRoleProps('Partner_Representative'),
          };
        default:
          return role;
      }
    });
  });

  useEffect(() => {
    dispatch(fetchTimezones());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth = useSelector(({ auth }) => auth);
  const partnerRevenueShare = get(auth, 'user.see_earning', '');
  const userRoles = get(auth, 'user.roles', '');
  if (userRoles.includes('Partner_Team_Manager'))
    memberRoles = memberRoles.filter(r => r.name == 'Partner_Representative');
  const { roles = [{}] } = member;
  const [initialRole = {}] = roles;

  let initialValues = {
    first_name: member?.first_name || '',
    last_name: member?.last_name || '',
    phone: member?.phone || '',
    email_address: member?.email || '',
    title: member?.title || '',
    timezone: member?.timezone || '',
    timezone_offset: member?.timezone_offset || '',
    street: member?.street || '',
    city_state_zip: member?.city || '',
    role: { ...initialRole, ...getAdditionalRoleProps(initialRole?.name) },
    supervisor: member?.supervisors?.[0] || null,
    see_earning: !!member?.see_earning || false,
  };

  const TeamMemberSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone: Yup.string()
      .matches(phoneNumberRegex, 'Must be a valid number')
      .typeError('Phone is required')
      .required('Phone is required'),
    email_address: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    title: Yup.string().required('Title is required'),
    timezone: Yup.string(),
    timezone_offset: Yup.string(),
    street: Yup.string(),
    city_state_zip: Yup.string(),
    role: Yup.object(),
    supervisor: Yup.object().nullable(),
    see_earning: Yup.boolean().required(),
  });

  const getRoleSwitchWarning = (initialRole, selectedRole) => {
    if (initialRole.name === 'Partner_Representative') {
      return (
        <p>
          <b>Warning</b>:{' '}
          <span>
            By switching a {initialRole.shortName} role to{' '}
            {selectedRole.shortName}, you will be{' '}
            <strong>
              removing them from {member.supervisors?.[0]?.name}’s team.
            </strong>
          </span>
        </p>
      );
    }
  };

  const TeamEditForm = ({
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
    resetForm,
  }) => {
    const isFormEdited = !isEqual(initialValues, values);
    return (
      <Form className={classes.form} role="form" onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader}>
            <h3>User Information</h3>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <div>
              <Row>
                <Col xs={12} sm={6}>
                  <h5>First Name</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="first_name"
                    placeholder="Jane"
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <h5>Last Name</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="last_name"
                    placeholder="Doe"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <h5>Phone Number</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="phone"
                    placeholder="209-546-4321"
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <h5>Email Address</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="email_address"
                    placeholder="email@example.com"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <h5>Title</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="title"
                    placeholder="CEO/Owner"
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <h5>Time Zone</h5>
                  <RSelect
                    id="timezone"
                    name="timezone"
                    className={cx(classes.timezone, 'mb-4')}
                    isClearable={false}
                    defaultValue={
                      values.timezone
                        ? {
                            label:
                              get(values, 'timezone') +
                              ' ' +
                              get(values, 'timezone_offset'),
                            value: get(values, 'timezone'),
                          }
                        : null
                    }
                    onChange={e => {
                      setFieldValue('timezone', e.value);
                    }}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    touched={get(touched, 'timezone')}
                    error={get(errors, 'timezone')}
                    options={timezonesData}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <h5>Street Address</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="street"
                    placeholder="123 Street"
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <h5>City, State, Zip</h5>
                  <FormikInput
                    groupClassName="mb-4"
                    name="city_state_zip"
                    placeholder="City, State, Zip"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col className={classes.roleSelect}>
                  <h5>Role</h5>
                  <Field name="role">
                    {({ field, form: { touched, errors } }) => {
                      return (
                        <RSelect
                          menuPlacement="top"
                          isSearchable={false}
                          isClearable={false}
                          touched={touched}
                          {...field}
                          options={memberRoles}
                          getOptionLabel={option => (
                            <OptionComponent
                              additionalClassName={cx(
                                classes.textWrap,
                                'h-100'
                              )}
                              data={option}
                              // selectedRole={values.role}
                            />
                          )}
                          onChange={selectedOption =>
                            setFieldValue('role', selectedOption)
                          }
                          error={get(errors, 'role')}
                          components={{
                            Control: ({ children, ...props }) => {
                              return (
                                <components.Control
                                  {...props}
                                  className={classes.control}
                                >
                                  {children}
                                </components.Control>
                              );
                            },
                            SingleValue: ({ children, ...props }) => {
                              return (
                                <components.SingleValue
                                  {...props}
                                  className={classes.singleValue}
                                >
                                  {children}
                                </components.SingleValue>
                              );
                            },
                            ValueContainer: ({ children, ...props }) => {
                              return (
                                <components.ValueContainer
                                  {...props}
                                  className={classes.valueContainer}
                                >
                                  {children}
                                </components.ValueContainer>
                              );
                            },
                            Option: ({
                              innerProps,
                              data,
                              getStyles,
                              isDisabled,
                              isFocused,
                              isSelected,
                              cx,
                              ...allProps
                            }) => {
                              return (
                                <OptionComponent
                                  innerProps={innerProps}
                                  selectedRole={values.role}
                                  additionalClassName={cx({
                                    ...getStyles('option', {
                                      ...allProps,
                                      innerProps,
                                      data,
                                    }),
                                    option: true,
                                    'option--is-disabled': isDisabled,
                                    'option--is-focused': isFocused,
                                    'option--is-selected': isSelected,
                                  })}
                                  data={data}
                                />
                              );
                            },
                          }}
                        />
                      );
                    }}
                  </Field>
                  {!isEqual(initialValues.role, values.role) && (
                    <div className={classes.roleSwitchWarning}>
                      {getRoleSwitchWarning(initialValues.role, values.role)}
                    </div>
                  )}
                </Col>
              </Row>
              {values?.role?.name === 'Partner_Representative' && (
                <Row>
                  <Col className={classes.supervisor}>
                    <h2>Assign a Supervisor </h2>
                    <h3>
                      This representative will be assigned to the Supervisor you
                      select below:
                    </h3>
                    <Row>
                      <Col>
                        <RSelectAsync
                          url={'/users/list/dropdown?role=manager'}
                          className={cx('mb-4', classes.supervisorSelect)}
                          name={'supervisor'}
                          value={values.supervisor}
                          getOptionLabel={option => option.name}
                          placeholder={'Select'}
                          onChange={selectedOption => {
                            setFieldValue('supervisor', selectedOption);
                          }}
                          isClearable={true}
                          styles={{
                            width: '300px',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {partnerRevenueShare == 1 &&
                values.role === 'Partner_Representative' && (
                  <Row>
                    <Col className={classes.allowEarningView}>
                      <h2 className={classes.required}>
                        Allow them to see earnings from their referrals?
                      </h2>
                      <h3>
                        Enables Reps to view the earnings they generate for the
                        company from their referrals.
                      </h3>
                      <div className={classes.typeWrapper}>
                        {selectOptions.map(type => {
                          return (
                            <span
                              key={`${type.label}-type`}
                              className={cx(
                                classes.type,
                                values.see_earning === type.value
                                  ? classes.active
                                  : ''
                              )}
                              onClick={() => {
                                setFieldValue('see_earning', type.value);
                              }}
                            >
                              {type?.label}
                            </span>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                )}
              <Row>
                <Col>
                  <h5>Status</h5>
                  <div className={classes.statusAction}>
                    <Button className={classes.statusButton} outlined disabled>
                      {memberStatus}
                    </Button>
                    <Button
                      className={classes[memberStatus]}
                      color={'secondary'}
                      onClick={() => {
                        let alertProps = {
                          customClass: classes.customDeactivateAlert,
                        };
                        if (memberStatus === 'Active') {
                          alertProps = {
                            ...alertProps,
                            onConfirm: async () => {
                              handleStatusChange('deactivate');
                            },
                            customClass: classes.customDeactivateAlert,
                            confirmBtnText: 'Ok',
                            title: 'Warning',
                            text:
                              'By deactivating this account, this user will lose access to the portal but you will continue to have access to their referral information',
                            type: 'warning',
                            cancelBtnText: (
                              <span>
                                <i
                                  className="fa fa-arrow-left"
                                  aria-hidden="true"
                                ></i>{' '}
                                Back to Edit
                              </span>
                            ),
                          };
                        } else {
                          alertProps = {
                            ...alertProps,
                            onConfirm: async () => {
                              handleStatusChange('activate');
                            },
                            confirmBtnText: 'Yes',
                            customClass: classes.customActivateAlert,
                            cancelBtnText: (
                              <span>
                                <i
                                  className="fa fa-arrow-left"
                                  aria-hidden="true"
                                ></i>{' '}
                                Back to Edit
                              </span>
                            ),
                            title: 'Are you sure?',
                            text:
                              'By reactivating this account, this user will regain access to the portal and access to their referral information.',
                            customIcon: (
                              <div>
                                <img
                                  src={require('assets/img/icons/info-circle-icon.svg')}
                                />
                              </div>
                            ),
                          };
                        }

                        AlertPopupHandler.openCustom({
                          ...alertProps,
                        });
                      }}
                    >
                      {memberStatus === 'Active' ? 'Deactivate' : 'Reactivate'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              className={classes.back}
              onClick={() => {
                if (isFormEdited) {
                  AlertPopupHandler.openCustom({
                    onConfirm: () => {
                      history.push('/admin/team');
                    },
                    customClass: classes.customActivateAlert,
                    confirmBtnText: 'Yes',
                    cancelBtnText: (
                      <span>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{' '}
                        Back to Edit
                      </span>
                    ),
                    title: 'Are you sure?',
                    text: 'By going back, all changes made will be lost.',
                    customIcon: (
                      <div>
                        <img
                          src={require('assets/img/icons/info-circle-icon.svg')}
                        />
                      </div>
                    ),
                  });
                } else {
                  history.push('/admin/team');
                }
                // onClose();
                // openInviteForm();
              }}
              color="link"
              leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
            >
              Back to Team
            </Button>
            {isFormEdited && (
              <div className={classes.actions}>
                <Button
                  onClick={() => {
                    if (isFormEdited) {
                      AlertPopupHandler.openCustom({
                        onConfirm: () => {
                          resetForm(initialValues);
                        },
                        customClass: classes.customActivateAlert,
                        confirmBtnText: 'Yes',
                        cancelBtnText: (
                          <span>
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                            ></i>{' '}
                            Back to Edit
                          </span>
                        ),
                        title: 'Are you sure?',
                        text: 'By cancelling, all changes made will be lost.',
                        customIcon: (
                          <div>
                            <img
                              src={require('assets/img/icons/info-circle-icon.svg')}
                            />
                          </div>
                        ),
                      });
                    } else {
                      resetForm(initialValues);
                    }
                    // handleReset();
                    // closeModal();
                  }}
                  className="btn-outline-default"
                  outlined
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color={'primary'}
                  disabled={!isValid || loading}
                  loading={loading}
                >
                  Save Changes
                </Button>
              </div>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  };

  if (memberLoading && !isUpdating) {
    return <Loading />;
  }

  return (
    <Container fluid className={classes.formWrapper}>
      <Formik
        initialValues={initialValues}
        validationSchema={TeamMemberSchema}
        enableReinitialize
        onSubmit={async values => {
          setIsUpdating(true);
          const {
            email_address = '',
            first_name = '',
            last_name = '',
            role = '',
            see_earning = false,
            title = '',
            supervisor = null,
            phone = '',
            street = '',
            city_state_zip = '',
            timezone,
          } = values;
          const data = {
            email_address,
            first_name,
            last_name,
            role: role?.name,
            title,
            timezone,
            phone,
            ...(role?.name === 'Partner_Representative'
              ? { supervisors: [supervisor?.id] }
              : {}),
            street,
            city: city_state_zip,
            see_earning,
          };
          await dispatch(updateTeamMember(member.id, data));
          setIsUpdating(false);
        }}
      >
        {TeamEditForm}
      </Formik>
    </Container>
  );
};

export default EditTeamMember;
