import React from 'react';
import classes from './Deals.module.scss';
import DealDeskItem from '../../DealDeskItem';
import Pagination from 'components/Pagination';
import Filters from '../../Filters';
import history from 'helpers/history';
import { useLocation } from 'react-use';

const Deals = ({ meta = {}, data = [], setFilter, filter }) => {
  const location = useLocation();

  return (
    <div className={classes.wrapper}>
      <Filters filter={filter} setFilter={setFilter} filterType="deals" />

      {data.map(item => (
        <DealDeskItem
          key={item.id}
          item={item}
          type={'deals'}
          openModal={id => {
            history.push(`${location.pathname}?tab=deals&deal_id=${id}`);
          }}
        />
      ))}
      <div className={classes.paginationWrapper}>
        {meta && meta.total > 0 && (
          <div className={classes.paging}>
            From {meta.from} to {meta.to} of {meta.total} Results
          </div>
        )}

        {meta?.total > 0 && (
          <Pagination
            totalPage={meta.last_page}
            currentPage={meta.current_page}
            onClick={page => {
              setFilter({ ...filter, page });
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Deals;
