import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_PARTNER_TEAM_INPROGRESS',
  'FETCH_PARTNER_TEAM_SUCCESS',
  'FETCH_PARTNER_TEAM_FAILURE',
  'FETCH_PARTNER_TEAM_ROLE_INPROGRESS',
  'FETCH_PARTNER_TEAM_ROLE_SUCCESS',
  'FETCH_PARTNER_TEAM_ROLE_FAILURE',
  'FETCH_PARTNER_TEAM_STATUS_INPROGRESS',
  'FETCH_PARTNER_TEAM_STATUS_SUCCESS',
  'FETCH_PARTNER_TEAM_STATUS_FAILURE',
  'POST_INVITE_TEAM_MEMBER_INPROGRESS',
  'POST_INVITE_TEAM_MEMBER_SUCCESS',
  'POST_INVITE_TEAM_MEMBER_FAILURE',
  'UPDATE_TEAM_MEMBER_INPROGRESS',
  'UPDATE_TEAM_MEMBER_SUCCESS',
  'UPDATE_TEAM_MEMBER_FAILURE',
  'FETCH_MEMBER_INPROGRESS',
  'FETCH_MEMBER_SUCCESS',
  'FETCH_MEMBER_FAILURE',
  'POST_MEMBER_STATUS_INPROGRESS',
  'POST_MEMBER_STATUS_SUCCESS',
  'POST_MEMBER_STATUS_FAILURE'
);
