import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  ModalBody,
} from 'reactstrap';
import classes from 'views/pages/ClientProfile/ClientProfile.module.scss';
import get from 'lodash/get';
import BaseTable from 'components/Table';
import cx from 'classnames';
import NoTeamMembersImg from 'assets/img/theme/No_Team_Members.svg';
import DeleteAlertIcon from 'assets/img/icons/delete-alert-icon.svg';
import CloseIcon from 'assets/img/icons/close-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import InviteUserForm from 'views/pages/Users/InviteUser.Form';
import UserInfo from 'components/UserInfo';
import InfiniteScroll from 'react-infinite-scroller';
import { fetchTeamMembers } from 'store/actions/clientProfile';
import {
  cancelInvite,
  deactivateUser,
  deleteUser,
  editInvite,
  impersonateUser,
  inviteMembers,
} from 'store/actions/users';
import Dropdowns from 'components/Dropdowns';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Modal from 'components/FormFields/Modal';

const ClientTeams = ({
  clientId,
  getDetails,
  handleClientTeamAction,
  isMobile,
  companyId,
  cardHeader = 'Client Team',
  reducerKey = 'clientTeam',
  defaultSort = { dataField: 'name', order: 'asc' },
  isFulfilment = false,
}) => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState(defaultSort);
  const [showInviteMembersModal, setShowInviteMembersModal] = useState(false);
  const [selectionChange, setSelectionChange] = useState({
    openValidModal: false,
    agreeToInvite: false,
    selectedValue: '',
    data: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [checkRemoveUserModal, setCheckRemoveUserModal] = useState(false);
  const [viewMode, setViewMode] = useState(null);
  const dispach = useDispatch();
  const getReducerTasks = (field, defaultValue) =>
    getDetails(`${reducerKey}.${field}`, defaultValue);
  const teamMembers = getReducerTasks('data.data', []);
  const metaDetails = getReducerTasks('data.meta');
  const isError = getReducerTasks('isError', false);
  const loading = getReducerTasks('isInProgress', false);
  const lastPage = get(metaDetails, 'last_page', 1);
  const currentPage = get(metaDetails, 'current_page', 1);

  const loggedInUser = useSelector(({ auth }) => get(auth, 'user.id', null));
  const user = useSelector(({ auth }) => get(auth, 'user', null));

  let isImpersonate = false;
  const acceptableRoles = [
    'Company_Administrator',
    'Fulfilment_Onboarding_Manager',
    'Fulfilment_Director',
  ];

  const roles = get(user, 'roles', []);

  acceptableRoles.forEach(role => {
    const isRolePresent = roles.includes(role);
    if (isRolePresent) {
      isImpersonate = true;
    }
  });

  const closeModal = () => {
    setIsEdit(false);
    setEditData({});
    setViewMode(null);
  };

  const getTasks = (options = {}) => {
    let searchParams = {};
    const { order, dataField } = sortBy;
    if (order && dataField) {
      searchParams.page = 0;
      searchParams.sort = order === 'asc' ? dataField : `-${dataField}`;
    }
    handleClientTeamAction({ ...searchParams, ...options });
  };

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, dispach]);

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noTeamMemberWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.teamMemberImageWrapper}>
            <img
              className={cx('m-auto w-100', classes.defaultImage)}
              src={NoTeamMembersImg}
              alt="No Team Members"
            />
          </div>
          <h4
            className={cx(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Team Members
          </h4>
        </div>
      </div>
    );
  };

  const handleCloseInviteMembers = () => {
    closeModal();
    setShowInviteMembersModal(false);
  };

  const submitInviteMembers = async data => {
    setIsLoading(true);
    let status;
    const {
      email,
      firstName,
      lastName,
      role,
      workflow,
      note,
      studies,
      inviteClient,
    } = data;
    const initiatives = studies?.map(study => study.id) || [];
    if (!isEdit) {
      const formData = {
        email_addresses: [email],
        first_name: firstName,
        last_name: lastName,
        roles: [role.name],
        show_macro_onboarding: workflow,
        company_id: companyId,
      };
      if (data.note) {
        formData.personal_note = note;
      }
      if (inviteClient) {
        formData.initiatives = initiatives;
      }
      status = await dispach(inviteMembers(formData));
    } else if (isEdit) {
      const formData = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        roles: [role.name],
      };
      if (inviteClient) {
        formData.initiatives = initiatives;
      }

      status = await dispach(editInvite(editData.id, formData));
    }
    setShowInviteMembersModal(false);
    setIsLoading(false);
    if (status) {
      dispach(fetchTeamMembers(clientId));
    }
    setSelectionChange({
      openValidModal: false,
      agreeToInvite: false,
      selectedValue: '',
      data: null,
    });
    closeModal();
  };

  const handleResendInvite = async row => {
    setViewMode('re-invite');
    setEditData(row);
    setShowInviteMembersModal(true);
  };

  const handleCancelInvite = async id => {
    const { status } = await dispach(
      cancelInvite(id, { company_id: companyId })
    );
    if (status === 1) {
      dispach(fetchTeamMembers(clientId));
    }
  };

  const handleEditUser = row => {
    setIsEdit(true);
    setEditData(row);
    setShowInviteMembersModal(true);
  };

  const handleRemoveUser = row => {
    AlertPopupHandler.openCustom({
      onConfirm: async () => {
        const status = await dispach(deleteUser(row.id));
        if (status) {
          dispach(fetchTeamMembers(clientId));
        }
      },
      onCancel: () => {},
      confirmBtnText: 'Remove',
      custom: true,
      title: ' ',
      customClass: classes.alertModalWrapper,
      ChildTag: 'div',
      text: (
        <div className={classes.deleteAlertSection}>
          <div className={classes.deleteAlertIcon}>
            <img src={DeleteAlertIcon} alt="" />
          </div>
          <div>
            <div className={classes.deleteAlertHeader}>
              Are you sure you would like to delete {row.name} ?
            </div>
            <div className={classes.deleteAlertText}>
              This user will be removed from the portal.
            </div>
          </div>
        </div>
      ),
      confirmBtnBsStyle: 'warning',
      cancelBtnBsStyle: 'custom',
      confirmBtnCssClass: classes.confirmBtn,
      cancelBtnCssClass: classes.cancelBtn,
      customIcon: '',
    });
  };

  const handleDeactivateUser = user => () => {
    AlertPopupHandler.openCustom({
      onConfirm: async () => {
        const status = await dispach(
          deactivateUser(user.id, { company_id: companyId })
        );
        if (status) {
          dispach(fetchTeamMembers(clientId));
        }
      },
      onCancel: () => {},
      confirmBtnText: 'Deactivate User',
      custom: true,
      title: ' ',
      customClass: classes.alertModalWrapper,
      ChildTag: 'div',
      text: (
        <div className={classes.deleteAlertSection}>
          <div className={classes.deleteAlertIcon}>
            <img src={DeleteAlertIcon} alt="" />
          </div>
          <div>
            <div className={classes.deleteAlertHeader}>
              You are about to deactivate {user.name} from your company account.
            </div>
            <div className={classes.deleteAlertText}>
              You can still reactivate their account later. Do you want to
              continue?
            </div>
          </div>
        </div>
      ),
      confirmBtnBsStyle: 'warning',
      cancelBtnBsStyle: 'custom',
      confirmBtnCssClass: classes.confirmBtn,
      cancelBtnCssClass: classes.cancelBtn,
      customIcon: '',
    });
  };

  const toggleModal = () => {
    setCheckRemoveUserModal(!checkRemoveUserModal);
    closeModal();
  };

  const handleLoadMore = () => {
    getTasks({ page: currentPage + 1 });
  };

  const ImpersonateAction = row => async e => {
    e.preventDefault();
    const resp = await dispatch(impersonateUser(row.id));
    const impersonatedUser = resp.data;
    if (impersonatedUser.is_client && impersonatedUser.is_partner) {
      window.location.href = '/admin/referral/clients';
    } else {
      window.location.href = '/admin/dashboard';
    }
  };

  const getUserActionOptions = row => {
    const status = row.status?.status;
    switch (status) {
      case 'Active':
        return [
          {
            text: 'Edit User',
            onClick: () => handleEditUser(row),
          },
          ...(loggedInUser !== row.id
            ? [
                {
                  text: 'Deactivate User',
                  onClick: handleDeactivateUser(row),
                },
                {
                  text: 'Remove User',
                  onClick: () => handleRemoveUser(row),
                },
                ...(isImpersonate
                  ? [
                      {
                        text: 'Impersonate User',
                        onClick: ImpersonateAction(row),
                      },
                    ]
                  : []),
              ]
            : []),
        ];
      case 'Invited':
      case 'Pending':
        return [
          {
            text: 'Cancel Invite',
            onClick: () => handleCancelInvite(row.id),
          },
          {
            text: 'Re-Invite User',
            onClick: () => handleResendInvite(row),
          },
        ];
      case 'Deactivated':
        return [
          {
            text: 'Re-Invite User',
            onClick: () => handleResendInvite(row),
          },
          ...(loggedInUser !== row.id
            ? [
                {
                  text: 'Remove User',
                  onClick: () => handleRemoveUser(row),
                },
              ]
            : []),
        ];
      default:
        return [];
    }
  };

  return (
    <>
      {checkRemoveUserModal && (
        <Modal
          isOpen={checkRemoveUserModal}
          toggle={toggleModal}
          className={classes.modalWrapper}
          noHeader
        >
          <ModalBody className={cx('d-flex', classes.modalBodyWrapper)}>
            <button
              aria-label="Close"
              className={cx('close', classes.closeButton)}
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              <span aria-hidden={true}>
                <img src={CloseIcon} alt="" />
              </span>
            </button>
            <div>
              <img src={DeleteAlertIcon} alt="" />
            </div>
            <div className="ml-3">
              <div className={classes.title}>Unable to Remove User</div>
              <div className={classes.description}>
                You must have at least 1 or more Master Collaborator to manage
                your account.
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      {showInviteMembersModal && (
        <InviteUserForm
          clientId={clientId}
          isModalOpen={showInviteMembersModal}
          submitValues={submitInviteMembers}
          closeModal={handleCloseInviteMembers}
          isLoading={isLoading}
          editValues={
            isEdit
              ? {
                  firstName: editData.name.split(' ')[0],
                  lastName: editData.name.split(' ')[1],
                  email: editData.email,
                  roles: editData.roles[0],
                  userid: editData.id,
                }
              : viewMode === 're-invite'
              ? {
                  ...editData,
                  email: editData?.email,
                  roles: editData?.roles?.[0],
                  userid: editData?.id,
                }
              : false
          }
          selectionChange={selectionChange}
          setSelectionChange={setSelectionChange}
          inviteClient={true}
          isEdit={isEdit}
          viewMode={viewMode}
          title={
            isFulfilment
              ? 'Invite Members'
              : isEdit
              ? 'Edit User'
              : 'Invite Client'
          }
          confirmBtnTitle={isEdit ? 'Save' : 'Send Invite Now'}
          resendInviteAction={handleResendInvite}
          cancelInviteAction={handleCancelInvite}
        />
      )}
      <Card className={cx('d-flex', classes.ClientTeams)}>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-0">
              <span>
                <i
                  className={cx(
                    `fas fa-caret-'down' mr-1`,
                    classes.ClientTeamsHeader
                  )}
                />
              </span>
              {cardHeader}
              {metaDetails?.total > 0 && (
                <Badge color="info" className="ml-2 rounded-circle">
                  {metaDetails?.total}
                </Badge>
              )}
            </h3>
            <div className={cx(classes.InviteTeamMemberButton)}>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  setShowInviteMembersModal(true);
                }}
              >
                {isFulfilment ? 'Invite New User' : 'Invite Team Members'}
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody
          className={cx('p-0', classes.cardBody, !isMobile && classes.scroll)}
        >
          <InfiniteScroll
            useWindow={isMobile}
            hasMore={currentPage < lastPage && !loading && !isError}
            initialLoad={false}
            pageStart={1}
            loadMore={handleLoadMore}
          >
            <BaseTable
              keyField="id"
              defaultSorted={[sortBy]}
              remote
              noDataIndication={NoDataIndication}
              search={false}
              bordered={false}
              loading={loading}
              paginationOptions={false}
              data={teamMembers}
              classes="mb-0"
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  setSortBy({
                    dataField: sortField,
                    order: sortOrder,
                  });
                }
              }}
              columns={[
                !isFulfilment && {
                  dataField: 'avatar',
                  text: '',
                  sort: false,
                  formatter: (cell, row) => {
                    return (
                      <UserInfo
                        info={{
                          id: row.id,
                          name: row.name,
                          avatar: cell,
                        }}
                        showName={false}
                        showToolTip={true}
                      />
                    );
                  },
                },
                {
                  dataField: 'name',
                  text: 'Name',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <h5
                          id={`client_name-${get(row, 'id')}`}
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName,
                            classes.nameField
                          )}
                        >
                          {cell}
                        </h5>
                      </>
                    );
                  },
                },
                {
                  dataField: 'email',
                  text: 'Email Address',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <h5
                          id={`client_email-${get(row, 'id')}`}
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName,
                            classes.nameField
                          )}
                        >
                          {cell}
                        </h5>
                      </>
                    );
                  },
                },
                {
                  dataField: 'roles[0].title',
                  text: 'Role',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <span
                        className={cx(
                          classes.roleField,
                          classes[`roleField${cell}`]
                        )}
                      >
                        {cell}
                      </span>
                    );
                  },
                },
                {
                  dataField: 'status',
                  text: 'Status',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <span
                        className={cx(
                          classes.roleField,
                          classes[`roleField${cell}`]
                        )}
                      >
                        {cell.status}
                      </span>
                    );
                  },
                },
                isFulfilment && {
                  dataField: 'assigned_studies',
                  text: 'ASSIGNED STUDIES',
                  sort: true,
                  classes: classes.clientName,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => (
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <ul className="mb-0">
                        {cell?.length > 0
                          ? cell?.map((cellData, index) => (
                              <li key={index}>{cellData}</li>
                            ))
                          : '-'}
                      </ul>
                    </div>
                  ),
                },
                {
                  dataField: '',
                  text: '',
                  sort: false,
                  formatter: (cell, row) => {
                    return (
                      <Dropdowns
                        onClick={event => event.stopPropagation()}
                        text={<i className="fas fa-ellipsis-v" />}
                        className="btn-icon-only m-0 text-light float-right"
                        options={getUserActionOptions(row)}
                        caret={false}
                        size="sm"
                        color=""
                      />
                    );
                  },
                },
              ]}
            />
          </InfiniteScroll>
        </CardBody>
      </Card>
    </>
  );
};

export default ClientTeams;
