import React, { useEffect, useState } from 'react';
import { Container, Input } from 'reactstrap';
import classes from './PartnerClients.module.scss';
import cs from 'classnames';
import Dropdowns from 'components/Dropdowns';
import PartnerClientStudies from 'views/pages/PartnerClients/Components/PartnerClientStudies';
import Button from 'components/Button';
import {
  clearReferralCommentsData,
  fetchClientStudiesForGivenPartner,
} from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';
import moment from 'moment';
import { debounce } from 'lodash';
import LeadModal from 'views/pages/dashboards/PartnerDashboard/LeadModal';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import history from 'helpers/history';
import SubmitReferrals from 'components/Sidebar/SubmitReferral';

const PartnerClients = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    status: '',
    search: '',
    from_date: '',
    to_date: moment().format('YYYY/MM/DD'),
  });

  const [period, setPeriod] = useState({
    value: '',
    text: 'All time',
  });
  const [status, setStatus] = useState({
    value: '',
    text: 'All statuses',
  });
  const clientStudiesInProgress = useSelector(({ partner }) =>
    get(partner, 'clientStudies.isInProgress', false)
  );
  const [searchText, setSearchText] = useState('');
  const [isModal, setIsModal] = useState(false);
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  const [activeComment, setActiveComment] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [modalType, setModalType] = useState('client');
  const [currentTab, setCurrentTab] = useState('comment');
  const [isSubmitReferralFormOpen, setSubmitReferralFormOpen] = useState(false);
  const [isEmptyState, setIsEmptyState] = useState(false);

  const parentId = useSelector(({ auth }) => auth.user.company.id, null);
  const clientStudies = useSelector(({ partner }) =>
    get(partner, 'clientStudies.data', [])
  );

  useEffect(() => {
    if (clientStudies.length > 0) {
      setIsEmptyState(false);
    } else {
      setIsEmptyState(true);
    }
  }, [clientStudies.length, setIsEmptyState]);
  useEffect(() => {
    const { lead = '', tab = '', type = '', comment } = queryProps;
    if (lead) {
      setLeadId(lead);
      setCurrentTab(tab);
      setModalType(type);
      setIsModal(true);
      setActiveComment(comment);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps.lead]);

  useEffect(() => {
    const filterQuery = {
      ...filter,
      status: status.value,
      from_date: period.value,
    };
    dispatch(fetchClientStudiesForGivenPartner(filterQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, status, period]);

  const handleSearch = () => {
    setFilter({ ...filter, search: searchText });
  };

  const handleChange = e => {
    e.persist();

    delayedSearch(e);
  };

  const searchInputChanged = e => {
    const filterQuery = {
      ...filter,
      status: status.value,
      from_date: period.value,
      search: e.target.value,
    };
    setSearchText(e.target.value);
    setFilter(filterQuery);
  };

  const delayedSearch = debounce(searchInputChanged, 500);

  const handleSetLeadId = id => {
    setLeadId(id);
  };

  const periodOptions = [
    {
      value: '',
      text: 'All time',
      onClick: async () => {
        setPeriod({
          value: '',
          text: 'All time',
        });
      },
    },
    {
      value: 'last_year',
      text: 'Last 365 days',
      onClick: async () => {
        setPeriod({
          value: moment()
            .subtract(365, 'days')
            .format('YYYY/MM/DD'),
          text: 'Last 365 days',
        });
      },
    },
    {
      value: 'last_six_months',
      text: 'Last 6 months',
      onClick: async () => {
        setPeriod({
          value: moment()
            .subtract(182, 'days')
            .format('YYYY/MM/DD'),
          text: 'Last 6 months',
        });
      },
    },
    {
      value: 'last_month',
      text: 'Last 30 Days',
      onClick: async () => {
        setPeriod({
          value: moment()
            .subtract(30, 'days')
            .format('YYYY/MM/DD'),
          text: 'Last 30 days',
        });
      },
    },
    {
      value: 'last_week',
      text: 'Last 7 Days',
      onClick: async () => {
        setPeriod({
          value: moment()
            .subtract(7, 'days')
            .format('YYYY/MM/DD'),
          text: 'Last 7 Days',
        });
      },
    },
  ];

  const statusOptions = [
    {
      value: '',
      text: 'All statuses',
      onClick: async () => {
        setStatus({
          value: '',
          text: 'All statuses',
        });
      },
    },
    {
      value: 'Document Gathering',
      text: 'Document Gathering',
      onClick: async () => {
        setStatus({
          value: 'Document Gathering',
          text: 'Document Gathering',
        });
      },
    },
    {
      value: 'Onboarding',
      text: 'Onboarding',
      onClick: async () => {
        setStatus({
          value: 'Onboarding',
          text: 'Onboarding',
        });
      },
    },
    {
      value: 'On Hold',
      text: 'On Hold',
      onClick: async () => {
        setStatus({
          value: 'On Hold',
          text: 'On Hold',
        });
      },
    },
    {
      value: 'Calculations',
      text: 'Calculations',
      onClick: async () => {
        setStatus({
          value: 'Calculations',
          text: 'Calculations',
        });
      },
    },
    {
      value: 'Credits Delivered',
      text: 'Credits Delivered',
      onClick: async () => {
        setStatus({
          value: 'Credits Delivered',
          text: 'Credits Delivered',
        });
      },
    },
    {
      value: 'Final Reports',
      text: 'Final Reports',
      onClick: async () => {
        setStatus({
          value: 'Final Reports',
          text: 'Final Reports',
        });
      },
    },
    {
      value: 'Complete',
      text: 'Complete',
      onClick: async () => {
        setStatus({
          value: 'Complete',
          text: 'Complete',
        });
      },
    },
  ];
  if (clientStudiesInProgress) {
    return <Loading />;
  }
  return (
    <>
      {isModal ? (
        <LeadModal
          parentId={parentId}
          leadId={leadId}
          isOpen={isModal}
          modalType={modalType}
          closeModal={() => {
            setIsModal(false);
            dispatch(clearReferralCommentsData());
            history.push('?');
          }}
          currentTab={currentTab}
          comment={activeComment}
        />
      ) : null}
      {isEmptyState ? (
        <Container className={classes.emptyData}>
          <div className={classes.emptyDataHeading}>Clients</div>
          <div className={classes.emptyText}>
            Use this section to track and review your Client and Partner
            Referrals. See where
            <br /> they are at in the process and what may need your attention.
          </div>
          <Button color="info" onClick={() => setSubmitReferralFormOpen(true)}>
            Submit Referrals
          </Button>
        </Container>
      ) : (
        <Container fluid className={classes.wrapper}>
          <p className={classes.title}>Client Tracker</p>
          <div className={classes.clientHeaderWrapper}>
            <div className={classes.headerDropdowns}>
              {/*<div>*/}
              {/*  <p>DATE RANGE</p>*/}
              {/*  <Dropdowns*/}
              {/*    dropdownClasses={cs(classes.customDropdown)}*/}
              {/*    className="m-0 p-1 ml-1"*/}
              {/*    text={*/}
              {/*      <div className={classes.caret}>*/}
              {/*        <span>{period.text}</span>{' '}*/}
              {/*        <i className="fas fa-caret-down" />{' '}*/}
              {/*      </div>*/}
              {/*    }*/}
              {/*    active={period.value}*/}
              {/*    size="sm"*/}
              {/*    role="button"*/}
              {/*    caret={false}*/}
              {/*    color=""*/}
              {/*    options={periodOptions}*/}
              {/*  />*/}
              {/*</div>*/}
              <div>
                <p>STATUS </p>
                <Dropdowns
                  dropdownClasses={cs(classes.customDropdown)}
                  className="m-0 p-1 ml-1"
                  text={
                    <div className={classes.caret}>
                      <span className={classes.statusText}>{status.text}</span>{' '}
                      <i className="fas fa-caret-down" />{' '}
                    </div>
                  }
                  active={status.value}
                  size="sm"
                  role="button"
                  caret={false}
                  color=""
                  options={statusOptions}
                />
              </div>
            </div>
            <div>
              <p className={classes.searchLabel}>SEARCH</p>
              <div className={classes.searchButtonWrapper}>
                <Input
                  className={cs(
                    classes.input,
                    'form-control-alternative edit-event--description textarea-autosize'
                  )}
                  placeholder="Enter search term..."
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  id="inviteUsersBtn"
                  color={'primary'}
                  className={'float-left'}
                  onClick={handleSearch}
                  disabled={searchText === ''}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
          {clientStudiesInProgress ? (
            <Loading />
          ) : (
            <PartnerClientStudies
              setIsEmptyState={setIsEmptyState}
              handleSetLeadId={handleSetLeadId}
              openModal={() => setIsModal(true)}
            />
          )}
        </Container>
      )}
      <SubmitReferrals
        isOpenSubmitReferrals={isSubmitReferralFormOpen}
        closeModal={() => setSubmitReferralFormOpen(false)}
      ></SubmitReferrals>
    </>
  );
};

export default PartnerClients;
