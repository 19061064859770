// get value from the schema(getting from BE)

import { isArray } from 'lodash';

// mostly used to check dependsOn
export function findObjectByPath(data, path) {
  const pathArray = path.split('.');
  let filteredData = data;
  let updatedPaths = [];
  const findObject = (arr, key) =>
    arr.find(ele => ele.name === key || ele.group === key);
  pathArray.forEach((key, index) => {
    const recursiveCheckValue = () => {
      if (key === '*') {
        updatedPaths.push('*');
      }

      let obj = findObject(filteredData, key);
      if (!obj) {
        filteredData.find(nested => {
          let breakLoop = false;
          if (
            nested?.type === 'group' ||
            nested.group ||
            !!(nested?.fields || []).length
          ) {
            filteredData = nested.fields;
            breakLoop = recursiveCheckValue();
          }
          return !!breakLoop;
        });
      } else {
        if (obj.group && !updatedPaths.includes(obj.group)) {
          updatedPaths.push(obj.group);
        }
        if (pathArray.includes(obj.name)) {
          updatedPaths.push(obj.name);
        }
        if (obj.fields) {
          filteredData = obj.fields;
        }
      }
      return obj;
    };
    recursiveCheckValue(filteredData);
  });
  return [...new Set(updatedPaths)].join('.');
}

// get value from the schema(getting from BE)
export const getValueFromTheFormikSchema = (values, name = '', index) => {
  let value = values;
  const keys = name.split('.');
  for (const key of keys) {
    if (key === '*') {
      value = value[index];
    }
    // eslint-disable-next-line no-prototype-builtins
    else if (value && value.hasOwnProperty(key)) {
      value = value[key];
    } else {
      // Handle the case where the property is not found
      value = undefined;
      break;
    }
  }
  return value;
};

export const getDependsOnObject = (schemaArray, dependsOn) => {
  const dependsOnPathValue = [];

  const getValue = dependOnObj => {
    let path = dependOnObj.path;
    let value = dependOnObj.value;
    path = findObjectByPath(schemaArray, path);

    return { path, value };
  };

  (isArray(dependsOn) ? dependsOn : [dependsOn]).forEach(dependOnObj => {
    if (dependOnObj.fields) {
      const fields = dependOnObj.fields.map(getValue);
      dependsOnPathValue.push({ ...dependOnObj, fields });
    } else {
      const obj = getValue(dependOnObj);
      dependsOnPathValue.push(obj);
    }
  });

  return dependsOnPathValue;
};
