import React from 'react';
import classes from './earnings.module.scss';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import { UncontrolledTooltip } from 'reactstrap';
import cx from 'classnames';
import InfoCircle from 'assets/img/icons/info-circle-dark-icon.svg';
import { calculateFormatPercentage } from './constants';
import get from 'lodash/get';
import useBreakpoint from 'helpers/useBreakPoint';
import { withCurrency } from 'helpers/constants';

const EstimatedChart = ({
  futureData = {},
  comparedData = {},
  estimatedBreakdown,
}) => {
  // const { not_invoiced = 0, unpaid = 0, paid = 0 } = futureData;
  // const { earnings = 0, payouts = 0 } = comparedData;
  const isMobile = useBreakpoint('xs', 'down');
  const {
    payments_disbursed = 0,
    unpaid = 0,
    pending_payouts = 0,
    total_referral_earning = 0,
  } = estimatedBreakdown;
  const amountData = [];
  const labels = [];
  const totalValue = payments_disbursed + unpaid + pending_payouts;
  const sortnData = [
    {
      price: payments_disbursed,
      label: 'Paid',
      color: '#4FB3C9',
      tooltipContent: 'The total value of all payments made to you by Strike.',
    },
    {
      price: unpaid,
      label: 'Not Yet Paid',
      color: '#4FC9AC',
      tooltipContent:
        'The total value of your earnings from the clients you referred, where the client(s) has not yet made payment to us. ',
    },
    {
      price: pending_payouts,
      label: 'Pending Payouts',
      color: '#473902',
      tooltipContent:
        'The total value of earnings from clients you refererd, where the client has made payment but we have not yet made payment to you.',
    },
  ];

  sortnData.sort((a, b) => {
    return a.price > b.price ? -1 : 1;
  });

  sortnData.forEach(item => {
    amountData.push(item.price);
    labels.push(item.label);
  });

  const customLabels = labels.map(
    (label, index) =>
      `${label} (${calculateFormatPercentage(
        amountData[index],
        totalValue,
        0,
        true
      )})`
  );
  const pieData = {
    options: {
      responsive: false,
      max: 100,
      cutoutPercentage: 60,
      legend: {
        position: `${isMobile ? 'bottom' : 'right'}`,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
        onClick: () => {
          return false;
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      plugins: {
        labels: {
          render: args => {
            return withCurrency(args.value, {
              isShorted: true,
              minimumFractionDigits: 0,
              isRoundOf: true,
            });
          },
          fontColor: 'white',
        },
      },
      tooltips: {
        xAlign: 'left',
        yAlign: 'top',
        callbacks: {
          label(item, data) {
            const index = item.index;
            const label = data.labels[index]?.replace(/\(\S+\)/g, '');
            let value = get(data, `datasets[0].data[${index}]`, '');
            value = withCurrency(value, {
              isShorted: true,
              minimumFractionDigits: 0,
              isRoundOf: true,
            });
            return `${label}: ${value}`;
          },
        },
      },
    },
    data: {
      labels: customLabels,
      datasets: [
        {
          data: amountData,
          backgroundColor: ['#4FB3C9', '#4FC9AC', '#473902'],
          label: 'Dataset 1',
          borderWidth: 0,
          style: {
            marginLeft: ' 20px',
          },
        },
      ],
    },
    plugins: [
      {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
          const { datasets } = chart.data;
          const { color, width, radiusDecrease } = options;
          let hasData = datasets[0]?.data?.filter(Boolean)?.length > 0 || false;
          if (!hasData) {
            const {
              chartArea: { left, top, right, bottom },
              ctx,
            } = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            const r = Math.min(right - left, bottom - top) / 2;

            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || '#7D7882';
            ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.save();
          }
        },
        beforeDatasetsDraw(chart, args, options) {
          const { ctx, chartArea } = chart;
          const { left, top, right, bottom } = chartArea;
          const centerX = (left + right) / 2;
          let centerY = (top + bottom - 10) / 2;

          ctx.save();
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';

          const lines = [
            {
              text: `${withCurrency(total_referral_earning, {
                isShorted: true,
                minimumFractionDigits: 0,
                isRoundOf: true,
              })}`,
              fontSize: 16,
              fontWeight: 'bold',
            },
            { text: 'Total Earnings', fontSize: 10, fontWeight: 'normal' },
          ];

          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            ctx.font = `${line.fontWeight} ${line.fontSize}px Arial`;
            if (i === 1) {
              ctx.fillStyle = 'gray';
            }
            ctx.fillText(line.text, centerX, centerY);
            centerY += line.fontSize + 2; // Adjust the spacing between lines
          }

          ctx.restore();
        },
      },
    ],
  };

  if (payments_disbursed === 0 && unpaid === 0 && pending_payouts === 0) {
    return (
      <div className={classes.empty}>
        <span>No data to display</span>
        <svg
          width="172"
          height="172"
          viewBox="0 0 172 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8586 77.8696C13.5829 80.4355 13.4414 83.0417 13.4414 85.6809C13.4414 125.6 45.802 157.96 85.7209 157.96C125.64 157.96 158 125.6 158 85.6809C158 45.7623 125.64 13.4018 85.7218 13.4014V85.6808L13.8586 77.8696Z"
            fill="#EEEEEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M82.3604 10.0391V82.3195L10.5 74.5086C14.3942 38.2635 45.0812 10.0401 82.3604 10.0391Z"
            fill="#EEEEEE"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className={classes.chartWrapper}>
      {/* <div className={classes.barChart}>
        <p>Estimated Future Earnings by Client Status</p>
        <Bar
          height={90}
          data={barData.data}
          options={barData.options}
          className="chart-canvas"
          id="bar-chart"
        />
      </div> */}
      <div
        className={classes.pieChart}
        style={{
          marginTop: '-20px',
          width: '100%',
        }}
      >
        <div>
          <p style={{ position: 'absolute', left: '11rem' }}>
            Earnings Breakdown
            <img
              id="potential-earnings-info"
              src={InfoCircle}
              className="ml-1"
              alt="InfoCircle"
            />
          </p>

          <UncontrolledTooltip
            target={`potential-earnings-info`}
            hideArrow
            boundariesElement="viewport"
            autohide={false}
            className={cx('custom-tooltip', classes.tooltipCustom)}
            placement="right"
          >
            {sortnData.map(({ label, tooltipContent, color }, key) => {
              return (
                <div className={cx(classes.tooltipPoints)} key={key}>
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: '50%',
                      backgroundColor: color,
                      marginRight: 5,
                    }}
                  ></div>
                  <div
                    style={{
                      width: 'calc(100% - 10px)',
                    }}
                  >
                    <p>
                      <span className={classes.bold}>{label}</span> -{' '}
                      {tooltipContent}
                    </p>
                  </div>
                </div>
              );
            })}
          </UncontrolledTooltip>
        </div>
        <div className="" style={{ marginTop: '-20px' }}>
          <Doughnut
            height={170}
            data={pieData.data}
            options={pieData.options}
            plugins={pieData.plugins}
            className="chart-canvas"
            id="pie-chart"
          />
        </div>
      </div>
    </div>
  );
};
export default EstimatedChart;
