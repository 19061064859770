import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import classes from './ListView.module.scss';
import classNames from 'classnames';
import BaseTable from 'components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersList } from 'store/actions/users';
import { roles, statuses } from 'views/pages/PartnerTeam/constants';

const ListView = ({
  handleEditMember,
  pageList,
  renderSortCaret,
  searchText,
  NoDataIndication,
  selectedRole,
  selectedStatus,
}) => {
  const dispatch = useDispatch();
  const type = 'list';
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const { userState } = useSelector(({ user }) => user);
  const usersData = get(userState, 'data.data', []);
  const userMetadata = get(userState, 'data.meta', {});

  const isInProgress = get(userState, 'isInProgress', false);
  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total float-left mt-3">
      Showing {from} to {to} of {size} teammates
    </span>
  );
  const sizePerPageRenderer = ({ options, onSizePerPageChange }) => {
    return (
      <div className="float-left size-per-page mr-5 mt-3">
        Show
        <select
          className="mx-2 size-per-page-select"
          onChange={event => {
            onSizePerPageChange(event.target.value);
          }}
        >
          {options.map(p => (
            <option key={p.text} value={p.page}>
              {p.text}
            </option>
          ))}
        </select>
        teammates
      </div>
    );
  };
  useEffect(() => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    //setPage(1);
    dispatch(
      fetchUsersList(
        page,
        sort,
        searchText,
        sizePerPage,
        null,
        selectedRole?.name,
        selectedStatus?.name,
        type
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, selectedRole, selectedStatus]);
  return (
    <div className={classes['list-view']}>
      <BaseTable
        keyField="id"
        search={false}
        defaultSorted={[sortBy]}
        remote
        bordered={false}
        loading={isInProgress}
        noDataIndication={NoDataIndication}
        paginationOptions={{
          page: page,
          totalSize: userMetadata.total,
          sizePerPage: sizePerPage,
          hideSizePerPage: false,
          showTotal: true,
          paginationTotalRenderer,
          sizePerPageList: pageList,
          sizePerPageRenderer,
        }}
        data={usersData}
        classes="mb-0"
        wrapperClasses={classNames(classes.tableWrapper, 'table-responsive')}
        onTableChange={(
          type,
          { page, sizePerPage, sortOrder, sortField, searchText, ...rest }
        ) => {
          if (type === 'pagination') {
            setPage(page);
            setSizePerPage(sizePerPage);
          } else if (type === 'sort') {
            setPage(1);
            setSortBy({
              dataField: sortField,
              order: sortOrder,
            });
          }
          if (type === 'search') {
            setPage(1);
          }
          const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
          dispatch(
            fetchUsersList(
              page,
              sort,
              searchText,
              sizePerPage,
              null,
              selectedRole?.name,
              selectedStatus?.name,
              type
            )
          );
        }}
        columns={[
          {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: (cell, row) => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {cell}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'roles',
            text: 'Role',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: (cell, row) => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {row.roles && row.roles.length > 0
                      ? roles.find(u => u.name === row.roles[0].name)
                          ?.displayName
                      : ''}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: (cell, row) => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {row.status
                      ? statuses.find(s => s.name == row.status.status)
                          ?.displayName
                      : ''}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'supervisors',
            text: 'Supervisor',
            sort: true,
            sortCaret: renderSortCaret,
            formatter: (cell, row) => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {row.supervisors && row.supervisors.length > 0
                      ? row.supervisors[0].name
                      : ''}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'actions',
            text: 'Actions',
            classes: classNames('d-flex'),
            headerStyle: (colum, colIndex) => {
              return { width: '50px' };
            },
            formatter: (cell, row) => (
              <div
                className={classNames('d-flex align-items-center')}
                onClick={() => handleEditMember(row)}
              >
                <i className="fas text-primary fa-pencil-alt mx-4" />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
export default ListView;
