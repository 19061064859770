import React from 'react';
import classes from './PartnerDashboard.module.scss';
import { Bar, Doughnut } from 'react-chartjs-2';
import { get } from 'lodash';
import 'chartjs-plugin-labels';
import useBreakpoint from 'helpers/useBreakPoint';

const StatusChart = ({ counts = {}, opportunityTypes }) => {
  const isMobile = useBreakpoint('xs', 'down');
  const {
    active_referrals = 0,
    closed = 0,
    received = 0,

    contacted = 0,
    disqualified = 0,
    responded = 0,
    qualified = 0,
    hold = 0,
    opportunities = 0,
    silent = 0,
    untouched = 0,
  } = counts;

  const barData = {
    options: {
      maintainAspectRatio: false,
      cornerRadius: 0,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#8898AA',
              fontSize: 11,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
              color: '#E9ECEF',
            },
            ticks: {
              min: 0,
              fontColor: '#8898AA',
              callback(value) {
                if (!(value % 10)) {
                  // return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label(item, data) {
            const label = data.datasets[item.datasetIndex].label || '';
            const { yLabel } = item;
            let content = '';
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
      plugins: {
        labels: false,
      },
    },
    data: {
      labels: [
        'Received',
        'Contacted',
        'Responded',
        'Qualified',
        'Silent',
        'On Hold',
        'Disqualified',
        'Closed',
      ],
      datasets: [
        {
          data: [
            received,
            contacted,
            responded,
            qualified,
            silent,
            hold,
            disqualified,
            closed,
          ],
          backgroundColor: [
            '#2C90EC',
            '#32325D',
            '#9F5CA5',
            '#E2E387',
            '#BDBDBD',
            '#F2994A',
            '#EB6363',
            '#6FCF97',
          ],
        },
      ],
    },
  };
  const percentageData = [
    get(opportunityTypes, 'per.RnD', 0)?.toFixed(2),
    get(opportunityTypes, 'per.ertc', 0)?.toFixed(2),
  ];

  const countData = [
    get(opportunityTypes, 'count.RnD', 0),
    get(opportunityTypes, 'count.ertc', 0),
  ];

  const labels = ['R&D', 'ERTC'];

  const customLabels = labels.map(
    (label, index) => `${label} (${percentageData[index]}%)`
  );
  const doughnutData = {
    options: {
      responsive: false,
      max: 100,
      legend: {
        position: `${isMobile ? 'bottom' : 'right'}`,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      plugins: {
        emptyDoughnut: {
          color: '#7D7882',
          width: 2,
          radiusDecrease: 20,
        },
        labels: { render: 'value', fontColor: 'white' },
      },
    },
    data: {
      labels: customLabels,
      datasets: [
        {
          data: countData,
          backgroundColor: ['#4FB3C9', '#7D7882'],
          label: 'Dataset 1',
          borderWidth: 0,
        },
      ],
    },
    plugins: [
      {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
          const { datasets } = chart.data;
          const { color, width, radiusDecrease } = options;
          let hasData = datasets[0]?.data?.filter(Boolean)?.length > 0 || false;
          if (!hasData) {
            const {
              chartArea: { left, top, right, bottom },
              ctx,
            } = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            const r = Math.min(right - left, bottom - top) / 2;

            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || '#7D7882';
            ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
            ctx.stroke();
          }
        },
      },
    ],
  };
  return (
    <div className={classes.chartWrapper}>
      <div className={classes.pieChart}>
        <p>Opportunities by Type</p>
        <Doughnut
          height={190}
          data={doughnutData.data}
          options={doughnutData.options}
          plugins={doughnutData.plugins}
          className="chart-canvas"
          id="Opportunities-by-type"
        />
      </div>
      <div className={classes.lineChart}>
        <p>Referrals by Status</p>
        <Bar
          height={90}
          data={barData.data}
          options={barData.options}
          className="chart-canvas"
          id="chart-bars"
        />
      </div>
    </div>
  );
};

export default StatusChart;
