import Modal, { ModalBody } from 'components/FormFields/Modal';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import classes from './InvitePartnerTeam.module.scss';
import InvitePartnerTeamForm from './InvitePartnerTeamForm';
import ConfirmInvitation from './ConfirmInvitation';
import RolePermissions from '../../RolePermissions';
import { useDispatch, useSelector } from 'react-redux';
import { get, map } from 'lodash';
import { fetchPartnerTeamRoles } from 'store/actions/partnerTeams';

const InvitePartnerTeam = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const [openInviteForm, setOpenInviteForm] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [roleToInvite, setRoleToInvite] = useState('');
  const [selectedRoleInfo, setSelectedRoleInfo] = useState(null);

  const [invitationValues, setInvitationValues] = useState({});

  const handleInviteFormOpen = () => setOpenInviteForm(true);
  const handleInviteFormClose = () => setOpenInviteForm(false);

  const handleConfirmModalOpen = () => setOpenConfirmModal(true);
  const handleConfirmModalClose = () => setOpenConfirmModal(false);
  const auth = useSelector(({ auth }) => auth);
  const userRoles = get(auth, 'user.roles', '');
  let roles = useSelector(partnerTeam => {
    const applicableRoles = get(
      partnerTeam,
      'partnerTeam.partnerTeamRoleState.data',
      []
    );
    return map(applicableRoles, role => {
      switch (role.name) {
        case 'Partner_Administrator':
          return {
            ...role,
            shortName: 'administrator',
            className: 'admin',
            inviteButtonText: 'Invite an Admin',
            abilities: {
              allowed: [
                'Invite Admins, Managers, and Reps to the team and assign user roles.',
                'Assign Supervisors to Reps.',
                'View the organization’s referrals, earnings, and performance.',
                'Deactivate team members.',
              ],
              denied: [],
            },
          };
        case 'Partner_Team_Manager':
          return {
            ...role,
            shortName: 'manager',
            className: 'manager',
            inviteButtonText: 'Invite a Manager',
            abilities: {
              allowed: [
                'Invite Reps to their team.',
                'View referrals, earnings, and performance of their team.',
                'Deactivate Reps from their team.',
              ],
              denied: [
                'View or manage other teams.',
                'Add or deactivate Admins.',
              ],
            },
          };
        case 'Partner_Representative':
          return {
            ...role,
            shortName: 'rep',
            className: 'representative',
            inviteButtonText: 'Invite a Rep',
            abilities: {
              allowed: [
                'Make and track their own referrals.',
                'See earnings they sourced* (*if authorized by an Admin)',
              ],
              denied: [
                'View or manage their teammates.',
                'Add or deactivate users',
              ],
            },
          };
        default:
          return role;
      }
    });
  });
  if (userRoles.includes('Partner_Team_Manager'))
    roles = roles.filter(r => r.name == 'Partner_Representative');
  useEffect(() => {
    setInvitationValues({});
  }, [isOpen]);

  useEffect(() => {
    dispatch(fetchPartnerTeamRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectRole = role => {
    setRoleToInvite(role);
    handleInviteFormOpen();
    handleClose();
  };

  const handleSetInvitationValue = values => {
    const getRole = roles.find(({ name }) => name === values.role);

    setSelectedRoleInfo(getRole);
    setInvitationValues(values);

    handleConfirmModalOpen();
    handleInviteFormClose();
  };

  return (
    <>
      {openInviteForm ? (
        <InvitePartnerTeamForm
          invitationValues={invitationValues}
          currentRole={roleToInvite}
          totalRoles={roles}
          isModalOpen={openInviteForm}
          closeModal={handleInviteFormClose}
          handleSetInvitationValue={handleSetInvitationValue}
        />
      ) : null}
      {openConfirmModal ? (
        <ConfirmInvitation
          data={invitationValues}
          selectedRoleInfo={selectedRoleInfo}
          isOpen={openConfirmModal}
          onClose={handleConfirmModalClose}
          openInviteForm={handleInviteFormOpen}
        />
      ) : null}
      <Modal
        isOpen={isOpen}
        toggle={handleClose}
        size="lg"
        headerClassName={classes.header}
        title={
          <div>
            <h3>Invite A Team Member</h3>
            <p>
              Select the role you want to assign for this invite to get started.
            </p>
          </div>
        }
        className={classes.modalWrapper}
      >
        <ModalBody>
          <RolePermissions
            showActions={true}
            handleSelectRole={handleSelectRole}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default InvitePartnerTeam;
