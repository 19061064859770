import React, { useEffect, useMemo, useState } from 'react';
import classes from './FileValidationSection.module.scss';
import { ReactComponent as AddIcon } from 'assets/img/icons/add-icon.svg';
import CustomAccordion from 'components/CustomAccordion';
import { Button } from 'reactstrap';
import cx from 'classnames';
import { useParams } from 'react-router';
import TaxReturnsForm from '../TaxReturnsForm/TaxReturnsForm';
import { useDispatch, useSelector } from 'react-redux';
import { get, groupBy, map as mapObj, orderBy } from 'lodash';
import {
  deleteTaxReturnsFormValidation,
  getFormTypes,
  postTaxReturnStatus,
  postTaxReturnsFormValidation,
} from 'store/actions/taxReturns';

const FileValidationSection = ({ currentDocumentId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { documentBucketId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }, [errorMessage]);
  const validationForm = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'taxReturnsFileValidation.data.data', {});
  });
  const initialFormStatus = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'status.data.data', {});
  });
  const isFullyIntact = useMemo(() => !!initialFormStatus.fully_intact, [
    initialFormStatus,
  ]);
  const documents = useSelector(({ documents }) =>
    get(documents, `documentStudyTypes.data.data`, [])
  );

  const filledForms = useSelector(({ taxReturns }) => {
    return get(taxReturns, `filledForms.data`, []);
  }).map(form => ({
    ...form,
    document_type: form.form_type || form.document_type,
  }));

  const { years } = documents?.find(
    d => d?.financial_year_document_id == documentBucketId
  );
  useEffect(() => {
    setSelectedButtons(
      Object.keys(initialFormStatus).filter(key => !!initialFormStatus[key])
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormStatus]);

  // @TODO update when we have API to fetch submitted form
  const handleUpdateForm = (item, isMultiFilling = false) => {
    const findSameYear = (filledForms || []).filter(
      form =>
        form.financial_year_id === item.financial_year_id &&
        form.document_type === item.document_type
    );

    const form_index = findSameYear.length + 1;

    dispatch(
      postTaxReturnsFormValidation(
        {
          ...item,
          form_index,
          is_draft: true,
          form: {},
        },
        false
      )
    );
  };
  const handleAddForm = (item = {}) => {
    dispatch(
      postTaxReturnsFormValidation(
        {
          ...item,
          tempId: Math.round(Math.random() * 100), // needed initially when form is not saved
          is_draft: true,
          form: {},
        },
        false
      )
    );
  };

  const handleRemoveForm = (formId, item = {}) => {
    dispatch(
      deleteTaxReturnsFormValidation({ ...item, id: formId }, currentDocumentId)
    );
  };

  const handleButtonSelection = buttonName => {
    const updatedFormStatus =
      buttonName === 'fully_intact'
        ? { not_tax_return: false, missing_pages: false, fully_intact: true }
        : {
            ...initialFormStatus,
            [buttonName]: !selectedButtons.includes(buttonName),
          };
    dispatch(
      postTaxReturnStatus(
        currentDocumentId,
        updatedFormStatus,
        async status => {
          if (!status.fully_intact) {
            return;
          }
          await dispatch(getFormTypes());
        }
      )
    );
  };
  const handleCollapseAll = () => {
    setIsCollapsed(true);
    setActiveIndex(null);
  };

  const handleExpandAll = () => {
    setIsCollapsed(false);
    setActiveIndex(0);
  };
  const renderForm = ({
    id,
    form_index,
    index,
    status,
    isMultiFilling,
    tempId,
    form,
    financial_year_id,
    document_type,
  }) => {
    return (
      <div className={classes.questionContainer} key={id}>
        <TaxReturnsForm
          forms={filledForms}
          formId={id}
          tempId={tempId}
          form_index={form_index}
          index={index + 1}
          isCollapsed={isCollapsed}
          handleAddForm={handleAddForm}
          handleRemoveForm={handleRemoveForm}
          handleUpdateForm={handleUpdateForm}
          financialYear={id || tempId ? financial_year_id : null}
          currentDocumentId={currentDocumentId}
          formStatus={status}
          yearData={years}
          documentType={id || tempId ? document_type : null}
          isMultiFilling={isMultiFilling}
          form={form}
          setErrorMessage={setErrorMessage}
        />
      </div>
    );
  };

  return (
    <div className={classes.content}>
      <>
        <div className={classes.collapseButtonWrapper}>
          <Button
            className={classes.collapseButton}
            onClick={isCollapsed ? handleExpandAll : handleCollapseAll}
          >
            {isCollapsed ? 'Expand All' : 'Collapse All'}
          </Button>
        </div>
        <div className={classes.fileValidationWrapper}>
          <CustomAccordion
            key={0}
            index={0}
            activeIndex={
              isCollapsed ? (activeIndex !== null ? 0 : null) : activeIndex
            }
            onSelect={selectedIndex => {
              if (activeIndex === 0) {
                setActiveIndex(null);
              } else {
                setActiveIndex(selectedIndex);
              }
            }}
            header={
              <div className={classes.accordionHeaderWrapper}>
                <div className={classes.headerContent}>File Validation</div>
                {isFullyIntact && (
                  <div className={classes.selectedOption}>Fully Intact</div>
                )}
              </div>
            }
            customHeaderStyle={{
              paddingTop: '12px',
              paddingBottom: '12px',
              boxShadow: 'none',
              backgroundColor: '#F6F9FC',
            }}
            dropIconPosition="right"
            customArrowStyle={{
              color: '#525F7F',
              fontSize: 'medium',
            }}
            customChildContainerStyle={{ minWidth: '200px' }}
            customActiveContentStyle={{ paddingLeft: '20px' }}
          >
            <div>
              <div className={classes.description}>
                Do the tax returns in this file have missing_pages and/or are
                redacted? Select the appropriate options below:
              </div>
              <div className={classes.buttonContainer}>
                <div className={classes.buttonWrapper}>
                  <Button
                    className={cx(classes.button, {
                      [classes.selectedButton]: selectedButtons.includes(
                        'not_tax_return'
                      ),
                    })}
                    name="not_tax_return"
                    onClick={() => {
                      handleButtonSelection('not_tax_return');
                    }}
                  >
                    Not Tax Return(s)
                  </Button>
                  <Button
                    className={cx(classes.button, {
                      [classes.selectedButton]: selectedButtons.includes(
                        'missing_pages'
                      ),
                    })}
                    name="missing_pages"
                    onClick={() => {
                      handleButtonSelection('missing_pages');
                    }}
                  >
                    Missing Pages / Redacted
                  </Button>
                </div>
                <Button
                  name="fully_intact"
                  className={cx(classes.intactButton, {
                    [classes.selectedIntactButton]: selectedButtons.includes(
                      'fully_intact'
                    ),
                  })}
                  onClick={() => {
                    handleButtonSelection('fully_intact');
                  }}
                >
                  Return(s) Look Fully Intact
                </Button>
              </div>
              {!isFullyIntact && (
                <div className={classes.customerText}>
                  <span>**</span> Please reach out to the customer and ask for
                  the entire file before proceeding.
                  <span>**</span>
                </div>
              )}
            </div>
          </CustomAccordion>
        </div>
        {isFullyIntact &&
          (!filledForms?.length ? (
            <div
              className={classes.processTaxWrapper}
              onClick={() => {
                handleAddForm();
              }}
            >
              <div className={classes.processTaxButton}>
                <AddIcon height={30} width={30} />
              </div>
              <div className={classes.processTaxText}>
                Click to process Tax Return found in File
              </div>
            </div>
          ) : (
            <>
              {orderBy(filledForms, [
                'financial_year_id',
                'document_form_id',
                'form_index',
              ]).map((form, index) => {
                return (
                  <React.Fragment key={`form-` + (form.id || form.tempId)}>
                    {renderForm({
                      ...form,
                      index: index,
                      isMultiFilling:
                        form.document_type &&
                        filledForms.filter(
                          v =>
                            v.financial_year_id === form.financial_year_id &&
                            v.document_type === form.document_type
                        ).length > 1,
                    })}
                  </React.Fragment>
                );
              })}
              {/* {mapObj(
                groupBy(filledForms, 'financial_year_id'),
                (values, key) => {
                  return values.map((form, index) => {
                    return (
                      <React.Fragment key={`form-` + index}>
                        {renderForm({
                          ...form,
                          index: index,
                          isMultiFilling:
                            form.document_type &&
                            values.filter(
                              v =>
                                v.financial_year_id ===
                                  form.financial_year_id &&
                                v.document_type === form.document_type
                            ).length > 1,
                        })}
                      </React.Fragment>
                    );
                  });
                }
              )} */}
              {errorMessage && (
                <div className={classes['invalid-feedback']}>
                  {errorMessage}
                </div>
              )}
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.newSectionBUtton}
                  onClick={() => {
                    handleAddForm();
                  }}
                >
                  Add Another Return Section
                </Button>
              </div>
            </>
          ))}
      </>
    </div>
  );
};

export default FileValidationSection;
