import React, { useState } from 'react';
import classes from './InviteTeam.module.scss';
import cx from 'classnames';
import Button from 'components/Button';
import { Card } from 'reactstrap';
import InvitePartnerTeam from './InvitePartnerTeam';

const InviteTeam = () => {
  const [openInvitePartnerModal, setOpenInvitePartnerModal] = useState(false);

  const handleOpenInviteModal = () => setOpenInvitePartnerModal(true);
  const handleCloseInviteModal = () => setOpenInvitePartnerModal(false);

  return (
    <>
      <InvitePartnerTeam
        isOpen={openInvitePartnerModal}
        handleClose={handleCloseInviteModal}
      />
      <Card className={classes.inviteTeamWrapper}>
        <div className={cx('d-flex align-items-center')}>
          <div
            className={cx(
              classes.content,
              'd-flex justify-content-between align-items-center flex-column'
            )}
          >
            <h4 className="display-4 mb-0 text-center px-2 mt-2">
              Invite your team.
            </h4>
            <p>
              Start inviting your team to grow your referral base and track your
              deal pipeline. Once invites are sent, your team members will be
              listed here.
            </p>
            <Button
              onClick={handleOpenInviteModal}
              className={classes.inviteButton}
              color="primary"
            >
              Start Inviting
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default InviteTeam;
