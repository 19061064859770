import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import { Container, Popover } from 'reactstrap';
import InviteTeam from './InviteTeam';
import InvitePartnerTeam from './InviteTeam/InvitePartnerTeam';
import cx from 'classnames';
import classes from './PartnerTeam.module.scss';
import PartnerTeamMembers from './PartnerTeamMembers/PartnerTeamMembers';
import RolePermissions from './RolePermissions';
import history from 'helpers/history';
import { fetchUsersList } from 'store/actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Loading from 'components/Loading';

const PartnerTeam = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openViewPermission, setOpenViewPermission] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState(true);
  const handleAddMember = () => {
    setOpenModal(true);
    setSelectedTeamMember(null);
  };

  const handleViewPermissions = () => {
    setOpenViewPermission(true);
  };

  const handleEditMember = row => {
    history.push(`/admin/team/${row.id}/edit`);
    setSelectedTeamMember(row);
  };
  useEffect(() => {
    setIsFirstTimeLoaded(true);
    dispatch(fetchUsersList(1, '-name', '', 10, null, '', '', 'list'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const { userState } = useSelector(({ user }) => user);
  const userMetadata = get(userState, 'data.meta', {});
  const listLoading = get(userState, 'isInProgress', false);

  const hasMembers = userMetadata.total > 0;
  const handleFilters = () => {
    setIsFirstTimeLoaded(false);
  };
  if (listLoading && !hasMembers) {
    return <Loading />;
  }

  return (
    <>
      <InvitePartnerTeam
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <Popover
        isOpen={openViewPermission}
        toggle={e => {
          setOpenViewPermission(currentState => !currentState);
        }}
        placement={'bottom-end'}
        trigger="legacy"
        popperClassName={classes.popperClassName}
        target={'view-permission-popover'}
        // container={'#' + id}
        // disabled={disabled}
        hideArrow={true}
        className={classes.popover}
      >
        <RolePermissions />
      </Popover>
      <Container fluid className={classes.wrapper}>
        <div className={classes.header}>
          <div>
            {hasMembers && (
              <div>
                <Button
                  className={classes.inviteTeamButton}
                  color="link"
                  leftIcon={<i className="fa fa-plus" aria-hidden="true"></i>}
                  onClick={handleAddMember}
                >
                  Invite Team Member
                </Button>
                <p className="mb-0">
                  Add additional team members here to enable them to make and
                  manage referrals.
                </p>
              </div>
            )}
          </div>
          <Button
            id="view-permission-popover"
            className={cx(classes.viewPermissionButton, {
              [classes.active]: openViewPermission,
            })}
            rightIcon={
              openViewPermission ? (
                <i className="fa fa-times" aria-hidden="true"></i>
              ) : (
                <i></i>
              )
            }
            color="link"
            onClick={handleViewPermissions}
          >
            View Permissions
          </Button>
        </div>
        {hasMembers || !isFirstTimeLoaded ? (
          <PartnerTeamMembers
            handleEditMember={handleEditMember}
            handleFilters={handleFilters}
          />
        ) : (
          <InviteTeam />
        )}
      </Container>
    </>
  );
};

export default PartnerTeam;
