import * as React from 'react';
const Trash = props => {
  return (
    <svg
      cursor={'pointer'}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill="#333"
        d="M11.146 14.166h.625c.156 0 .312-.13.312-.312V8.229a.336.336 0 0 0-.312-.313h-.625a.317.317 0 0 0-.313.313v5.625a.3.3 0 0 0 .313.312Zm4.27-8.75h-2.16l-.886-1.458a1.26 1.26 0 0 0-1.068-.625h-2.63c-.443 0-.86.26-1.068.625L6.72 5.416H4.583a.428.428 0 0 0-.416.417v.417c0 .234.182.416.416.416H5v8.75c0 .703.547 1.25 1.25 1.25h7.5a1.25 1.25 0 0 0 1.25-1.25v-8.75h.417a.428.428 0 0 0 .416-.416v-.417a.448.448 0 0 0-.416-.417ZM8.62 4.661c.026-.026.078-.078.13-.078H11.224c.052 0 .104.052.13.078l.443.755h-3.62l.443-.755Zm5.13 10.755h-7.5v-8.75h7.5v8.75Zm-5.52-1.25h.624c.157 0 .313-.13.313-.312V8.229a.336.336 0 0 0-.313-.313H8.23a.317.317 0 0 0-.312.313v5.625a.3.3 0 0 0 .312.312Z"
      />
    </svg>
  );
};
export default Trash;
