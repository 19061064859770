import Button from 'components/Button';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import React from 'react';
import cx from 'classnames';
import classes from './ConfirmInvitation.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { inviteTeamMember } from 'store/actions/partnerTeams';
import { getIcon } from 'views/pages/PartnerTeam/constants';
import { get } from 'lodash';

const ConfirmInvitation = ({
  isOpen,
  onClose,
  data,
  openInviteForm,
  selectedRoleInfo,
}) => {
  const dispatch = useDispatch();

  const {
    name,
    className,
    shortName,
    abilities = { allowed: [], denied: [] },
  } = selectedRoleInfo;

  const loading = useSelector(({ partnerTeam }) =>
    get(partnerTeam, 'inviteTeamMember.isInProgress', false)
  );

  const handleAddTeamMember = async data => {
    const {
      email_address = '',
      first_name = '',
      last_name = '',
      role = '',
      personal_note = '',
      see_earning = null,
      title = '',
      supervisor = '',
      phone = '',
    } = data;

    const member = {
      email_address: email_address,
      first_name: first_name,
      last_name: last_name,
      role: role,
      personal_note: personal_note,
      show_macro_onboarding: false,
      see_earning: see_earning,
      title: title,
      supervisors: [supervisor?.id],
      phone,
    };
    const { status } = await dispatch(inviteTeamMember(member));
    if (status === 1) {
      onClose();
    }
  };

  return (
    <Modal
      title="Confirm Invitation"
      isOpen={isOpen}
      toggle={onClose}
      className={classes.modalWrapper}
    >
      <ModalBody>
        <div className={classes.memberDetails}>
          <h3>
            Please confirm that you want to add the following collaborator
            Strike Partner Portal for Ventive, LLC :
          </h3>
          <div className={classes.details}>
            <p>
              {data?.first_name} {data?.last_name}
            </p>
            <p>{data?.email_address}</p>
            <p>{data?.phone}</p>
          </div>
        </div>
        {selectedRoleInfo?.name === 'Partner_Representative' && (
          <div className={classes.supervisorDetails}>
            <h3>Their Supervisor:</h3>
            <div className={classes.details}>
              <p>{data?.supervisor?.name}</p>
            </div>
          </div>
        )}
        <div className={classes.assignedRole}>
          <h3>Their Assigned Role:</h3>
          <div className={cx(classes.role, classes[className])}>
            <div className={classes.content}>
              <div className={classes.title}>
                {getIcon(name)} <h4>{className}</h4>
              </div>
              <div className={classes.abilities}>
                {abilities?.allowed?.length !== 0 && (
                  <div className={classes.privileges}>
                    <h5>
                      <i className="fa fa-check" aria-hidden="true"></i>
                      {shortName}s can:{' '}
                    </h5>
                    <ul className={classes.list}>
                      {abilities?.allowed?.map((privileges, index) => (
                        <li key={index}>{privileges}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {abilities?.denied?.length !== 0 && (
                  <div className={classes.prohibitions}>
                    <h5>
                      <i className="fa fa-times" aria-hidden="true"></i>
                      {shortName}s can't:
                    </h5>
                    <ul className={classes.list}>
                      {abilities?.denied?.map((privileges, index) => (
                        <li key={index}>{privileges}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        className={cx(
          'd-flex pt-0 mt-2 justify-content-between',
          classes.modalFooter
        )}
      >
        <Button
          className={classes.back}
          onClick={() => {
            onClose();
            openInviteForm();
          }}
          color="link"
          leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
        >
          Back to Edit
        </Button>
        <Button
          type="submit"
          id="inviteUsersBtn"
          color={'primary'}
          className={'float-left'}
          onClick={() => {
            handleAddTeamMember(data);
          }}
          // disabled={!isValid || isLoading}
          loading={loading}
        >
          Confirm and Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmInvitation;
