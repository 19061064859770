import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  fetchCompletedStudies,
  getDashboardMessages,
} from 'store/actions/userDashboard';
import { fetchProjectsList as fetchProjects } from 'store/actions/projects';
import { fetchSupportStaffList } from 'store/actions/supportStaff';
import { fetchNotificationsList } from 'store/actions/notifications';
import ClientOnboarding from '../Fields/ClientOnboarding';
import cx from 'classnames';
import classes from './ClientDashboard.module.scss';
import queryString from 'query-string';
import { toNumber, get } from 'lodash';
import { useLocation } from 'react-router';

import ActiveStudies from 'views/widgets/ActiveStudies';
import Messages from '../Fields/Messages';
import SupportTeam from 'views/pages/dashboards/ClientDashboard/SupportTeam';

const ClientDashboard = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const [storyId, setStoryId] = useState('');
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const showOnboarding = get(queryProps, 'showOnboarding', 'false') === 'true';

  const messages = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.dashboardWidgetMessages', [])
  );
  const isMessagesLoading = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.isDashboardWidgetInProgress', false)
  );
  const shouldMakeCall = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.shouldMakeCall', true)
  );

  useEffect(() => {
    // Note: we are making api call with static page param
    // with assumption that user won't have more studies
    dispatch(fetchProjects(1, '', '', 1));
    dispatch(fetchNotificationsList(null));
    dispatch(fetchCompletedStudies());
    dispatch(getDashboardMessages('widgetList'));
    dispatch(
      fetchSupportStaffList(currentUser?.client_id, null, null, null, {
        strike_staff: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const recentActivityMarginTop = { marginTop: '3rem' };

  const pageHeading = {
    marginTop: '20px',
    marginBottom: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '-0.01em',
    color: '#32325D',
  };

  useEffect(() => {
    if (queryProps.story) {
      setStoryId(toNumber(queryProps.story));
    }
  }, [queryProps.story]);

  return (
    <>
      <Container fluid>
        {currentUser && (
          <h4 style={pageHeading}>Welcome back, {currentUser?.name}!</h4>
        )}
        <Row className={cx('mt-2 ', classes.row)}>
          <Col lg="8" className="p-3 ">
            <ActiveStudies />
            <div style={recentActivityMarginTop}>
              <Messages
                // showFilter
                notifications={messages}
                shouldMakeCall={shouldMakeCall}
                isMessagesLoading={isMessagesLoading}
              />
            </div>
          </Col>
          <Col lg="4" className="p-3 ">
            <SupportTeam />
          </Col>
        </Row>
      </Container>
      <ClientOnboarding open={showOnboarding} />
    </>
  );
};

export default ClientDashboard;
