import React from 'react';
import { array, bool, number, object, string } from 'yup';
import {
  GenerateFormikArrayField,
  GenerateFormikDateField,
  GenerateFormikRadioField,
  GenerateFormikSelectField,
  GenerateFormikTextInput,
  checkValidToRender,
} from './formikFields';
import { GenerateLabel } from './components';

export const getStringSchema = (label, isRequired, dependsOn) => {
  let stringSchema = string()
    .label(label)
    .nullable()
    .default(null);

  return stringSchema;
};
export const getNumberSchema = (label, isRequired, dependsOn) => {
  let numberSchema = number()
    .label(label)
    .nullable()
    .default(null)
    .typeError('must be a numerical value');

  return numberSchema;
};

export const getYupObjectSchema = (schemaObj, isRequired) => {
  const objectSchema = object()
    .shape({ ...schemaObj })
    .default({});

  return objectSchema;
};

export const getBooleanSchema = (label, isRequired, dependsOn) => {
  let boolSchema = bool()
    .label(label)
    .nullable()
    .default(null);

  return boolSchema;
};

export const getYupArraySchema = (obj, label) => {
  const objectSchema = getYupObjectSchema(obj);
  return array()
    .of(objectSchema)
    .default([]);
};

export const generateComponentSchema = (
  schemaObject,
  style = {},
  customName,
  dependsOn = [],
  className = '',
  nestedFields
) => {
  let schema;
  let Component;
  const { type, required, name, label, options, multiple } = schemaObject;
  if (multiple) {
    const { schema: nestedFieldSchema } = nestedFields;
    Component = GenerateFormikArrayField;
    schema = () => getYupArraySchema(nestedFieldSchema);
  } else {
    switch (type) {
      case 'radio':
        schema = (options || []).every(o => typeof o.value === 'boolean')
          ? getBooleanSchema
          : getStringSchema;
        Component = GenerateFormikRadioField;
        break;
      case 'date':
        schema = getStringSchema;
        Component = GenerateFormikDateField;
        break;
      case 'select':
        schema = getStringSchema;
        Component = GenerateFormikSelectField;
        break;
      case 'number':
      case 'text':
        schema = type === 'number' ? getNumberSchema : getStringSchema; // type number for numeric value
        Component = GenerateFormikTextInput;
        break;
      case 'critical_info_label':
        schema = null;
        Component = GenerateLabel;
        break;
      default:
        schema = null;
        Component = null;
        break;
    }
  }

  const componentProps = {
    obj: schemaObject,
    style,
    name: customName || name,
    dependsOn,
    className: className,
    nestedFields: nestedFields?.Component,
  };
  return {
    schema: schema ? schema(label, required, dependsOn) : null,
    Component: Component ? <Component {...componentProps} /> : <></>,
  };
};
