import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

const toMatch = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
];

export const detectMobile = () => {
  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return '-';
  const cleaned = phoneNumber.replace(/\D/g, '');
  const formatted = `(${cleaned.substring(0, 3)}) ${cleaned.substring(
    3,
    6
  )} - ${cleaned.substring(6)}`;
  return formatted;
};

export const validatePassword = (value = '') => {
  // Note: We can use regex /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&]).{8})/ to validate password
  // instead of below logic, but regex contains look ahead operations and it is not supporting in safari as of now
  // until safari and IE doesnt support it, we will use below logic

  if (value.length < 8) {
    // password should be atleast 8 characters
    return false;
  }
  if (value.search(/[a-z]/) < 0) {
    // password should have atleast 1 lowercase character
    return false;
  }
  if (value.search(/[A-Z]/) < 0) {
    // password should have atleast 1 uppercase character
    return false;
  }
  if (value.search(/[0-9]/) < 0) {
    // password should have atleast 1 digit
    return false;
  }
  if (value.search(/[~`!@#$%^&*(),./;'[\]|}{":?><_+\-= ]/) < 0) {
    // password should have atleast 1 special character
    return false;
  }
  return true;
};

/**
 * This function will allow us to download the file
 * @param file - Response of /download endpoint which is file data
 * @param fileName - Name of the file
 */
export const fileDownload = (file, fileName, type = {}) => {
  const url = window.URL.createObjectURL(new Blob([file.data], type));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

/**
 * Converts the bytes value to readable format
 * @param bytes - Value to be converted
 * @returns {string}
 */
export const byteToReadbleSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const fileExtensionList = [
  'ai',
  'aif',
  'apk',
  'arj',
  'asp',
  'bat',
  'bin',
  'bmp',
  'cda',
  'cer',
  'cfm',
  'cgi',
  'com',
  'css',
  'csv',
  'dat',
  'db',
  'deb',
  'doc',
  'docx',
  'exe',
  'fnt',
  'fon',
  'gif',
  'html',
  'ico',
  'jar',
  'jpg',
  'js',
  'jsp',
  'key',
  'log',
  'mdb',
  'mid',
  'mp3',
  'mpa',
  'odp',
  'ogg',
  'otf',
  'pdf',
  'php',
  'pkg',
  'png',
  'pps',
  'ppt',
  'ps',
  'psd',
  'py',
  'rar',
  'rpm',
  'rss',
  'rtf',
  'sav',
  'sql',
  'svg',
  'tar',
  'tex',
  'tif',
  'ttf',
  'txt',
  'wav',
  'wks',
  'wma',
  'wpd',
  'wpl',
  'wsf',
  'xhtml',
  'xls',
  'xml',
  'z',
  'zip',
];
export const imageFileExtensionList = [
  'ai',
  'bmp',
  'gif',
  'ico',
  'jpg',
  'png',
  'psd',
  'ps',
  'svg',
  'tif',
];

export const generateRandomNumber = (digit = 5) => {
  let min = Math.pow(10, digit - 1); // 10000;
  let max = Math.pow(10, digit) - 1; // 99999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getUniqDataInOrder = (
  data,
  uniqueField = 'id',
  orderField = 'id',
  order = 'asc'
) => uniqBy([...orderBy([...data], orderField, order)], uniqueField);

export const getCurrencyStringToNumber = value => {
  return parseFloat(value.substring(1).replaceAll(',', ''));
};

export const phoneNumberRegex = new RegExp(
  new RegExp(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/).source +
    '|' +
    new RegExp(/^[0-9]{3}[.][0-9]{3}[.][0-9]{4}$/).source +
    '|' +
    new RegExp(/^\d{10}$/).source
);

export const getStringFromHtml = html => {
  return html?.replace(/<[^>]*>/g, '') || '';
};

export const yearOptions = [
  { label: '2019', value: 24501 },
  { label: '2020', value: 24502 },
  { label: '2021', value: 24503 },
  { label: '2022', value: 24504 },
  { label: '2023', value: 39773 },
];
export const returnTypeOptions = [
  { label: '1040', value: 1 },
  { label: '1065', value: 2 },
  { label: '1120', value: 3 },
  { label: '1120S', value: 4 },
];

const values = ['k', 'm', 'b', 't'];
function formatCurrency(number, decPlaces) {
  decPlaces = Math.pow(10, decPlaces);

  for (let i = values.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number == 1000 && i < values.length - 1) {
        number = 1;
        i++;
      }

      number += values[i];

      break;
    }
  }

  return number;
}

export const withCurrency = (
  number,
  params = { isShorted: false, minimumFractionDigits: 2, isRoundOf: false }
) => {
  if (number === null || number === undefined) return '-';

  const {
    isShorted = false,
    minimumFractionDigits = 2,
    isRoundOf = false,
  } = params;

  let result = '0';
  if (number !== 0 && number !== null) {
    if (isShorted) {
      result = formatCurrency(Math.round(number), minimumFractionDigits);
    }

    if (!isShorted) {
      const withDecimal = new Intl.NumberFormat('en-Us', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: minimumFractionDigits,
      }).format(number);

      result = withDecimal;
      if (isRoundOf) {
        result = Math.round(withDecimal);
      }
    }

    return `$${result}`;
  } else {
    return '';
  }
};
