import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Button from 'components/Button';
import { Form, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classes from './RelatedEntityModal.module.scss';
import cs from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';
import {
  addRelatedEntity,
  deleteRelatedEntity,
} from 'store/actions/dealDeskActions';

const RelatedEntityModal = forwardRef(
  ({ id, relatedEntity, updateActivity }, ref) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      open: d => {
        setData(d);
        setOpen(true);
      },
    }));
    const toggleModal = () => {
      setOpen(!open);
    };

    // const schema = Yup.object().shape(dynamicSchema);
    const schema = Yup.object().shape({
      entityName: Yup.string()
        .required('Entity name is required')
        .max(255, 'The entity name must be less than 255 chars'),
      entityAddressLine1: Yup.string()
        .required('Entity address line 1 is required')
        .max(500, 'The entity address line 1 must be less than 500 chars'),
      entityCity: Yup.string()
        .required('Entity city is required')
        .max(100, 'The entity city must be less than 100 chars'),
      entityState: Yup.string()
        .required('Entity state is required')
        .max(100, 'The entity state must be less than 100 chars'),
      entityZip: Yup.string()
        .required('Entity zip is required')
        .max(6, 'The entity zip must be less than 6 chars'),
    });

    const handleSubmit = async values => {
      const data = {
        entity_name: values.entityName,
        address1: values.entityAddressLine1,
        address2: values.entityAddressLine2,
        city: values.entityCity,
        state: values.entityState,
        zip: values.entityZip,
        entity_id: relatedEntity?.entity_id,
      };
      setLoading(true);
      await dispatch(addRelatedEntity(id, data));
      updateActivity();
      setOpen(false);
      setLoading(false);
    };

    return (
      <Modal
        centered={true}
        scrollable
        fade={false}
        isOpen={open}
        toggle={toggleModal}
        className={classes.modalEditContract}
      >
        <ModalHeader className={classes.header}>
          {!relatedEntity ? 'Add' : 'Edit'} Related Entity
        </ModalHeader>
        <Formik
          validateOnMount
          initialValues={{
            entityName: relatedEntity ? relatedEntity.entity_name : '',
            entityAddressLine1: relatedEntity ? relatedEntity.address1 : '',
            entityAddressLine2: relatedEntity ? relatedEntity.address2 : '',
            entityCity: relatedEntity ? relatedEntity.city : '',
            entityState: relatedEntity ? relatedEntity.state : '',
            entityZip: relatedEntity ? relatedEntity.zip : '',
          }}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isValid, values, errors, setFieldValue }) => {
            return (
              <ModalBody className={classes.modalRelatedEntity}>
                <Form>
                  <FormGroup>
                    <label htmlFor="entityName">Entity Name</label>
                    <FormikInput
                      name="entityName"
                      placeholder="Entity Name"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="entityAddressLine1">Entity Address</label>
                    <FormikInput
                      name="entityAddressLine1"
                      placeholder="Entity Address"
                      type="text"
                    />
                  </FormGroup>
                  <div className={classes.cityStateInfo}>
                    <FormGroup>
                      <label htmlFor="entityCity">Entity City</label>
                      <FormikInput
                        name="entityCity"
                        placeholder="City"
                        type="text"
                        style={{ width: 230 }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="entityState">Entity State</label>
                      <FormikInput
                        name="entityState"
                        placeholder="State"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="entityZip">Entity Zip</label>
                      <FormikInput
                        name="entityZip"
                        placeholder="Zip"
                        type="text"
                      />
                    </FormGroup>
                  </div>
                  <div className={classes.buttons}>
                    {relatedEntity ? (
                      <button
                        type="button"
                        color="link"
                        loading={true}
                        className={cs('btn btn-secondary', classes.delete)}
                        onClick={async () => {
                          await dispatch(
                            deleteRelatedEntity(id, relatedEntity?.entity_id)
                          );
                          toggleModal();
                          updateActivity();
                        }}
                      >
                        Delete
                      </button>
                    ) : (
                      <div></div>
                    )}
                    <div>
                      <button
                        type="button"
                        className={cs('btn btn-secondary', classes.deny)}
                        onClick={() => toggleModal()}
                      >
                        Cancel
                      </button>
                      <Button
                        disabled={loading}
                        loading={loading}
                        type="submit"
                        onClick={handleSubmit}
                        className={cs('btn btn-success', classes.submit)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              </ModalBody>
            );
          }}
        </Formik>
      </Modal>
    );
  }
);

export default RelatedEntityModal;
