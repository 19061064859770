import Types from 'store/types/taxReturns';

const initialState = {
  status: {
    data: '',
    isInProgress: false,
    message: '',
    isSubmitting: false,
  },
  taxReturnsFileValidation: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    isSubmitting: false,
    data: {},
    isDeleting: false,
  },
  formTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  filledForms: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  currentFile: {
    data: [],
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_FILE_VALIDATION_FORM_INPROGRESS:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isInProgress: true,
        },
      };
    case Types.FETCH_FILE_VALIDATION_FORM_SUCCESS:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_FILE_VALIDATION_FORM_FAILURE:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_TAX_RETURNS_FILE_VALIDATION_INPROGRESS:
      return {
        ...state,

        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isSubmitting: !!action?.isSubmitting,
        },
      };
    case Types.POST_TAX_RETURNS_FILE_VALIDATION_SUCCESS:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          status: 1,
          data: action.data,
          isSubmitting: !!action?.isSubmitting,
        },
      };
    case Types.POST_TAX_RETURNS_FILE_VALIDATION_FAILURE:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isError: true,
          message: action.message,
          isSubmitting: !!action?.isSubmitting,
        },
      };
    case Types.SET_CURRENT_FILE:
      return {
        ...state,
        currentFile: {
          data: action.data,
        },
      };
    case Types.DELETE_TAX_RETURNS_FILE_VALIDATION__INPROGRESS:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isDeleting: true,
        },
      };
    case Types.DELETE_TAX_RETURNS_FILE_VALIDATION_SUCCESS:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isDeleting: false,
          status: 1,
        },
      };
    case Types.DELETE_TAX_RETURNS_FILE_VALIDATION_FAILURE:
      return {
        ...state,
        taxReturnsFileValidation: {
          ...state.taxReturnsFileValidation,
          isDeleting: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TAX_RETURNS_STATUS_INPROGRESS:
      return {
        ...state,
        status: {
          ...state.status,
          isInProgress: true,
        },
      };
    case Types.FETCH_TAX_RETURNS_STATUS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TAX_RETURNS_STATUS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_TAX_RETURNS_STATUS_INPROGRESS:
      return {
        ...state,
        status: {
          ...state.status,
          isSubmitting: true,
        },
      };
    case Types.POST_TAX_RETURNS_STATUS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          status: 1,
          data: action.data,
          isSubmitting: false,
        },
      };
    case Types.POST_TAX_RETURNS_STATUS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          isError: true,
          message: action.message,
          isSubmitting: true,
        },
      };
    case Types.FETCH_FORM_TYPES_INPROGRESS:
      return {
        ...state,
        formTypes: {
          ...state.formTypes,
        },
      };
    case Types.FETCH_FORM_TYPES_SUCCESS:
      return {
        ...state,
        formTypes: {
          ...state.formTypes,
          status: 1,
          data: action.data,
          isInProgress: false,
        },
      };
    case Types.FETCH_FORM_TYPES_FAILURE:
      return {
        ...state,
        formTypes: {
          ...state.formTypes,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_FILLED_FORM_INPROGRESS:
      return {
        ...state,
        filledForms: {
          ...state.filledForms,
        },
      };
    case Types.FETCH_FILLED_FORM_SUCCESS:
      return {
        ...state,
        filledForms: {
          ...state.filledForms,
          status: 1,
          data: action.data,
          isInProgress: false,
        },
      };
    case Types.FETCH_FILLED_FORM_FAILURE:
      return {
        ...state,
        filledForms: {
          ...state.filledForms,
          isError: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
};
