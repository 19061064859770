import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classes from './OwnerShip.module.scss';
import BaseTable from 'components/Table';
import cx from 'classnames';
import Button from 'components/Button';
import { ReactComponent as AddIcon } from 'assets/img/icons/add-icon.svg';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import Input from 'components/FormFields/Input';
import CircularProgress from 'components/CircularProgress';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import ConfirmationModal from '../ConfirmationModal';
import { uniq } from 'lodash';

const CONFIRMATION_TITLE = 'Confirm Removing Owner';

export const ownerValidationSchema = Yup.object().shape({
  id: Yup.number(),
  ownerName: Yup.string().required('Owner Name is required'),
  percentageOwned: Yup.number()
    .default(0)
    .required('Percentage Owned is required')
    .typeError('Percentage Owned is required'),
});

const RenderForm = ({ values, handleChange, handleBlur, errors, touched }) => {
  return (
    <div className={classes.inputWrapper}>
      <div className={classes.input}>
        <Input
          name="ownerName"
          id="ownerName"
          placeholder="Enter Name"
          label="Owner Name "
          labelClassName={classes.label}
          value={values.ownerName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.ownerName}
          touched={touched.ownerName}
        />
      </div>
      <div className={classes.percentageWrapper}>
        <div className={classes.input}>
          <Input
            name="percentageOwned"
            id="percentageOwned"
            type="number"
            max={100}
            min={0}
            placeholder="Enter Percentage Owned"
            label="Percentage Owned  "
            labelClassName={classes.label}
            value={values.percentageOwned}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.percentageOwned}
            touched={touched.percentageOwned}
          />
        </div>
        <CircularProgress percentage={values.percentageOwned} />
      </div>
    </div>
  );
};

const OwnerShip = ({
  value,
  companyName,
  setFieldValue,
  isMultiOwner,
  completed,
  setInvalidateFields,
}) => {
  const [ownerShipData, setOwnerShipData] = useState(value || []);
  useEffect(() => {
    setOwnerShipData(value);
  }, [value]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: null });
  const totalPercentage = useMemo(() => {
    return ownerShipData.reduce(
      (total, owner) => total + owner?.percentageOwned,
      0
    );
  }, [ownerShipData]);
  const [editData, setEditData] = useState({
    id: (value || []).length,
    ownerName: '',
    percentageOwned: '',
  });

  useEffect(() => {
    if (isMultiOwner && (!value.length || totalPercentage < 100)) {
      setInvalidateFields(prev => uniq([...prev, 'owners']));
    } else {
      setInvalidateFields(prev => prev.filter(p => p !== 'owners'));
    }
  }, [value, isMultiOwner, setInvalidateFields, totalPercentage]);

  useEffect(() => {
    if (!isMultiOwner) {
      setFieldValue('owners', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiOwner, setFieldValue]);
  const handleEdit = value => {
    setShowAddModal(true);

    setEditData({
      ...value,
    });
  };
  const handleRemove = value => {
    setDeleteModal({ isOpen: true, data: value });
  };

  const dropdownOptions = [
    {
      text: 'Edit Owner',
      onClick: data => {
        setIsEditing(true);
        handleEdit(data);
      },
    },
    {
      text: 'Remove Owner',
      onClick: data => {
        handleRemove(data);
      },
    },
  ];

  const handleSubmit = (values, action) => {
    const findIndex = ownerShipData.findIndex(o => o.id === values.id);
    const variablePercent = isEditing
      ? totalPercentage -
        ownerShipData[values.id - 1].percentageOwned +
        values.percentageOwned
      : totalPercentage + values.percentageOwned;
    let finalData = [...ownerShipData];
    if (variablePercent > 100) {
      return action.setFieldError(
        'percentageOwned',
        'Total Ownership percentage must not be more than 100%'
      );
    }

    if (findIndex !== -1 && isEditing) {
      finalData.splice(findIndex, 1, values);
      setOwnerShipData(() => finalData);
    } else {
      finalData.push({ ...values, id: finalData.length + 1 });
      setOwnerShipData(finalData);
    }
    if (isEditing) setIsEditing(false);
    setEditData({});
    setFieldValue('owners', finalData);
    setShowAddModal(false);
  };

  const handleToggle = () => {
    setShowAddModal(false);
    setEditData({});
    if (isEditing) setIsEditing(false);
  };

  return (
    <>
      {isMultiOwner && (
        <div className={classes.container}>
          <div className={classes.header}>
            Ownership Split
            <span>*</span>
          </div>
          <div className={classes.tableContainer}>
            <BaseTable
              keyField="id"
              data={value}
              search={false}
              paginationOptions={false}
              bordered={false}
              showHeaderWithNoData={true}
              className={cx({ [classes.table]: !value.length })}
              columns={[
                {
                  dataField: 'ownerName',
                  text: 'Owner',
                  sort: false,
                  formatter: (cell, row) => (
                    <>
                      <div
                        title={cell}
                        className={cx(
                          'd-flex align-items-center',
                          classes.owner
                        )}
                        id={`owner-${row.id}`}
                      >
                        <span>{cell || '-'}</span>
                      </div>
                    </>
                  ),
                },
                {
                  dataField: 'percentageOwned',
                  text: '% Owned',
                  sort: false,

                  formatter: (cell, row) => (
                    <>
                      <div
                        title={cell}
                        className={cx(
                          'd-flex align-items-center justify-content-between',
                          classes.name
                        )}
                        id={`owner-${row.id}`}
                      >
                        <span>{cell || '-'}%</span>
                        <span>
                          {!!dropdownOptions.length && !completed && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className={cx(classes.dropdownMenu)}
                              >
                                {dropdownOptions.map((item, index) => (
                                  <DropdownItem
                                    key={index}
                                    href="#"
                                    className={classes.dropDownText}
                                    onClick={() => item.onClick(row)}
                                  >
                                    <span>{item.text}</span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </span>
                      </div>
                    </>
                  ),
                },
              ]}
            />
            {totalPercentage < 100 && !showAddModal && (
              <div className={classes.note}>
                ** Ownership percentage must equal 100%
              </div>
            )}
            <div>
              <Button
                className={classes.addOwnerShipButton}
                type="button"
                leftIcon={<AddIcon />}
                onClick={() => setShowAddModal(true)}
                disabled={totalPercentage >= 100 || completed}
              >
                Add Owner
              </Button>
            </div>
          </div>
          {showAddModal && (
            <Modal
              size="md"
              backdrop={['static']}
              scrollable
              fade={false}
              title={<>{isEditing ? 'Edit Owner' : 'New Owner'}</>}
              toggle={() => {
                handleToggle();
              }}
              isOpen={showAddModal}
            >
              <ModalBody>
                <Formik
                  validationSchema={ownerValidationSchema}
                  initialValues={{
                    id: editData.id ?? value.length,
                    ownerName: editData.ownerName || '',
                    percentageOwned: editData.percentageOwned || null,
                  }}
                  onSubmit={(values, action) => {
                    handleSubmit(values, action);
                  }}
                  enableReinitialize
                >
                  {({
                    values,

                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    isValid,
                    dirty,
                  }) => {
                    return (
                      <Form>
                        <RenderForm
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors}
                          touched={touched}
                          setOwnerShipData={setOwnerShipData}
                          ownerShipData={ownerShipData}
                          editData={editData}
                          totalPercentage={totalPercentage}
                        />
                        <div className={classes.buttonContainer}>
                          <Button
                            onClick={() => {
                              handleToggle();
                            }}
                            className={classes.cancel}
                            type="button"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={
                              !isValid || !dirty || totalPercentage > 100
                            }
                            type="submit"
                            className={cx(classes.add, {
                              [classes.disabled]: !isValid || !dirty,
                            })}
                          >
                            {isEditing ? 'Update' : 'Add'} Owner
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </ModalBody>
            </Modal>
          )}
          <ConfirmationModal
            isOpen={deleteModal?.isOpen}
            toggle={() => setDeleteModal({ isOpen: false, data: null })}
            action={() => {
              const result = (value || [])
                .filter(o => o.id !== deleteModal?.data?.id)
                .map((f, index) => ({ ...f, id: index }));
              // setOwnerShipData(result);
              setFieldValue('owners', result);
              setDeleteModal({ isOpen: false, data: null });
            }}
            title={CONFIRMATION_TITLE}
            message={`Are you sure you want to remove ${deleteModal.data
              ?.ownerName || ''} as an owner ${
              companyName ? 'on ' + companyName : ''
            }`}
            buttonGroup={[{ button: 'Cancel' }, { button: ' Delete Owner' }]}
          />
        </div>
      )}
    </>
  );
};

export default OwnerShip;
