import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './DocumentUpload.module.scss';
import Button from 'components/Button';
import * as Yup from 'yup';
import cx from 'classnames';
import RSelect from 'components/FormFields/RSelect';
import { fetchOcrDocumentTypes } from 'store/actions/documentUpload';
import { Form, Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';
import { ModalBody } from 'components/FormFields/Modal';
import { useDropzone } from 'react-dropzone';
import millify from 'millify';
import history from 'helpers/history';

const DocumentUpload = ({
  onSubmit,
  files,
  onDrop,
  removeFile,
  setIsValid,
  setValues,
}) => {
  const uploadFile = useRef();
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    dispatch(fetchOcrDocumentTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Options = useSelector(state => state.documents.ocrDocumentTypes);
  const handleInputRef = event => {
    event.stopPropagation();
    uploadFile.current.click();
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Batch Name is required'),
    type: Yup.string().required('Type is required'),
  });

  let initialValues = {
    name: '',
    type: null,
    Options: [],
  };

  return (
    <div className={classes.uploadSection}>
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          const status = await onSubmit(values);

          if (status) {
            resetForm();
            history.push('/admin/utility/document-processor');
          }
        }}
      >
        {({ isValid, values, setFieldValue, errors }) => {
          setIsValid(isValid);
          setValues(values);
          return (
            <Form id="document-processor-upload">
              <ModalBody
                className={cx('d-flex flex-row flex-wrap', classes.modalBody)}
                id="storyModal-body"
              >
                <div className="w-100">
                  <div>
                    <FormikInput
                      groupClassName="mb-4"
                      name="name"
                      placeholder="Batch Name"
                      type="text"
                    />
                  </div>
                  <div>
                    <RSelect
                      name={'type'}
                      className="mb-4"
                      value={values.type}
                      getOptionLabel={opt => opt.label}
                      getOptionValue={opt => opt.id}
                      placeholder={'Select File Type'}
                      options={Options}
                      error={errors.type}
                      onChange={selectedOption => {
                        setFieldValue('type', selectedOption);
                      }}
                    />
                  </div>
                </div>

                <div
                  className={cx(classes.fileInputWrapper, {
                    [classes.hoverDrop]: isDragActive,
                  })}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} accept=".pdf" ref={uploadFile} />
                  {isDragActive ? (
                    <div className={classes.onDropText}>
                      <p>Drop them Here</p>
                      <div className={classes.dropIcon}>
                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.uploadActions}>
                      <div className={classes.text}>
                        <p>
                          Drop file here to upload
                          <br />
                          <br /> or
                        </p>
                      </div>
                      <Button
                        color="secondary"
                        onClick={handleInputRef}
                        className={classes.uploadButton}
                      >
                        Browse Files
                      </Button>
                    </div>
                  )}
                </div>
                <div className={classes.fileList}>
                  {files.map(({ name, size }, i) => {
                    return (
                      <div key={i} className={classes.file}>
                        <div className={classes.fileDetails}>
                          <img
                            src={require('assets/img/extensions/Icn-file.svg')}
                          />
                          <div className={classes.title}>
                            <h3>{name}</h3>
                            <p>
                              {millify(size, {
                                precision: 0,
                                units: ['B', 'KB', 'MB', 'GB', 'TB'],
                                space: true,
                              })}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => removeFile(i)}
                          className={classes.removeFile}
                        >
                          <i className="fas fa-trash" aria-hidden="true"></i>
                        </div>
                      </div>
                    );
                  })}
                  {isValid && (
                    <div className={classes.docInfo}>
                      Only files in PDF format can be uploaded for W2’s.
                    </div>
                  )}
                </div>
              </ModalBody>
              {/* <ModalFooter className={classes.modalFooter}>
                <Button
                  type="submit"
                  loading={uploadLoading}
                  disabled={!isValid || uploadLoading || files.length === 0}
                  className={classes.next}
                >
                  Upload Files
                </Button>
              </ModalFooter> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DocumentUpload;
