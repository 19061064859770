import {
  getTaxReturnsSummary as getTaxReturnsSummaryApi,
  postTaxReturnsFormValidation as postTaxReturnsFormValidationApi,
  deleteTaxReturnsFormValidation as deleteTaxReturnsFormValidationApi,
  getTaxReturnStatus as getTaxReturnStatusApi,
  postTaxReturnStatus as postTaxReturnStatusApi,
  getFormTypes as getFormTypesApi,
  getFilledForm as getFilledFormApi,
} from 'api/taxReturns';
import Types from 'store/types/taxReturns';
import get from 'lodash/get';
import { store } from '../index';

export const getTaxReturnsSummary = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_INTERACTIONS_LIST_INPROGRESS });
    try {
      const resp = await getTaxReturnsSummaryApi();
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_TAX_RETURNS_SUMMARY_SUCCESS,
          data: { data, meta, total },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TAX_RETURNS_SUMMARY_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TAX_RETURNS_SUMMARY_FAILURE,
        message: error,
      });
    }
  };
};

export const postTaxReturnsFormValidation = (
  payload,
  isSubmitting = true,
  forcedUpdate
) => {
  // pass isSubmitting false while adding form for the first time to avoid API call
  return async dispatch => {
    dispatch({
      type: Types.POST_TAX_RETURNS_FILE_VALIDATION_INPROGRESS,
      ...(isSubmitting ? { isSubmitting: true } : {}),
    });
    try {
      let resp = { payload };
      if (isSubmitting) {
        delete payload.tempId;
        resp = await postTaxReturnsFormValidationApi(payload);
      }
      const status = get(resp, 'status', 0) || !isSubmitting;
      const data = get(resp, 'payload', {});
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});
      const storeData = store.getState();
      const taxValidationForm = get(
        storeData,
        'taxReturns.filledForms.data',
        []
      );
      const submitted_form = [...(taxValidationForm || [])];
      const filterResponse = { ...payload, ...data };
      if (data.id || data.tempId) {
        const index = submitted_form.findIndex(f =>
          !isSubmitting
            ? f.tempId === data.tempId // managed tempId for reducer update only.
            : f.id === data.id ||
              (f.form_index === data.form_index &&
                f.financial_year_id === data.financial_year_id &&
                f.document_form_id === data.document_form_id)
        );

        if (index >= 0) {
          if (
            !payload?.id ||
            payload?.status !== submitted_form[index]?.status ||
            !!forcedUpdate
          ) {
            submitted_form.splice(index, 1, filterResponse);
          }
        } else {
          submitted_form.push(filterResponse);
        }
      } else {
        submitted_form.push(filterResponse);
      }

      if (status) {
        dispatch({
          type: Types.POST_TAX_RETURNS_FILE_VALIDATION_SUCCESS,
          data: {
            data: { ...taxValidationForm, submitted_form: submitted_form },
            meta,
            total,
          },
          ...(isSubmitting ? { isSubmitting: false } : {}),
        });
        // debugger;
        dispatch({
          type: Types.FETCH_FILLED_FORM_SUCCESS,
          data: submitted_form,
          ...(isSubmitting ? { isSubmitting: false } : {}),
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.POST_TAX_RETURNS_FILE_VALIDATION_FAILURE,
          message,
          ...(isSubmitting ? { isSubmitting: false } : {}),
        });
      }

      return { data, total, meta };
    } catch (error) {
      dispatch({
        type: Types.POST_TAX_RETURNS_FILE_VALIDATION_FAILURE,
        message: error,
        ...(isSubmitting ? { isSubmitting: false } : {}),
      });
    }
  };
};

// need to pass item
// if item.id exist call API otherwise remove from reducer only based on financial_year & form_index
export const deleteTaxReturnsFormValidation = (item, documentId) => {
  return async dispatch => {
    dispatch({ type: Types.DELETE_TAX_RETURNS_FILE_VALIDATION__INPROGRESS });
    try {
      let resp = {};
      if (item.id) {
        resp = await deleteTaxReturnsFormValidationApi(item.id);
      }

      const status = get(resp, 'status') || !!item.tempId;
      const data = get(resp, 'data', {});
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});
      const storeData = store.getState();
      const taxValidationForm = get(
        storeData,
        'taxReturns.filledForms.data',
        {}
      );

      const isMatch = form =>
        item.tempId
          ? form.tempId === item.tempId
          : item.id
          ? form.id === item.id
          : form.form_index === item.form_index &&
            form.financial_year_id === item.financial_year_id &&
            form.document_form_id === item.document_form_id;

      const deletedForm = taxValidationForm.find(form => {
        return isMatch(form);
      });

      let needUpdateForm;
      const submittedData = (taxValidationForm || [])
        .filter(form => !isMatch(form))
        .map(form => {
          if (
            form.financial_year_id === deletedForm.financial_year_id &&
            form.document_form_id === deletedForm.document_form_id
          ) {
            form.form_index = 1;
            needUpdateForm = form;
          }
          return form;
        });

      if (status) {
        dispatch({
          type: Types.FETCH_FILLED_FORM_SUCCESS,
          data: submittedData,
        });
        dispatch({
          type: Types.DELETE_TAX_RETURNS_FILE_VALIDATION_SUCCESS,
        });
      }

      if (needUpdateForm) {
        dispatch(
          postTaxReturnsFormValidation(
            {
              ...needUpdateForm,
              /* is_draft: true, */ document_id: documentId,
            },
            true,
            true
          )
        );
      }
      return { data, total, meta };
    } catch (error) {
      dispatch({
        type: Types.DELETE_TAX_RETURNS_FILE_VALIDATION_FAILURE,
        message: error,
      });
    }
  };
};

export const setCurrentFile = file => {
  return dispatch => {
    dispatch({
      type: Types.SET_CURRENT_FILE,
      data: file,
    });
  };
};

export const getTaxReturnStatus = (documentId, cb) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TAX_RETURNS_STATUS_INPROGRESS });
    try {
      const resp = await getTaxReturnStatusApi(documentId);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        if (cb) {
          cb(data);
        }
        dispatch({
          type: Types.FETCH_TAX_RETURNS_STATUS_SUCCESS,
          data: { data, meta, total },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TAX_RETURNS_STATUS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TAX_RETURNS_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const postTaxReturnStatus = (documentId, payload, cb) => {
  return async dispatch => {
    dispatch({ type: Types.POST_TAX_RETURNS_STATUS_INPROGRESS });
    try {
      const resp = await postTaxReturnStatusApi(documentId, payload);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        if (cb) {
          cb(data);
        }
        dispatch({
          type: Types.POST_TAX_RETURNS_STATUS_SUCCESS,
          data: { data, meta, total },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.POST_TAX_RETURNS_STATUS_FAILURE,
          message,
        });
      }

      return { data, total, meta };
    } catch (error) {
      dispatch({
        type: Types.POST_TAX_RETURNS_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const getFormTypes = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_FORM_TYPES_INPROGRESS });
    try {
      const resp = await getFormTypesApi();
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      if (status) {
        dispatch({
          type: Types.FETCH_FORM_TYPES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_FORM_TYPES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_FORM_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const getFilledForm = documentId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_FILLED_FORM_INPROGRESS });
    try {
      const resp = await getFilledFormApi(documentId);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);

      if (status) {
        dispatch({
          type: Types.FETCH_FILLED_FORM_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_FILLED_FORM_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_FILLED_FORM_FAILURE,
        message: error,
      });
    }
  };
};
