import { FormikInput } from 'components/FormFields/Input';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import cx from 'classnames';
import { ReactComponent as InfoIcon } from 'assets/img/icons/info-circle-dark-icon.svg';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import classes from './InvitePartnerTeamForm.module.scss';
import FormModal from 'components/FormFields/FormModal';
import { Formik } from 'formik';
import { phoneNumberRegex } from 'helpers/constants';
import RoleInfoTooltip from 'views/pages/PartnerTeam/RoleInfoTooltip';
import { getIcon } from 'views/pages/PartnerTeam/constants';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import { get } from 'lodash';

const selectOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const InvitePartnerTeamForm = ({
  isModalOpen,
  closeModal,
  currentRole,
  totalRoles,
  handleSetInvitationValue,
  invitationValues,
}) => {
  const auth = useSelector(({ auth }) => auth);
  const partnerRevenueShare = get(auth, 'user.see_earning', '');
  let initialValues = {
    role: currentRole,
    first_name: '',
    last_name: '',
    email_address: '',
    phone: '',
    title: '',
    supervisor: null,
    see_earning: false,
    personal_note: '',
    show_macro_onboarding: false,
    ...invitationValues,
  };

  const TeamMemberSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email_address: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    phone: Yup.string()
      .matches(phoneNumberRegex, 'Must be a valid number')
      .typeError('Phone is required')
      .required('Phone is required'),
    title: Yup.string(),
    supervisor: Yup.object().nullable(),
    see_earning: Yup.boolean().required('Must select earnings option'),
    personal_note: Yup.string(),
  });

  const handleCloseModal = () => {
    closeModal();
  };

  const RenderForm = ({
    handleSubmit,
    isValid,
    values,
    errors,
    setFieldValue,
  }) => {
    return (
      <FormModal
        toggle={handleCloseModal}
        isOpen={isModalOpen}
        size="md"
        id="createClientModal"
        heading={'Invite Team Member'}
        className={classes.formModal}
        headerClassName={classes.header}
        footerClassName="pt-4"
        bodyClassName={classes.body}
        scrollable={true}
        submit={{
          onClick: handleSubmit,
          //   loading: loading,
          buttonVariant: 'primary',
          isValid: isValid,
          buttonText: `Next`,
        }}
      >
        <Form className={classes.form} role="form" onSubmit={handleSubmit}>
          <FormGroup className={classes.userRoles}>
            <h3>User Role: </h3>
            <div className={classes.roles}>
              {totalRoles.map(
                (
                  { name, className, description, abilities, shortName },
                  index
                ) => {
                  return (
                    <div
                      onClick={() => {
                        setFieldValue('role', name);
                      }}
                      className={cx(classes.role, classes[className], {
                        [classes.active]: name === values.role,
                      })}
                      key={index}
                    >
                      <div className={classes.icon}>{getIcon(name)}</div>
                      <div className={classes.content}>
                        <div className={classes.details}>
                          <h3>{className}</h3>
                          <p>{description}</p>
                        </div>
                        <div className={classes.tooltip}>
                          <InfoIcon id={`member-${name}ToolTip`} />
                          <RoleInfoTooltip
                            role={name}
                            className={className}
                            shortName={shortName}
                            abilities={abilities}
                            target={`member-${name}ToolTip`}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </FormGroup>
          <div className={classes.userInfo}>
            <h3>User Information: </h3>
            <Row>
              <Col xs={12} sm={6}>
                <h5 className={classes.required}>First Name</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="first_name"
                  placeholder="Jane"
                  type="text"
                />
              </Col>
              <Col xs={12} sm={6}>
                <h5 className={classes.required}>Last Name</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="last_name"
                  placeholder="Doe"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <h5 className={classes.required}>Email</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="email_address"
                  placeholder="email@example.com"
                  type="text"
                />
              </Col>
              <Col xs={12} sm={6}>
                <h5 className={classes.required}>Phone Number</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="phone"
                  placeholder="209-546-4321"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Their Title at your company (optional)</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="title"
                  placeholder="ex: Senior Accountant"
                  type="text"
                />
              </Col>
            </Row>
          </div>
          {values.role === 'Partner_Representative' && (
            <div className={classes.supervisor}>
              <h2>Assign a Supervisor </h2>
              <h3>
                This representative will be assigned to the Supervisor you
                select below:
              </h3>
              <Row>
                <Col>
                  <RSelectAsync
                    url={'/users/list/dropdown?role=manager'}
                    className={cx('mb-4', classes.supervisorSelect)}
                    name={'supervisor'}
                    value={values.supervisor}
                    getOptionLabel={option => option.name}
                    placeholder={'Select'}
                    onChange={selectedOption => {
                      setFieldValue('supervisor', selectedOption);
                    }}
                    isClearable={true}
                    styles={{
                      width: '300px',
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
          {partnerRevenueShare == 1 &&
            values.role === 'Partner_Representative' && (
              <div className={classes.allowEarningView}>
                <h2 className={classes.required}>
                  Allow them to see earnings from their referrals?
                </h2>
                <h3>
                  Enables Reps to view the earnings they generate for the
                  company from their referrals.
                </h3>
                <div className={classes.typeWrapper}>
                  {selectOptions.map(type => {
                    return (
                      <span
                        key={`${type.label}-type`}
                        className={cx(
                          classes.type,
                          values.see_earning === type.value
                            ? classes.active
                            : ''
                        )}
                        onClick={() => {
                          setFieldValue('see_earning', type.value);
                        }}
                      >
                        {type?.label}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          <div className={classes.note}>
            <h2>Add a personal note to the invitation email (optional)</h2>
            <FormikInput
              name="personal_note"
              placeholder="Add notes here (optional)"
              type="textarea"
              rows={3}
            />
          </div>
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={TeamMemberSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        // submitValues(values);
        handleSetInvitationValue(values);
      }}
    >
      {RenderForm}
    </Formik>
  );
};

export default InvitePartnerTeamForm;
