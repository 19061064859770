import React from 'react';
import classes from './TaxReturnsOverview.module.scss';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
// import Summary from './components/Summary';
import DocumentSection from './components/DocumentSection';
import DocumentDetails from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentDetails';
import cx from 'classnames';
import { useParams, useHistory } from 'react-router';
import get from 'lodash/get';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { useSelector } from 'react-redux';
import { ReactComponent as BackIcon } from 'assets/img/icons/chevron-left-icon.svg';

const TaxReturnsOverview = () => {
  const user = useSelector(({ auth }) => get(auth, 'user'));
  const assigned_initiatives = get(user, 'assigned_initiatives', []);
  const params = useParams();
  const id = get(params, 'id');
  const documentBucketId = get(params, 'documentBucketId');
  const modalParams = {
    parent_type: 'FinancialYearDocumentType',
    parent_id: documentBucketId,
  };
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const name = get(currentStudy, 'name', '');

  const isStudyAssigned = assigned_initiatives.includes(currentStudy?.id);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <BackIcon
            className={classes.leftIcon}
            onClick={() => history.goBack()}
          />
          <div className={classes.studyName}>Overview</div>
          <Breadcrumb
            className={cx('d-none d-md-inline-block md-4', classes.breadcrumb)}
            listClassName="breadcrumb-links"
          >
            <BreadcrumbItem>
              <div style={{ display: 'flex' }}>
                <span className={classes.dashIcon}>&nbsp; - &nbsp;</span>
                <div className={classes.rightStudyName}>
                  {name ? name : '-'} - Overview
                </div>
              </div>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      {/* <Summary /> */}
      <Row className={classes.chatWrapper}>
        <Col xs="8">
          <DocumentSection
            initiativeId={id}
            documentBucketId={documentBucketId}
            isStudyAssigned={isStudyAssigned}
          />
        </Col>
        <Col xs="4">
          <Card className={classes.chatCard}>
            <CardHeader className={classes.cardHeader}>
              <h3 className="mb-0">Chat</h3>
            </CardHeader>
            <DocumentDetails
              initiativeId={id}
              params={modalParams}
              parentId={modalParams.parent_id}
              userType={'admin'}
              comment={''}
              onTabChange={() => {}}
              analyticsSendEvent={analyticsSendEvent}
              isStudyAssigned={isStudyAssigned}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TaxReturnsOverview;
