import React, { useState, useEffect } from 'react';
import classes from './DocumentProcessor.module.scss';
import cx from 'classnames';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import queryString from 'query-string';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import NoActivityImg from 'assets/img/icons/folder-icon.svg';
import BaseTable from 'components/Table';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { AlertPopupHandler } from 'components/AlertPopup';
import DocumentViewer from './DocumentViewer';
import {
  deleteDocument,
  fetchDocuments,
  retryUpload,
} from 'store/actions/utility';
import { ocrActivity } from 'api/utility';
import history from 'helpers/history';
import { withTime } from 'helpers/times';

const DocumentProcessor = props => {
  const queryProps = queryString.parse(props.location.search); //batchId
  const dispatch = useDispatch();

  const documents = useSelector(({ utility }) => utility.documents);
  const retryUploadReducer = useSelector(({ utility }) => utility.retryUpload);

  const batchId = get(queryProps, 'batchId');
  const documentsData = get(documents, 'data.data', []);
  const userMetadata = get(documents, 'data.meta', {});

  const isInProgress = get(documents, 'isInProgress', false);
  const retryIsInProgress = get(retryUploadReducer, 'isInProgress', false);

  const [sortBy, setSortBy] = useState({
    dataField: 'created_at',
    order: 'desc',
  });
  const [page, setPage] = useState(1);
  const [retryId, setRetryId] = useState(null);
  const [sizePerPage, setSizePerPage] = useState(10);
  const sort =
    sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;

  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false);
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const [currentBatchId, setCurrentBatchId] = useState(null);

  const handleOpenDocumentUploadModal = () => setOpenDocumentUploadModal(true);
  const handleCloseDocumentUploadModal = () =>
    setOpenDocumentUploadModal(false);

  const handleFileViewer = () => {
    setFileViewerOpen(false);
    setCurrentBatchId(null);
    history.push('?');
  };

  const handleOpenFileViewer = async id => {
    // if (resp?.status) {
    setFileViewerOpen(true);
    setCurrentBatchId(id);
    // }
  };

  useEffect(() => {
    if (batchId) {
      handleOpenFileViewer(batchId);
    }
  }, [batchId]);

  useEffect(() => {
    ocrActivity(null, {
      activity: 'PageViewed',
    });
  }, []);

  useEffect(() => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    dispatch(fetchDocuments(page, sort, ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;

    await dispatch(deleteDocument(data.id, { page, sort, q: '' }));
  };

  const handleRetry = async id => {
    setRetryId(id);
    dispatch(retryUpload(id));
  };

  const deleteAction = row => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Remove batch',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const getNonExpandableRows = () => {
    let ids = [];
    documentsData.forEach((data, index) => {
      if (
        data.ocr &&
        (data?.ocr?.length === 0 || data?.status?.label === 'Processing')
      )
        ids.push(data.id);
    });
    return ids;
  };

  const formatFileSize = (size = 0) => {
    return `${(size / 1000).toFixed(2)} MB`;
  };

  const expandRow = {
    renderer: row => {
      return (
        <table width="100%" className={'table-borderless'}>
          {row.ocr.map((data, index) => (
            <tr key={index}>
              <td className={classes['first-expand-cell']}></td>
              <td className={classes['expand-name-cell']}>
                {data?.file?.name || ''}
              </td>
              <td className={classes['expand-files-cell']}></td>
              <td className={classes['expand-size-cell']}>
                {formatFileSize(data?.file?.size)}
              </td>
              <td className={classes['expand-date-cell']}></td>
              {/* <td className={classes['expand-accuracy-cell']}>
                {data?.accuracy ? `${data?.accuracy}%` : '--'}
              </td> */}
              <td className={classes['expand-status-cell']}>
                {row.status && (
                  <i
                    style={{
                      color: row?.status?.color,
                      fontSize: '6px',
                    }}
                    className="fas fa-circle mr-1"
                  />
                )}{' '}
                {row.status.label}
              </td>
              <td className={classes['expand-action-cell']}></td>
            </tr>
          ))}
        </table>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return '';
    },
    expandColumnRenderer: row => {
      const { expanded, expandable } = row;
      if (expandable) {
        if (expanded) {
          return <i className={cx('fas fa-angle-up', classes.expandIcon)}></i>;
        }
        return <i className={cx('fas fa-angle-down', classes.expandIcon)}></i>;
      }
      return '';
    },
    nonExpandable: getNonExpandableRows(),
  };

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={cx('m-auto w-100', classes.defaultImage)}
              src={NoActivityImg}
              alt="No Recent Activity"
            />
            <h3
              className={cx(
                'display-4',
                'mb-0',
                'text-center',
                'px-2',
                classes.defaultText
              )}
            >
              No New <br /> Batches
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  return (
    <>
      {isFileViewerOpen ? (
        <DocumentViewer
          isOpen={isFileViewerOpen}
          toggle={handleFileViewer}
          batchId={currentBatchId}
        />
      ) : null}
      <div className={classes.container}>
        <div className={classes.header}>
          <h3>Document Processor</h3>{' '}
          <Breadcrumb
            className={cx(
              'd-none d-md-inline-block ml-md-4',
              classes.breadcrumb
            )}
            listClassName="breadcrumb-links"
          >
            <BreadcrumbItem>
              <a href="/admin/dashboard" onClick={e => e.preventDefault()}>
                <i className="fas fa-home" />
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>Utility Tools</BreadcrumbItem>
            <BreadcrumbItem aria-current="page" className="active">
              Document Processor
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Card className={classes.card}>
          <CardBody className={cx('p-0', classes.cardBody)}>
            <BaseTable
              keyField="id"
              search={false}
              defaultSorted={[sortBy]}
              remote
              showHeaderWithNoData
              bordered={false}
              headerClassName={classes.headerStyles}
              titleWithNoData="Document Batches"
              headerButtonText={
                <div className={classes.uploadFilesButton}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  <p>Upload New Files</p>
                </div>
              }
              headerButtonClassName={classes.headerButton}
              headerButtonAction={() => {
                history.push('/admin/utility/document-processor/upload');
              }}
              loading={isInProgress}
              noDataIndication={NoDataIndication}
              paginationOptions={{
                page: page,
                totalSize: userMetadata.total,
                sizePerPage: sizePerPage,
              }}
              data={documentsData}
              classes="mb-0"
              expandRow={expandRow}
              // rowStyle={rowStyle}
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
              onTableChange={(
                type,
                { page, sizePerPage, sortOrder, sortField, searchText }
              ) => {
                if (type === 'pagination') {
                  setPage(page);
                  setSizePerPage(sizePerPage);
                } else if (type === 'sort') {
                  setPage(1);
                  setSortBy({
                    dataField: sortField,
                    order: sortOrder,
                  });
                }
                if (type === 'search') {
                  setPage(1);
                }
                const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
                dispatch(fetchDocuments(page, sort, '', sizePerPage));
              }}
              columns={[
                {
                  dataField: 'name',
                  text: 'BATCH NAME',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '250px' };
                  },
                  formatter: cell => {
                    return (
                      <>
                        <span
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName
                          )}
                        >
                          {cell}
                        </span>
                      </>
                    );
                  },
                },
                {
                  dataField: 'ocrs_count',
                  text: 'FILES',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '100px' };
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <span
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName
                          )}
                        >
                          {row?.files
                            ? `${row.files} File${row.files > 1 ? 's' : ''}`
                            : '-'}
                        </span>
                      </>
                    );
                  },
                },
                {
                  dataField: 'size',
                  text: 'SIZE',
                  sort: false,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '150px' };
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <span
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName
                          )}
                        >
                          {formatFileSize(cell)}
                        </span>
                      </>
                    );
                  },
                },
                {
                  dataField: 'created_at',
                  text: 'DATE',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '100px' };
                  },
                  formatter: (cell, row) => (
                    <div
                      className={cx('d-flex align-items-center text-nowrap')}
                      // onClick={() => handleEditMember(row)}
                    >
                      {withTime(cell)}
                    </div>
                  ),
                },
                // {
                //   dataField: 'accuracy',
                //   text: 'ACCURACY',
                //   headerStyle: () => {
                //     return { minWidth: '100px' };
                //   },
                //   formatter: (cell, row) => (
                //     <div
                //       className={cx('d-flex align-items-center text-nowrap')}
                //       // onClick={() => handleEditMember(row)}
                //     >
                //       {cell || '--'}%
                //     </div>
                //   ),
                // },
                {
                  dataField: 'created_by',
                  text: 'CREATED BY',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '100px' };
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <span
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName
                          )}
                        >
                          {/* <Avatar
                            // url={process.env.REACT_APP_API_URL + '/' + row?.created_by_avatar}

                            // DIVYANK: this work onthe dev env and the right path according to Long
                            // and the logged in user avatar, if on your env the above works, it is better

                            // for now, all canceled

                            url={process.env.REACT_APP_API_URL + '/storage/' + row?.created_by_avatar?.replace('/thumbnails','')}
                            className={cx('avatar-xs mr-2')}
                          /> */}
                          {row?.created_by}
                        </span>
                      </>
                    );
                  },
                },
                {
                  dataField: 'type',
                  text: 'TYPE',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '100px' };
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <span
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.hoverHand,
                            classes.storyName
                          )}
                        >
                          {row?.type +
                            (row?.type == 'w2'
                              ? ' (' + row?.recordsPerPage + ')'
                              : '')}
                        </span>
                      </>
                    );
                  },
                },
                {
                  dataField: 'ocr_status',
                  text: 'STATUS',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: () => {
                    return { minWidth: '130px' };
                  },
                  formatter: (cell, row) => {
                    const color = {
                      Processed: '#2DCE89',
                      Processing: '#11CDEF',
                      Failed: '#F5365C',
                      Uploading: '#FB6340',
                    };
                    return (
                      <div
                        className={cx('d-flex align-items-center text-nowrap')}
                        // onClick={() => handleEditMember(row)}
                      >
                        {row.status && (
                          <i
                            style={{
                              color: row.status.color,
                              fontSize: '6px',
                            }}
                            className="fas fa-circle mr-1"
                          />
                        )}{' '}
                        {row.status.label}
                      </div>
                    );
                  },
                },
                {
                  dataField: '',
                  text: 'ACTIONS',
                  classes: cx('d-flex justify-content-end'),
                  headerStyle: () => {
                    return { minWidth: '218px', textAlign: 'right' };
                  },
                  formatter: (cell, row) => {
                    const status = get(row, 'status.label');

                    const buttons = {
                      Processed: (
                        <Button
                          className={cx(classes.view)}
                          onClick={() => {
                            history.push(
                              `/admin/utility/document-processor?batchId=${row.id}`
                            );
                          }}
                          leftIcon={
                            <i className="fas fa-cloud-download-alt"></i>
                          }
                        >
                          View
                        </Button>
                      ),
                      Failed: (
                        <Button
                          loading={retryIsInProgress && row.id === retryId}
                          onClick={() => handleRetry(row.id)}
                          className={cx(classes.retry)}
                          leftIcon={<i className="fas fa-redo-alt"></i>}
                        >
                          Retry
                        </Button>
                      ),
                      Uploading: <></>,
                      Processing: <></>,
                    };

                    return (
                      <div
                        className={cx(
                          'd-flex gap-3 align-items-center text-nowrap',
                          classes.actionButtonWrapper
                        )}
                      >
                        {buttons[status] ?? <></>}
                        <Button
                          leftIcon={
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          }
                          className={cx(classes.delete, 'btn-outline-warning')}
                          outlined
                          onClick={() => deleteAction(row)}
                        >
                          Delete
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default DocumentProcessor;
