import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classes from './TeamView.module.scss';
import classNames from 'classnames';
import BaseTable from 'components/Table';
import { fetchUsersList } from 'store/actions/users';
import { roles, statuses } from 'views/pages/PartnerTeam/constants';
const TeamView = ({
  handleEditMember,
  pageList,
  renderSortCaret,
  NoDataIndication,
  searchText,
  selectedRole,
  selectedStatus,
}) => {
  const dispatch = useDispatch();
  const viewType = 'team';
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  useEffect(() => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    dispatch(
      fetchUsersList(
        page,
        sort,
        searchText,
        sizePerPage,
        null,
        selectedRole?.name,
        selectedStatus?.name,
        viewType
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, selectedRole, selectedStatus]);
  const { userState } = useSelector(({ user }) => user);
  const usersData = get(userState, 'data.data', []);
  const userMetadata = get(userState, 'data.meta', {});

  const isInProgress = get(userState, 'isInProgress', false);
  const getNonExpandableRows = () => {
    let ids = [];
    usersData.forEach(user => {
      if (!user.team || user.team.length == 0) ids.push(user.id);
    });
    return ids;
  };
  const expandRow = {
    renderer: row => {
      return (
        <table width="100%" className="table-borderless">
          {row.team.map(team => (
            <tr key={team.id}>
              <td className="first-expand-cell"></td>
              <td className="expand-name-cell">{team.name}</td>
              <td className="expand-role-cell">
                {team.roles && team.roles.length > 0
                  ? roles.find(u => u.name === row.roles[0].name)?.displayName
                  : ''}
              </td>
              <td className="expand-status-cell">
                {team.status
                  ? statuses.find(s => s.name == team.status.status)
                      ?.displayName
                  : ''}
              </td>
              <td width="50px">
                <div
                  className={classNames('d-flex align-items-center')}
                  onClick={() => handleEditMember(team)}
                >
                  <i className="fas text-primary fa-pencil-alt mx-4" />
                </div>
              </td>
            </tr>
          ))}
        </table>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return '';
    },
    expandColumnRenderer: row => {
      const { expanded, expandable } = row;
      if (expandable) {
        if (expanded) {
          return <i className="fas fa-angle-up"></i>;
        }
        return <i className="fas fa-angle-down"></i>;
      }
      return '';
    },
    nonExpandable: getNonExpandableRows(),
  };
  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total float-left mt-3">
      Showing {from} to {to} of {size} Teams
    </span>
  );
  const sizePerPageRenderer = ({ options, onSizePerPageChange }) => (
    <div className="float-left size-per-page mr-5 mt-3">
      Show
      <select
        className="mx-2 size-per-page-select"
        onChange={event => {
          onSizePerPageChange(event.target.value);
        }}
      >
        {options.map(p => (
          <option key={p.text} value={p.page}>
            {p.text}
          </option>
        ))}
      </select>
      teams
    </div>
  );
  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (
      row.roles &&
      row.roles.length > 0 &&
      row.roles[0].name === 'Partner_Administrator'
    ) {
      style.backgroundColor = '#F8FBFC';
    }
    return style;
  };
  return (
    <div className={classes['team-view']}>
      <BaseTable
        keyField="id"
        search={false}
        defaultSorted={[sortBy]}
        remote
        bordered={false}
        loading={isInProgress}
        noDataIndication={NoDataIndication}
        paginationOptions={{
          page: page,
          totalSize: userMetadata.total,
          sizePerPage: sizePerPage,
          hideSizePerPage: false,
          showTotal: true,
          paginationTotalRenderer,
          sizePerPageList: pageList,
          sizePerPageRenderer,
        }}
        data={usersData}
        classes="mb-0"
        expandRow={expandRow}
        rowStyle={rowStyle}
        wrapperClasses={classNames(classes.tableWrapper, 'table-responsive')}
        onTableChange={(
          type,
          { page, sizePerPage, sortOrder, sortField, searchText }
        ) => {
          if (type === 'pagination') {
            setPage(page);
            setSizePerPage(sizePerPage);
          } else if (type === 'sort') {
            setPage(1);
            setSortBy({
              dataField: sortField,
              order: sortOrder,
            });
          }
          if (type === 'search') {
            setPage(1);
          }
          const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
          dispatch(
            fetchUsersList(
              page,
              sort,
              searchText,
              sizePerPage,
              null,
              selectedRole?.name,
              selectedStatus?.name,
              viewType
            )
          );
        }}
        columns={[
          {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: renderSortCaret,
            headerStyle: () => {
              return { width: '50%' };
            },
            formatter: cell => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {cell}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'roles',
            text: 'Role',
            sort: false,
            sortCaret: renderSortCaret,
            headerStyle: () => {
              return { width: '25%' };
            },
            formatter: (cell, row) => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {row.roles && row.roles.length > 0
                      ? roles.find(u => u.name === row.roles[0].name)
                          ?.displayName
                      : ''}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: renderSortCaret,
            headerStyle: () => {
              return { width: '25%' };
            },
            formatter: (cell, row) => {
              return (
                <>
                  <span
                    className={classNames(
                      'm-0',
                      classes.ellipsis,
                      classes.hoverHand,
                      classes.storyName
                    )}
                  >
                    {row.status
                      ? statuses.find(s => s.name == row.status.status)
                          ?.displayName
                      : ''}
                  </span>
                </>
              );
            },
          },
          {
            dataField: 'actions',
            text: 'Actions',
            classes: classNames('d-flex'),
            headerStyle: () => {
              return { width: '50px' };
            },
            formatter: (cell, row) => (
              <div
                className={classNames('d-flex align-items-center')}
                onClick={() => handleEditMember(row)}
              >
                <i className="fas text-primary fa-pencil-alt mx-4" />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TeamView;
