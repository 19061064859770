import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Input as BaseInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classes from './Input.module.scss';
import CheckBox from 'components/FormFields/CheckBox';
import { LineLoader } from 'components/ContentLoader';

import pick from 'lodash/pick';

const Input = props => {
  const {
    className,
    outlined,
    loading,
    leftIcon,
    rightIcon,
    disabled,
    focused,
    touched,
    error,
    label,
    id,
    showContentLoader,
    alternative,
    inputId,
    labelClassName,
    ...rest
  } = props;

  if (props.type === 'checkbox') {
    const checkboxProps = pick(props, [
      'className',
      'touched',
      'error',
      'label',
      'id',
    ]);
    return <CheckBox {...checkboxProps} {...rest} />;
  }

  const renderIcon = (addonType, icon) => {
    if (!icon) return '';
    return (
      <>
        <InputGroupAddon addonType={addonType}>
          <InputGroupText
            className={classnames({ 'border-0': !outlined && !alternative })}
          >
            {icon}
          </InputGroupText>
        </InputGroupAddon>
      </>
    );
  };

  const renderLabel = (label, id = '') => {
    if (!label) return '';

    return (
      <label
        className={classnames('form-control-label', labelClassName)}
        htmlFor={id}
      >
        {label}
        {props?.required && <span className={classes.required}>*</span>}
      </label>
    );
  };

  if (showContentLoader) {
    // if show content loader then just render content loader inplace of TextInput
    return <LineLoader numner={1} height={46} />;
  }

  if (!leftIcon && !rightIcon) {
    return (
      <>
        {renderLabel(label, id)}
        <BaseInput
          {...(inputId ? { id: inputId } : {})}
          className={classnames(className, {
            [classes['is-invalid']]: touched && error,
            'border-0': !outlined,
          })}
          disabled={disabled || loading}
          autoComplete="off"
          {...rest}
        />
        {touched && error && (
          <div className={classes['invalid-feedback']}>{error}</div>
        )}
      </>
    );
  }

  return (
    <>
      {renderLabel(label, id)}
      <InputGroup
        className={classnames('input-group-merge', {
          'input-group-alternative': alternative === true,
          focused: focused,
          [className]: className,
          [classes['is-invalid']]: touched && error,
        })}
      >
        {renderIcon('prepend', leftIcon, touched, error)}
        <Input
          inputId={inputId}
          disabled={disabled || loading}
          error={null}
          outlined={outlined}
          {...rest}
        />
        {renderIcon('append', rightIcon, touched, error)}
      </InputGroup>
      {touched && error && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </>
  );
};

Input.prototype = {
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  loading: PropTypes.bool,
  focused: PropTypes.bool,
  touched: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  showContentLoader: PropTypes.bool,
  inputId: PropTypes.string,
  alternative: PropTypes.bool,
  // reactstrap button props,
};

Input.defaultProps = {
  alternative: true,
  label: null,
  id: null,
  focused: false,
  touched: false,
  rightIcon: null,
  leftIcon: null,
  loading: false,
  className: '',
  showContentLoader: false,
  inputId: '',
  outlined: true,
  labelClassName: '',
};

export default Input;
