import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_TAX_RETURNS_SUMMARY_INPROGRESS',
  'FETCH_TAX_RETURNS_SUMMARY_SUCCESS',
  'FETCH_TAX_RETURNS_SUMMARY_FAILURE',
  'POST_TAX_RETURNS_FILE_VALIDATION_INPROGRESS',
  'POST_TAX_RETURNS_FILE_VALIDATION_SUCCESS',
  'POST_TAX_RETURNS_FILE_VALIDATION_FAILURE',
  'DELETE_TAX_RETURNS_FILE_VALIDATION__INPROGRESS',
  'DELETE_TAX_RETURNS_FILE_VALIDATION_SUCCESS',
  'DELETE_TAX_RETURNS_FILE_VALIDATION_FAILURE',
  'FETCH_FILE_VALIDATION_FORM_INPROGRESS',
  'FETCH_FILE_VALIDATION_FORM_SUCCESS',
  'FETCH_FILE_VALIDATION_FORM_FAILURE',
  'DELETE_TAX_RETURNS_SUMMARY_INPROGRESS',
  'DELETE_TAX_RETURNS_SUMMARY_SUCCESS',
  'DELETE_TAX_RETURNS_SUMMARY_FAILURE',
  'SET_CURRENT_FILE',
  'FETCH_TAX_RETURNS_STATUS_INPROGRESS',
  'FETCH_TAX_RETURNS_STATUS_SUCCESS',
  'FETCH_TAX_RETURNS_STATUS_FAILURE',
  'POST_TAX_RETURNS_STATUS_INPROGRESS',
  'POST_TAX_RETURNS_STATUS_SUCCESS',
  'POST_TAX_RETURNS_STATUS_FAILURE',
  'FETCH_FORM_TYPES_INPROGRESS',
  'FETCH_FORM_TYPES_SUCCESS',
  'FETCH_FORM_TYPES_FAILURE',
  'FETCH_FILLED_FORM_INPROGRESS',
  'FETCH_FILLED_FORM_SUCCESS',
  'FETCH_FILLED_FORM_FAILURE'
);
