import React from 'react';
import classes from './CircularProgress.module.scss';
const CircularProgress = ({ percentage }) => {
  const circumference = 439.6;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <div className={classes.circularProgressContainer}>
      <svg width="50" height="50" viewBox="0 0 160 160">
        <circle
          r="70"
          cx="80"
          cy="80"
          fill="transparent"
          stroke="#D2E3EE"
          strokeWidth="12px"
        ></circle>
        <circle
          r="70"
          cx="80"
          cy="80"
          fill="transparent"
          stroke="#24A46D"
          strokeWidth="12px"
          strokeDasharray={`${circumference}px`}
          strokeDashoffset={`${offset}px`}
          // style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
          className={classes.progressCircle}
        ></circle>
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy="0.3em"
          fontSize="44px"
          fill="#000000"
        >
          {percentage ? percentage : 0}%
        </text>
      </svg>
    </div>
  );
};

export default CircularProgress;
