import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Card, CardHeader } from 'reactstrap';
import { ReactComponent as AddIcon } from 'assets/img/icons/add-icon.svg';
import cx from 'classnames';
import {
  downloadDocument,
  fetchDocumentStudyTypes,
  uploadStudyDocument,
} from 'store/actions/documents';
import { useDispatch, useSelector } from 'react-redux';
import classes from './DocumentSection.module.scss';
import BaseTable from 'components/Table';
import Button from 'components/Button';
import Modal from 'components/FormFields/Modal';
import cloneDeep from 'lodash/cloneDeep';
import DocumentUpload from 'views/pages/ClientStudyDashboard/DocumentUploadModal/DocumentUpload';
import history from 'helpers/history';
import { setCurrentFile } from 'store/actions/taxReturns';
import EditModal from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentYearStatus/EditModal/EditModal';
import { get } from 'lodash';

const DocumentSection = ({
  initiativeId,
  documentBucketId,
  isStudyAssigned,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { years, financial_year_document_id, name } = files;

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );

  const NoDataIndication = () => {
    return (
      <div className={classes.noDataIndication}>
        {!documentLoading && <p>There are no files uploaded. </p>}
      </div>
    );
  };

  useEffect(() => {
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiativeId]);

  const onDrop = acceptedFiles => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const handleRemoveFile = index => {
    const fileClone = cloneDeep(files);
    fileClone.splice(index, 1);
    setUploadedFiles(fileClone);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    formData.append('initiative_id', initiativeId);
    formData.append('document_type_id', files.id);
    const response = await dispatch(uploadStudyDocument(formData));

    if (response?.status) {
      getDocs();
      setShowUploadModal(false);
    }
  };

  const getDocs = async () => {
    setDocumentLoading(true);
    const { data = [] } = await dispatch(fetchDocumentStudyTypes(initiativeId));
    const getDocData = handleGetDocumentData(data);

    setFiles(getDocData);
    setDocumentLoading(false);
  };

  const handleGetDocumentData = documents => {
    const getDocData = documents?.find(
      doc => doc?.financial_year_document_id == documentBucketId
    );

    return getDocData || [];
  };

  const rowEvents = {
    onClick: (e, row) => {
      dispatch(setCurrentFile(row));
      history.push(
        `/admin/studies/${initiativeId}/document-type/${documentBucketId}/${row.id}`
      );
    },
  };

  const getYearLabel = value => {
    switch (value) {
      case 'Partial':
        return 'In Progress';
      case 'None':
        return 'Not Started';
      default:
        return value;
    }
  };

  const renderStatus = status => {
    switch (status) {
      case 'pending':
        return 'Not Processed';
      case 'completed':
        return 'Processed';
      default:
        return 'In Progress';
    }
  };

  const toggle = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Card>
      <CardHeader className={classes.cardHeader}>
        <div className={classes.yearsWrapper}>
          <h3 className="mb-0">Files</h3>
          <div className={classes.years}>
            {(years || [])?.map(data => {
              return (
                <div key={data.id} title={getYearLabel(data.status.label)}>
                  <Badge
                    className={cx(classes.badge, classes.rounded, {
                      [classes.success]: data.status.label === 'Complete',
                      [classes.info]: data.status.label === 'Partial',
                      [classes.notStarted]: data.status.label === 'None',
                    })}
                  >
                    {data.year}
                  </Badge>
                </div>
              );
            })}
            {!documentLoading && !!(years || []).length && (
              <div className={classes.editBtn} onClick={toggle}>
                Edit
              </div>
            )}
          </div>
        </div>
        <div>
          <Button
            disabled={!isStudyAssigned}
            className={classes.uploadDocButton}
            type="button"
            leftIcon={<AddIcon />}
            onClick={() => setShowUploadModal(true)}
          >
            Upload Files
          </Button>
        </div>
      </CardHeader>
      {showUploadModal ? (
        <Modal
          size="lg"
          backdrop={['static']}
          scrollable
          fade={false}
          title={
            <>
              Add your <strong>{files.name}</strong>
            </>
          }
          toggle={() => setShowUploadModal(false)}
          isOpen={showUploadModal}
          className={classes.modalWrapperUpload}
          headerClassName={classes.modalHeaderUpload}
        >
          <DocumentUpload
            files={uploadedFiles}
            next={handleUpload}
            prev={() => setShowUploadModal(false)}
            onDrop={onDrop}
            removeFile={handleRemoveFile}
            userType={'admin'}
          />
        </Modal>
      ) : null}
      <div>
        <BaseTable
          keyField="id"
          bootstrap4
          remote
          loading={documentLoading}
          noDataIndication={NoDataIndication}
          showHeaderWithNoData
          search={false}
          bordered={false}
          paginationOptions={false}
          wrapperClasses={classes.tableHeader}
          data={files?.documents || []}
          rowEvents={rowEvents}
          columns={[
            {
              dataField: 'name',
              text: 'File Name',
              formatter: (cell, row, index) => (
                <div
                  className={cx(
                    classes.fileName,
                    'table-data  d-flex align-items-center'
                  )}
                  onClick={() => {}}
                >
                  <span id={`file-name-${row.id}`} className="p-0">
                    {cell}
                  </span>
                </div>
              ),
            },
            {
              dataField: 'created_at',
              text: 'Date',
              formatter: (cell, row, index) => {
                return (
                  <div
                    className={cx(
                      classes.tData,
                      'table-data d-flex align-items-center'
                    )}
                  >
                    <span className={classes.cursor}>
                      {moment(cell).format('MM/DD/YYYY')}
                    </span>
                  </div>
                );
              },
            },
            {
              dataField: 'financial_year_form',
              text: 'Year(s)',
              formatter: (cell, row, index) => {
                return (
                  <div
                    className={cx(
                      classes.tData,
                      classes.badgeWrapper,
                      'table-data d-flex align-items-center gap-4 flex-wrap'
                    )}
                  >
                    {cell?.length > 0 ? (
                      cell?.map((year, index) => (
                        <Badge
                          key={index}
                          className={cx(classes.badge, {
                            [classes.success]: year.status === 'complete',
                            [classes.info]: year.status === 'in_progress',
                          })}
                        >
                          {year.financial_year}
                        </Badge>
                      ))
                    ) : (
                      <Badge className={cx(classes.badge)}>No Data</Badge>
                    )}
                  </div>
                );
              },
            },
            {
              dataField: 'financial_year_form_status',
              text: 'Status',
              formatter: (cell, row, index) => {
                return (
                  <div
                    className={cx(
                      classes.tData,
                      'table-data d-flex justify-content-between align-items-center gx-8'
                    )}
                  >
                    <span
                      className={cx(classes.cursor, classes.status, {
                        [classes.danger]: cell == 'pending',
                        [classes.info]: cell == 'In Progress',
                        [classes.success]: cell == 'completed',
                      })}
                    >
                      {renderStatus(cell)}
                    </span>
                    <span>
                      <i
                        className={cx('fa fa-chevron-right', classes.cursor)}
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
      {isOpen && (
        <EditModal
          isOpen={isOpen}
          toggle={toggle}
          years={years.map(year => year.year)}
          parentId={financial_year_document_id}
          getDocs={getDocs}
          docType={name}
          studyName={currentStudy.name}
        />
      )}
    </Card>
  );
};

export default DocumentSection;
