import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_CLIENT_PROFILE_INPROGRESS',
  'FETCH_CLIENT_PROFILE_SUCCESS',
  'FETCH_CLIENT_PROFILE_FAILURE',
  'FETCH_AGREEMENTS_INPROGRESS',
  'FETCH_AGREEMENTS_SUCCESS',
  'FETCH_AGREEMENTS_FAILURE',
  'CREATE_AGREEMENT_INPROGRESS',
  'CREATE_AGREEMENT_SUCCESS',
  'CREATE_AGREEMENT_FAILURE',
  'EDIT_AGREEMENT_INPROGRESS',
  'EDIT_AGREEMENT_SUCCESS',
  'EDIT_AGREEMENT_FAILURE',
  'CLEAR_EDIT_AGREEMENT',
  'UPDATE_AGREEMENT_INPROGRESS',
  'UPDATE_AGREEMENT_SUCCESS',
  'UPDATE_AGREEMENT_FAILURE',
  'DELETE_AGREEMENT_INPROGRESS',
  'DELETE_AGREEMENT_SUCCESS',
  'DELETE_AGREEMENT_FAILURE',
  'FETCH_AGREEMENT_DATA_INPROGRESS',
  'FETCH_AGREEMENT_DATA_SUCCESS',
  'FETCH_AGREEMENT_DATA_FAILURE',
  'CLEAR_AGREEMENT_DATA',
  'DOWNLOAD_FILE_INPROGRESS',
  'DOWNLOAD_FILE_SUCCESS',
  'DOWNLOAD_FILE_FAILURE',
  'FETCH_AGREEMENT_TYPES_INPROGRESS',
  'FETCH_AGREEMENT_TYPES_SUCCESS',
  'FETCH_AGREEMENT_TYPES_FAILURE',
  'FETCH_CLIENT_TIME_LOG_GRAPH_INPROGRESS',
  'FETCH_CLIENT_TIME_LOG_GRAPH_SUCCESS',
  'FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE',
  'FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_INPROGRESS',
  'FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_SUCCESS',
  'FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE',
  'FETCH_CLIENT_RECENT_TIME_ENTRIES_INPROGRESS',
  'FETCH_CLIENT_RECENT_TIME_ENTRIES_SUCCESS',
  'FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE',
  'UPDATE_CLIENT_INPROGRESS',
  'UPDATE_CLIENT_SUCCESS',
  'UPDATE_CLIENT_FAILURE',
  'FETCH_CLIENT_DELIVERABLES_INPROGRESS',
  'FETCH_CLIENT_DELIVERABLES_SUCCESS',
  'FETCH_CLIENT_DELIVERABLES_FAILURE',
  'EDIT_CLIENT_DELIVERABLES_INPROGRESS',
  'EDIT_CLIENT_DELIVERABLES_SUCCESS',
  'EDIT_CLIENT_DELIVERABLES_FAILURE',
  'DELETE_CLIENT_DELIVERABLES_INPROGRESS',
  'DELETE_CLIENT_DELIVERABLES_SUCCESS',
  'DELETE_CLIENT_DELIVERABLES_FAILURE',
  'FETCH_DELIVERABLE_INPROGRESS',
  'FETCH_DELIVERABLE_SUCCESS',
  'FETCH_DELIVERABLE_FAILURE',
  'FETCH_CLIENT_PROFILE_TASKS_INPROGRESS',
  'FETCH_CLIENT_PROFILE_TASKS_SUCCESS',
  'FETCH_CLIENT_PROFILE_TASKS_FAILURE',
  'FETCH_CLIENT_ACTIVITIES_INPROGRESS',
  'FETCH_CLIENT_ACTIVITIES_SUCCESS',
  'FETCH_CLIENT_ACTIVITIES_FAILURE',
  'RESET_ACTIVE_DELIVERABLES',
  'FETCH_CLIENT_DOCUMENTS_INPROGRESS',
  'FETCH_CLIENT_DOCUMENTS_SUCCESS',
  'FETCH_CLIENT_DOCUMENTS_FAILURE',
  'UPLOAD_CLIENT_DOCUMENT_INPROGRESS',
  'UPLOAD_CLIENT_DOCUMENT_SUCCESS',
  'UPLOAD_CLIENT_DOCUMENT_FAILURE',
  'FETCH_CLIENT_TEAM_MEMBERS_INPROGRESS',
  'FETCH_CLIENT_TEAM_MEMBERS_SUCCESS',
  'FETCH_CLIENT_TEAM_MEMBERS_FAILURE',
  'CREATE_STUDY_INPROGRESS',
  'CREATE_STUDY_SUCCESS',
  'CREATE_STUDY_FAILURE'
);
