import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classes from './DealDesk.module.scss';
import cs from 'classnames';
import get from 'lodash/get';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

import DealDeskModal from './DealDeskModal';

import {
  callGetListDealDeskRefferal,
  callGetListDealDesks,
  callGetListStatuses,
  callGetListUsers,
  getDealDeskApprovedByUsers,
} from 'store/actions/dealDeskActions';

import Loading from 'components/Loading';
import Deals from './Tabs/Deals';
import Referrals from './Tabs/Referrals';
import RecurringDeals from './Tabs/RecurringDeals';

const initialFilter = {
  statuses: '',
  search: '',
  type: '',
  size: '',
  from_date: '',
  to_date: '',
  page: 1,
};

const DealDesk = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = queryString.parse(location.search);

  const tabs = {
    deals: 'deals',
    referrals: 'referrals',
    recurring_deals: 'deals',
  };

  const tab = params?.tab || 'deals'; //referrals
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => {
    dispatch(callGetListStatuses());
    dispatch(callGetListUsers());
  }, [dispatch]);

  const callDealDeskList = () => {
    if (tab === 'deals') {
      dispatch(callGetListDealDesks(filter));
    } else if (tab === 'referrals') {
      dispatch(callGetListDealDeskRefferal(filter));
    }
  };

  useEffect(() => {
    callDealDeskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, filter]);

  useEffect(() => {
    setFilter(initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    dispatch(
      getDealDeskApprovedByUsers(tab === 'referral' ? 'referral' : 'deal')
    );
  }, [dispatch, tab]);

  const dealId = params?.deal_id || null;
  useEffect(() => {
    if (dealId) modalRef.current.open(dealId);
  }, [dealId]);

  const dealDesks = useSelector(({ dealDesk }) =>
    get(dealDesk, 'listDealDesks')
  );

  const dealDeskReferral = useSelector(({ dealDesk }) =>
    get(dealDesk, 'listDealDesksReferral')
  );

  const dealDesksRecurringDeals = useSelector(({ dealDesk }) =>
    get(dealDesk, 'listDealDesksRecurringDeals')
  );

  const modalRef = useRef(null);

  return (
    <>
      <Container fluid className={classes.wrapper}>
        {dealDesks?.loading ||
          (dealDeskReferral?.loading && (
            <Loading wrapperClass={classes.loading} />
          ))}

        <p className={classes.title}>Deal Desk</p>

        <div className={classes.contentWrapper}>
          <Nav className={classes.nav} tabs>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'deals',
                })}
                onClick={() => history.push(`${location.pathname}?tab=deals`)}
              >
                Deals
              </NavLink>
            </NavItem>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'referrals',
                })}
                onClick={() =>
                  history.push(`${location.pathname}?tab=referrals`)
                }
              >
                Referrals
              </NavLink>
            </NavItem>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'recurring_deals',
                })}
                onClick={() =>
                  history.push(`${location.pathname}?tab=recurring_deals`)
                }
              >
                Recurring Deals
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId="deals">
              <Deals
                filter={filter}
                setFilter={setFilter}
                meta={dealDesks?.meta}
                data={dealDesks?.data || []}
              />
            </TabPane>
            <TabPane tabId="referrals">
              <Referrals
                filter={filter}
                setFilter={setFilter}
                meta={dealDeskReferral?.meta}
                data={dealDeskReferral?.data || []}
              />
            </TabPane>
            <TabPane tabId="recurring_deals">
              <RecurringDeals
                meta={dealDesksRecurringDeals?.meta}
                data={dealDesksRecurringDeals?.data || []}
              />
            </TabPane>
          </TabContent>
        </div>
      </Container>

      <DealDeskModal
        ref={modalRef}
        type={tabs[params?.tab || 'deals']}
        updateDealList={callDealDeskList}
      />
    </>
  );
};

export default DealDesk;
