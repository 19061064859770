import React, { useState } from 'react';
import classes from './PartnerTeamMembers.module.scss';
import classNames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Label,
  ButtonGroup,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import Button from 'components/Button';
import TeamListView from './ListView/ListView';
import TeamTreeView from './TeamView/TeamView';
import RSelect from 'components/FormFields/RSelect';
import NoUsersImg from 'assets/img/theme/No_Users_Icon.svg';
import { roles, statuses } from 'views/pages/PartnerTeam/constants';

const PartnerTeamMembers = ({ handleEditMember, handleFilters }) => {
  const [selectedView, setSelectedView] = useState(1);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [searchText, setSearchText] = useState();

  const handleSearch = event => {
    setSearchText(event.target.value);
    handleFilters();
  };
  const pageList = [
    {
      text: '10',
      value: 10,
    },
    {
      text: '20',
      value: 20,
    },
    {
      text: '30',
      value: 30,
    },
  ];
  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };
  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center',
          classes.noTeamMemberWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.teamMemberImageWrapper}>
            <img
              className="m-auto w-100"
              src={NoUsersImg}
              alt="No Team Members"
            />
          </div>
          <h4
            className={classNames(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Team Members
          </h4>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Card className={classes['partners-team']}>
        <CardHeader
          className={classNames('pb-0', 'border-0', classes.cardHeader)}
        >
          <Form>
            <Row>
              <Col sm="4" className="mr-auto">
                <FormGroup row className="mb-0">
                  <Col
                    sm="2"
                    className={classNames(
                      'col-form-label',
                      classes.colFormLabel,
                      'pr-0'
                    )}
                  >
                    <Label for="txtSearch">Search:</Label>
                  </Col>
                  <Col sm="8" className="pl-0">
                    <Input
                      id="txtSearch"
                      placeholder="Start typing name here"
                      onChange={handleSearch}
                    ></Input>
                  </Col>
                </FormGroup>
              </Col>
              <Col sm="5">
                <FormGroup row className="mb-0 filter-col">
                  <Label for="ddlRole" sm="auto">
                    Filter by:
                  </Label>
                  <Col sm="5" className="pr-0">
                    <RSelect
                      name="ddlRole"
                      className="mb-0"
                      value={selectedRole}
                      getOptionLabel={opt => opt.displayName}
                      getOptionValue={opt => opt.name}
                      placeholder="Role"
                      options={roles}
                      onChange={selectedOption => {
                        setSelectedRole(selectedOption);
                        handleFilters();
                      }}
                    />
                  </Col>
                  <Col sm="4">
                    <RSelect
                      name="ddlStatus"
                      className="mb-0"
                      value={selectedStatus}
                      getOptionLabel={opt => opt.displayName}
                      getOptionValue={opt => opt.name}
                      placeholder="Status"
                      options={statuses}
                      onChange={selectedOption => {
                        setSelectedStatus(selectedOption);
                        handleFilters();
                      }}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <div className="mb-0 p-0">
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setSelectedView(1)}
                    active={selectedView === 1}
                  >
                    List View
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setSelectedView(2)}
                    active={selectedView === 2}
                  >
                    Team View
                  </Button>
                </ButtonGroup>
              </div>
            </Row>
          </Form>
        </CardHeader>
        <CardBody>
          {selectedView === 1 ? (
            <TeamListView
              handleEditMember={handleEditMember}
              pageList={pageList}
              renderSortCaret={renderSortCaret}
              searchText={searchText}
              NoDataIndication={NoDataIndication}
              selectedRole={selectedRole}
              selectedStatus={selectedStatus}
            />
          ) : (
            <TeamTreeView
              handleEditMember={handleEditMember}
              pageList={pageList}
              searchText={searchText}
              renderSortCaret={renderSortCaret}
              NoDataIndication={NoDataIndication}
              selectedRole={selectedRole}
              selectedStatus={selectedStatus}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PartnerTeamMembers;
