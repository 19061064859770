import React from 'react';
import Button from 'components/Button';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import classes from './ConfirmationModal.module.scss';
import cx from 'classnames';
const ConfirmationModal = ({
  isOpen,
  toggle,
  title,
  message,
  action,
  buttonGroup,
  buttonWrapperClass,
}) => {
  const handleClick = btn => {
    if (btn === 'Cancel') {
      toggle();
    } else {
      action();
    }
  };

  return (
    <Modal
      size="md"
      backdrop={['static']}
      scrollable
      fade={false}
      title={<>{title}</>}
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalBody>
        <div className={classes.bodyWrapper}>
          <div className={classes.content}>{message}</div>
          <div
            className={cx(classes.buttonContainer, {
              [buttonWrapperClass]: buttonWrapperClass,
            })}
          >
            {buttonGroup.map((item, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => handleClick(item.button)}
                  type="button"
                  className={
                    item.button === 'Cancel' ? classes.cancel : classes.confirm
                  }
                >
                  {item.button}
                </Button>
              );
            })}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
