import { ReactComponent as ManagerIcon } from 'assets/img/icons/manager-icon.svg';
import { ReactComponent as RepIcon } from 'assets/img/icons/user-icon.svg';
import { ReactComponent as AdminIcon } from 'assets/img/icons/admin-icon.svg';
import React from 'react';

export const getIcon = role => {
  switch (role) {
    case 'Partner_Team_Manager':
      return <ManagerIcon />;
    case 'Partner_Representative':
      return <RepIcon />;
    case 'Partner_Administrator':
    default:
      return <AdminIcon />;
  }
};

export const roles = [
  {
    name: 'Partner_Administrator',
    displayName: 'Admin',
  },
  {
    name: 'Partner_Team_Manager',
    displayName: 'Manager',
  },
  {
    name: 'Partner_Representative',
    displayName: 'Rep',
  },
];
export const statuses = [
  {
    name: 'Invited',
    displayName: 'Invite sent',
  },
  {
    name: 'Deactivated',
    displayName: 'Inactive',
  },
  {
    name: 'Active',
    displayName: 'Active',
  },
];
