import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router';
import TaxReturnsOverview from '../TaxReturnsOverview';
import DocumentBucket from '../DocumentBucket/DocumentBucket';
import { useSelector } from 'react-redux';

const DocumentBucketContainer = () => {
  const params = useParams();
  const documentBucketId = get(params, 'documentBucketId');

  const documents = useSelector(({ documents }) =>
    get(documents, `documentStudyTypes.data.data`, [])
  );

  const currentDocumentBucket = documents.find(
    document =>
      document.financial_year_document_id === parseInt(documentBucketId)
  );

  const renderDocumentBucket = () => {
    switch (currentDocumentBucket?.name) {
      case 'Tax Returns':
        return <TaxReturnsOverview />;
      default:
        return <DocumentBucket />;
    }
  };

  return <>{renderDocumentBucket()}</>;
};

export default DocumentBucketContainer;
