import React from 'react';
import { Card } from 'reactstrap';
import { getIcon } from '../constants';
import cx from 'classnames';
import classes from './RolePermissions.module.scss';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { get, map } from 'lodash';

const RolePermissions = ({ showActions = false, handleSelectRole }) => {
  const auth = useSelector(({ auth }) => auth);
  const userRoles = get(auth, 'user.roles', '');
  let roles = useSelector(partnerTeam => {
    const applicableRoles = get(
      partnerTeam,
      'partnerTeam.partnerTeamRoleState.data',
      []
    );
    return map(applicableRoles, role => {
      switch (role.name) {
        case 'Partner_Administrator':
          return {
            ...role,
            shortName: 'administrator',
            className: 'admin',
            inviteButtonText: 'Invite an Admin',
            abilities: {
              allowed: [
                'Invite Admins, Managers, and Reps to the team and assign user roles.',
                'Assign Supervisors to Reps.',
                'View the organization’s referrals, earnings, and performance.',
                'Deactivate team members.',
              ],
              denied: [],
            },
          };
        case 'Partner_Team_Manager':
          return {
            ...role,
            shortName: 'manager',
            className: 'manager',
            inviteButtonText: 'Invite a Manager',
            abilities: {
              allowed: [
                'Invite Reps to their team.',
                'View referrals, earnings, and performance of their team.',
                'Deactivate Reps from their team.',
              ],
              denied: [
                'View or manage other teams.',
                'Add or deactivate Admins.',
              ],
            },
          };
        case 'Partner_Representative':
          return {
            ...role,
            shortName: 'rep',
            className: 'representative',
            inviteButtonText: 'Invite a Rep',
            abilities: {
              allowed: [
                'Make and track their own referrals.',
                'See earnings they sourced* (*if authorized by an Admin)',
              ],
              denied: [
                'View or manage their teammates.',
                'Add or deactivate users',
              ],
            },
          };
        default:
          return role;
      }
    });
  });
  if (userRoles.includes('Partner_Team_Manager'))
    roles = roles.filter(r => r.name == 'Partner_Representative');
  return (
    <Card className={classes.card}>
      {roles.map(
        (
          { name, className, abilities, inviteButtonText, shortName },
          index
        ) => {
          return (
            <div className={cx(classes.role, classes[className])} key={index}>
              <div className={classes.content}>
                <div className={classes.title}>
                  {getIcon(name)} <h4>{className}</h4>
                </div>
                {abilities.allowed.length !== 0 && (
                  <div className={classes.privileges}>
                    <h5>
                      <i className="fa fa-check" aria-hidden="true"></i>
                      {shortName}s can:{' '}
                    </h5>
                    <ul className={classes.list}>
                      {abilities.allowed.map((privileges, index) => (
                        <li key={index}>{privileges}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {abilities.denied.length !== 0 && (
                  <div className={classes.prohibitions}>
                    <h5>
                      <i className="fa fa-times" aria-hidden="true"></i>
                      {shortName}s can't:
                    </h5>
                    <ul className={classes.list}>
                      {abilities.denied.map((privileges, index) => (
                        <li key={index}>{privileges}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {showActions && (
                <div className={classes.action}>
                  <Button
                    onClick={() => handleSelectRole(name)}
                    className={classes.inviteButton}
                  >
                    {inviteButtonText}
                  </Button>
                </div>
              )}
            </div>
          );
        }
      )}
    </Card>
  );
};

export default RolePermissions;
