import React, { useEffect, useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { getValueFromTheFormikSchema } from './utils';
import { get, isArray, isEmpty, map, uniq } from 'lodash';
import FormikDatePicker from 'components/Formik';
import ConfirmationModal from '../../ConfirmationModal';
import Button from 'components/Button';
import { FieldWrapper, GenerateLabel } from './components';
import { FormikInput } from 'components/FormFields/Input';
import classes from '../TaxReturnsForm.module.scss';
import RSelect from 'components/FormFields/RSelect';
import cn from 'classnames';
import Trash from './Trash';
import * as moment from 'moment';
export const checkValidToRender = ({ values, dependsOn, index }) => {
  const checkDepend = depend => {
    const valueOfDependField = getValueFromTheFormikSchema(
      values,
      depend?.path || '',
      index
    );

    let duplicateDepend = null;
    dependsOn.find(d => {
      if (d.fields && d.type) {
        return false;
      } else {
        const isTrue = d?.path === depend?.path && depend.value !== d.value;
        if (isTrue) {
          duplicateDepend = d;
        }
      }

      return duplicateDepend;
    });
    const isValid =
      (isArray(depend.value)
        ? depend.value.includes(valueOfDependField)
        : valueOfDependField === depend.value) ||
      (isArray(duplicateDepend?.value)
        ? duplicateDepend?.value.includes(valueOfDependField)
        : valueOfDependField === duplicateDepend?.value);

    return isValid;
  };

  let isValid = false;
  const shouldRender = (dependsOn || []).every(depend => {
    if (depend.type && depend.fields) {
      if (depend.type === 'OR') {
        isValid = depend.fields.some(checkDepend);
      } else {
        isValid = depend.fields.every(checkDepend);
      }
    } else {
      isValid = checkDepend(depend);
    }

    return isValid;
  });

  return shouldRender;
};

export const GenerateFormikTextInput = ({
  obj,
  style,
  name,
  className = '',
  dependsOn = [],
  index,
  setInvalidateFields,
  invalidateFields,
}) => {
  const { touched, values, setFieldValue } = useFormikContext();

  const value = getValueFromTheFormikSchema(values, name);

  const fieldTouched = get(touched, name, '');
  const shouldRender = checkValidToRender({
    dependsOn,
    values,
    index,
  });

  useEffect(() => {
    if (shouldRender && !obj.disabled) {
      // remove from invalidate fields if values entered
      if (value === null || value === '') {
        if (!invalidateFields.includes(name)) {
          setInvalidateFields(prev => uniq([...prev, name]));
        }
      } else {
        if (invalidateFields.includes(name)) {
          setInvalidateFields(prev => prev.filter(p => p !== name));
        }
      }
    } else {
      if (invalidateFields.includes(name)) {
        setInvalidateFields(prev => prev.filter(p => p !== name));
      }
    }
  });

  if (value !== null && !shouldRender) {
    setFieldValue(name, null);
    setInvalidateFields(prev => prev.filter(p => p !== name));
  }

  if (!shouldRender) {
    return null;
  }

  return (
    <FieldWrapper
      className={`${className || ''} ${name.split('.').join('-') || ''}`}
      style={style}
    >
      <FormikInput
        type={obj.type}
        required={obj.required}
        name={name}
        label={obj.label}
        placeholder={obj.placeholder}
        groupClassName={classes['form-group']}
        disabled={obj.disabled}
        onKeyDown={e => {
          if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();
        }}
        onWheel={event => event.currentTarget.blur()}
      />
      {invalidateFields.includes(name) && fieldTouched && (
        <div className={classes.error}>This field is required</div>
      )}
    </FieldWrapper>
  );
};

export const GenerateFormikRadioField = ({
  obj,
  style,
  name,
  dependsOn,
  optionStyle,
  className = '',
  index,
  invalidateFields,
  setInvalidateFields,
  isDisabled,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [confirmModalValue, setConfirmModalValue] = useState();

  const noOptions = !obj.options || !(obj.options || []).length;
  const value = getValueFromTheFormikSchema(values, name);

  const shouldRender =
    !noOptions &&
    checkValidToRender({
      dependsOn,
      values,
      index,
    });

  useEffect(() => {
    if (shouldRender) {
      // remove from invalidate fields if values entered
      if (value === null) {
        if (!invalidateFields.includes(name)) {
          setInvalidateFields(prev => uniq([...prev, name]));
        }
      } else {
        if (invalidateFields.includes(name)) {
          setInvalidateFields(prev => prev.filter(p => p !== name));
        }
      }
    } else {
      if (invalidateFields.includes(name)) {
        setInvalidateFields(prev => prev.filter(p => p !== name));
      }
    }
  });

  if (value !== null && !shouldRender) {
    setFieldValue(name, null);
    setInvalidateFields(prev => prev.filter(p => p !== name));
  }

  if (!shouldRender) {
    return null;
  }

  const handleClick = currentValue => {
    if (obj.confirm) {
      const isValid =
        (value === (obj.confirm.on === true ? false : true) &&
          currentValue === obj.confirm.on) ||
        (obj.confirm.on === undefined &&
          value !== null &&
          value !== currentValue);
      if (isValid) {
        setConfirmModalValue(currentValue);
        return;
      }
    }
    setFieldValue(name, currentValue);
  };

  const handleConfirm = () => {
    setFieldValue(name, confirmModalValue);
    setConfirmModalValue();
  };
  const isCompleted = className?.includes('completed') || false;

  return (
    <FieldWrapper
      className={`${className || ''}  radio ${name.split('.').join('-') || ''}`}
      style={{ ...style, display: 'block' }}
    >
      <GenerateLabel obj={obj} name={name} dependsOn={dependsOn} />
      <div style={{ display: 'flex', gap: '10px' }}>
        <Field name={name} type="radio">
          {({ meta, ...fieldProps }) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  {!!obj.confirm && (
                    <ConfirmationModal
                      isOpen={confirmModalValue !== undefined}
                      toggle={() => setConfirmModalValue()}
                      action={() => handleConfirm()}
                      message={obj.confirm.message}
                      title={obj.confirm.title}
                      buttonGroup={obj.confirm.actions}
                    />
                  )}

                  {obj.options.map((option, index) => {
                    const isActive = value === option.value;
                    return (
                      <Button
                        disabled={isDisabled}
                        key={obj.name + ' ' + index}
                        className={cn(
                          {
                            [classes.disableSelectedRadio]:
                              isActive && isCompleted,
                            [classes.disableRadio]: !isActive && isCompleted,
                          },
                          obj.name
                        )}
                        onClick={() => handleClick(option.value)}
                        style={{
                          ...optionStyle,
                          backgroundColor: isActive ? '#1DA1F2' : 'white',
                          color: isActive ? 'white' : '#04A0F0',
                          border: '1px solid #04a0f0',
                          borderRadius: '4px',
                          padding: '7px 15px',
                        }}
                      >
                        {option.label}
                      </Button>
                    );
                  })}
                </div>
                {meta.touched && meta.error && (
                  <div className={classes.error}>{meta.error}</div>
                )}
              </div>
            );
          }}
        </Field>
      </div>
    </FieldWrapper>
  );
};

export const GenerateFormikDateField = ({
  obj,
  style,
  name,
  dependsOn,
  className = '',
  index,
  invalidateFields,
  setInvalidateFields,
}) => {
  const {
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const value = getValueFromTheFormikSchema(values, name);
  const startDate =
    getValueFromTheFormikSchema(values, `general_information.start_year`) || '';

  const endDate =
    getValueFromTheFormikSchema(values, `general_information.end_year`) || '';
  const fieldTouched = get(touched, name);

  const shouldRender = checkValidToRender({
    dependsOn,
    values,
    index,
  });

  useEffect(() => {
    if (shouldRender) {
      // remove from invalidate fields if values entered
      if (value === null) {
        if (!invalidateFields.includes(name)) {
          setInvalidateFields(prev => uniq([...prev, name]));
        }
      } else {
        if (invalidateFields.includes(name)) {
          setInvalidateFields(prev => prev.filter(p => p !== name));
        }
      }
    } else {
      if (invalidateFields.includes(name)) {
        setInvalidateFields(prev => prev.filter(p => p !== name));
      }
    }
  });

  if (value !== null && !shouldRender) {
    setFieldValue(name, null);
    setInvalidateFields(prev => prev.filter(p => p !== name));
  }

  if (!shouldRender) {
    return null;
  }
  const isCompleted = className?.includes('completed') || false;
  const handleDateActive = currentDate => {
    if (name === 'general_information.start_year' && !!endDate) {
      return currentDate.isBefore(moment(endDate).format(`MMM DD, YYYY`));
    }
    if (name === 'general_information.end_year' && !!startDate) {
      return currentDate.isAfter(moment(startDate).format(`MMM DD, YYYY`));
    } else return true;
  };

  return (
    <FieldWrapper
      className={`${className || ''} ${name.split('.').join('-') || ''}`}
      style={style}
    >
      <GenerateLabel obj={obj} name={name} dependsOn={dependsOn} />
      <FormikDatePicker
        isValidDate={handleDateActive}
        classes={{ error: classes.error }}
        name={name}
        disabled={isCompleted}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
      />
      {invalidateFields.includes(name) && fieldTouched && (
        <div className={classes.error}>This field is required</div>
      )}
    </FieldWrapper>
  );
};

export const GenerateFormikArrayField = ({
  obj,
  dependsOn,
  className,
  style,
  name,
  nestedFields,
  setInvalidateFields,
  invalidateFields,
}) => {
  const { values, setFieldValue } = useFormikContext();
  // const [orderedComponents, setOrderedComponents] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const value = getValueFromTheFormikSchema(values, name);

  const shouldRender = checkValidToRender({
    dependsOn,
    values,
  });

  useEffect(() => {
    if (shouldRender) {
      // remove from invalidate fields if values entered
      if (!(value || []).length) {
        if (!invalidateFields.includes(name)) {
          setInvalidateFields(prev => uniq([...prev, name]));
        }
      } else {
        if (invalidateFields.includes(name)) {
          setInvalidateFields(prev => prev.filter(p => p !== name));
        }
      }
    } else {
      if (invalidateFields.includes(name)) {
        setInvalidateFields(prev => prev.filter(p => p !== name));
      }
    }
  });

  if (!!(value || []).length && !shouldRender) {
    setFieldValue(name, []);
    setInvalidateFields(prev => prev.filter(p => p !== name));
  }

  if (!shouldRender) {
    return null;
  }

  const initialValuesFor = Object.keys(nestedFields).reduce((obj, field) => {
    obj[field] = null;
    return obj;
  }, {});
  const isCompleted = className?.includes('completed') || false;

  return (
    <FieldWrapper
      className={`${className || ''} ${name.split('.').join('-') || ''}`}
      style={style}
    >
      <FieldArray
        name={name}
        render={arrayHelpers => {
          if ((value === null || !value.length) && shouldRender) {
            arrayHelpers.push(initialValuesFor);
          }
          return (
            <div className={classes.multiGroupWrapper}>
              <div className={classes.multiGroupContainer}>
                {(value || []).map((_, index) => {
                  const orderedComponents = [];

                  const getRenderComponent = object => {
                    // get sorted objects of components
                    map(object, (value, key) => {
                      const { Component, ...rest } = value || {};
                      if (value.fields || Object.keys(rest).length) {
                        getRenderComponent(value.fields || rest);
                      }
                      if (Component) {
                        const render = Component;

                        orderedComponents.push(render);
                      }
                    });
                  };

                  getRenderComponent(nestedFields);
                  return (
                    <div className={classes.multiGroup} key={index}>
                      <div className={classes.formIndex}>
                        <span>K-1 Sub-Record #{index + 1}</span>
                        {(value || []).length !== 1 && (
                          <span
                            className={cn(classes.trashWrapper, {
                              [classes.disableTrash]: isCompleted,
                            })}
                            onClick={() => setDeleteModal(index)}
                          >
                            <Trash />
                          </span>
                        )}
                      </div>
                      {orderedComponents.map((c, i) => {
                        const propsName = c.props?.name || '';
                        const splittedName = propsName.split(name);
                        const newName = splittedName.join(name + '.' + index);
                        return React.cloneElement(c, {
                          index,
                          name: newName,
                          key: `nested-fields-` + i + `-` + index,
                          invalidateFields,
                          setInvalidateFields,
                        });
                      })}
                    </div>
                  );
                })}
              </div>
              <div
                className={cn(classes.addMoreButtonWrapper, {
                  [classes.paddingTop]: !(value || []).length,
                })}
              >
                <Button
                  disabled={isCompleted}
                  className={classes.addMoreButton}
                  type="button"
                  onClick={() => arrayHelpers.push(initialValuesFor)}
                  leftIcon={
                    <img src={require('assets/img/icons/file-plus.svg')} />
                  }
                >
                  {obj?.add_more_label}
                </Button>
              </div>
              <ConfirmationModal
                isOpen={deleteModal !== null}
                toggle={() => setDeleteModal(null)}
                action={() => {
                  arrayHelpers.remove(deleteModal);
                  setDeleteModal(null);
                }}
                message={`Are you sure you want to remove this K-1 Sub-Record? This action cannot be undone.`}
                title={`Confirm Removing K-1 Sub-Record`}
                buttonGroup={[
                  {
                    button: 'Cancel',
                  },
                  {
                    button: 'Remove K-1 Sub-Record',
                    style: 'danger',
                  },
                ]}
              />
            </div>
          );
        }}
      />
    </FieldWrapper>
  );
};

export const GenerateFormikSelectField = ({
  obj,
  style,
  name,
  dependsOn,
  className = '',
  index,
  setInvalidateFields,
  invalidateFields,
}) => {
  const {
    touched,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const value = getValueFromTheFormikSchema(values, name);
  const fieldTouched = get(touched, name);
  const shouldRender = checkValidToRender({
    dependsOn,
    values,
    index,
  });

  useEffect(() => {
    if (shouldRender) {
      // remove from invalidate fields if values entered
      if (value === null) {
        if (!invalidateFields.includes(name)) {
          setInvalidateFields(prev => uniq([...prev, name]));
        }
      } else {
        if (invalidateFields.includes(name)) {
          setInvalidateFields(prev => prev.filter(p => p !== name));
        }
      }
    } else {
      if (invalidateFields.includes(name)) {
        setInvalidateFields(prev => prev.filter(p => p !== name));
      }
    }
  });

  if (value !== null && !shouldRender) {
    setFieldValue(name, null);
    setInvalidateFields(prev => prev.filter(p => p !== name));
  }

  if (!shouldRender) {
    return null;
  }
  const isCompleted = className?.includes('completed') || false;
  const handleBlur = () => {
    setFieldTouched(name, true);
  };
  return (
    <FieldWrapper
      className={`${className || ''} ${name.split('.').join('-') || ''}`}
      style={style}
    >
      <GenerateLabel obj={obj} name={name} dependsOn={dependsOn} />
      <Field name={name}>
        {({ field }) => {
          const fieldValue = (obj?.options || []).find(
            v => v.value === field.value
          );
          return (
            <RSelect
              isClearable={false}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              isDisabled={isCompleted}
              options={obj?.options}
              placeholder="Select Type"
              defaultValue={fieldValue}
              onChange={selectedOption => {
                setFieldValue(name, selectedOption.value);
              }}
              onBlur={handleBlur}
            />
          );
        }}
      </Field>
      {invalidateFields.includes(name) && fieldTouched && (
        <div className={classes.error}>This field is required</div>
      )}
    </FieldWrapper>
  );
};
