import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import Button from 'components/Button';
import { debounce, get, isEmpty, isEqual, map, orderBy } from 'lodash';
import classes from './TaxReturnsForm.module.scss';
import OwnerShip from '../OwnerShip';
import { postTaxReturnsFormValidation } from 'store/actions/taxReturns';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'yup';
import CustomAccordion from 'components/CustomAccordion';
import ConfirmationModal from '../ConfirmationModal';
import * as Yup from 'yup';
import { generateSchemaComponentFromJSON } from './helpers/generateComponentSchemaFromJSON';
import { ownerValidationSchema } from '../OwnerShip/OwnerShip';
import Question from '../Questions';
import Loading from 'components/Loading';
import cn from 'classnames';

const CONFIRMATION_TITLE = 'Confirm Changing Tax Return Type';
const CONFIRMATION_MESSAGE =
  'Are you sure you want to change the form type? This will permanently delete all currently entered data for this section.';
const DELETE_TITLE = 'Delete Tax Return Section';
const DELETE_MESSAGE =
  'Are you sure you want to delete this Tax Return Section? This will permanently delete all currently entered data for this section.';

const debouncedTime = 1000;

// render component in the form with the same order.
const RenderChildrenComponent = ({
  components,
  handleAddForm,
  handleRemoveForm,
  index,
  values,
  isMultiFilling,
  setFieldValue,
  handleSubmit,
  returnYear,
  forms,
  isEditing,
  form_index,
  taxReturnType,
  setInvalidateFields,
  invalidateFields,
  formStatus,
  formId,
}) => {
  const [orderedComponents, setOrderedComponents] = useState([]);
  const formTypes = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'formTypes.data', []);
  });

  const { initialValues } = useFormikContext();

  // initial create form on returnType, taxReturnType selection to get formId.
  // this mount on once returnType, taxReturnType selected.
  useEffect(() => {
    if (!formId) {
      handleSubmit(initialValues, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, initialValues]);

  const debouncedCallback = useCallback(
    debounce((val, forceUpdate) => {
      handleSubmit(val, true, forceUpdate);
    }, debouncedTime),
    [formStatus]
  );

  useEffect(() => {
    if (formId && !isEmpty(values) && !isEqual(initialValues, values)) {
      debouncedCallback(values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, formId]);

  const multipleFilling = useMemo(
    () => get(values, 'general_information.multiple_filing', undefined),
    [values]
  );

  const multiFilledForm = useMemo(() => {
    const documentType = (formTypes || []).find(
      type => type.document_type === taxReturnType
    );
    return (forms || []).filter(
      f =>
        documentType &&
        f.document_form_id &&
        f.financial_year_id &&
        f.financial_year_id === returnYear &&
        f.document_form_id === documentType.id
    );
  }, [returnYear, formTypes, forms, taxReturnType]);

  useEffect(() => {
    if (formId) {
      if (multiFilledForm.length > 1) {
        setFieldValue('general_information.multiple_filing', form_index === 1);
      } else if (multipleFilling && multiFilledForm.length === 1) {
        setFieldValue('general_information.multiple_filing', false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, form_index, multiFilledForm, setFieldValue]);

  const handleMultiForm = () => {
    if (!taxReturnType || !returnYear) {
      return;
    }
    const documentType = formTypes.find(
      type => type.document_type === taxReturnType
    );
    const secondIndexMultiForm =
      form_index === 1 &&
      (forms || []).find(
        f =>
          f.financial_year_id === returnYear &&
          f.form_index === 2 &&
          documentType?.id === f.document_form_id
      ); //check if there is more than one form with same year

    if (multipleFilling === true && !secondIndexMultiForm) {
      //add new form if there is no multiFilledForm and multiple filing is true, passed same returnYear
      handleAddForm({
        financial_year_id: returnYear,
        form_index: 2,
        document_form_id: documentType?.id || 1,
        document_type: documentType.document_type,
      });
    }
    if (multipleFilling === false && secondIndexMultiForm) {
      //remove form multiple filing is false and there is multiFilledForm,passed tempId if form not saved
      handleRemoveForm(secondIndexMultiForm.id, {
        tempId: secondIndexMultiForm.tempId,
        form_index: form_index,
        financial_year: returnYear,
        document_type: documentType.document_type,
        document_form_id: documentType?.id || 1,
        financial_year_id: returnYear,
      });
    }
  };

  useEffect(() => {
    if (formId) handleMultiForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, multipleFilling]);

  useEffect(() => {
    if (formId) {
      setOrderedComponents([]);
      const getRenderComponent = object => {
        // get sorted objects of components
        map(object, (value, key) => {
          const { Component, ...rest } = value;
          if (value.fields || Object.keys(rest).length) {
            getRenderComponent(value.fields || rest);
          }
          if (Component) {
            const render = Component;

            setOrderedComponents(prevState => [
              ...prevState,
              { Component: render, order: rest.order, name: rest.name },
            ]);
          }
        });
      };

      getRenderComponent(components);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, isEditing]);

  return (
    <>
      {/**
       * need to pass additional props
       * so clone the all schema components & pass new props
       * isDisabled for multiple filling form_index = 2
       */}
      {orderBy(orderedComponents, 'order')
        .map(o => o.Component)
        .map((o, i) => {
          return React.cloneElement(o, {
            key: `fields-` + i + `-` + index,
            setInvalidateFields,
            invalidateFields,
            isDisabled:
              o.props.name === 'general_information.multiple_filing' &&
              form_index !== 1,
          });
        })}
    </>
  );
};

const RenderForm = ({
  activeIndex,
  currentDocumentId,
  taxReturnType,
  handleAddForm,
  handleRemoveForm,
  index,
  returnYear,
  formId,
  isMultiFilling,
  form,
  form_index,
  forms,
  formStatus,
  tempId,
  isEditing,
  setIsEditing,
  returnTypeOptions,
  ...props
}) => {
  const [invalidateFields, setInvalidateFields] = useState([]);
  const [formSchema, setFormSchema] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const availableForms = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'formTypes.data', []);
  });
  const { isDeleting, isSubmitting } = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'taxReturnsFileValidation', {});
  });
  const is1040TaxReturnType = useMemo(() => taxReturnType === '1040', [
    taxReturnType,
  ]);
  useMemo(() => {
    const requiredForm = availableForms?.find(
      x => x.document_type === taxReturnType
    );
    const fields = requiredForm?.fields;
    const response = generateSchemaComponentFromJSON(fields || [], !isEditing);
    setFormSchema(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, taxReturnType, availableForms]);

  const formData = useMemo(() => {
    const validationSchema = object().shape({
      ...formSchema?.schema,
      owners: is1040TaxReturnType
        ? Yup.array().of(ownerValidationSchema)
        : undefined,
    });

    const formValue = { ...(form || {}) };
    if (is1040TaxReturnType) {
      formValue.owners = form.owners || [];
    }
    const initialValues = validationSchema.cast(formValue, {
      assert: false,
      stripUnknown: true,
    });
    return {
      validationSchema,
      initialValues,
    };
  }, [form, formSchema.schema, is1040TaxReturnType]);

  const formTypes = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'formTypes.data', []);
  });

  if (isEmpty(formSchema?.Component) && !!taxReturnType) {
    return <Loading />;
  }

  const handleSubmit = (values, isDraft, forceUpdate) => {
    const isAlreadyCompleted = isDraft && formStatus === 'complete';
    const documentForm = formTypes.find(
      type => taxReturnType === type.document_type
    );

    if (!documentForm) {
      return;
    }

    if (!isEditing && !isAlreadyCompleted) {
      setIsEditing(true);
    } else if (
      (formStatus === 'complete' && !isDraft && isEditing) ||
      isAlreadyCompleted
    ) {
      setIsEditing(false);
    }

    dispatch(
      postTaxReturnsFormValidation(
        {
          id: formId,
          form: formData.validationSchema.cast(values, {
            stripUnknown: true,
            assert: false,
          }),
          is_draft: isAlreadyCompleted
            ? false
            : !isDraft && isEditing
            ? false
            : true,
          status: isAlreadyCompleted
            ? 'complete'
            : !isDraft && isEditing
            ? 'complete'
            : 'in_progress',
          document_form_id: documentForm.id || 1,
          financial_year_id: returnYear,
          document_id: currentDocumentId,
          form_index: form_index,
          document_type: documentForm.document_type,
        },
        true,
        true || forceUpdate || !isDraft //@TODO no need forceupdate now will remove once verified
      )
    );
  };

  const renderChild = () => {
    if (taxReturnType) {
      //TODO: handle form on taxReturnType
      return (
        <Formik
          validationSchema={formData?.validationSchema}
          initialValues={formData?.initialValues}
          onSubmit={values => handleSubmit(values)}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <RenderChildrenComponent
                  {...props}
                  components={formSchema?.Component}
                  handleSubmit={handleSubmit}
                  values={values}
                  handleAddForm={handleAddForm}
                  handleRemoveForm={handleRemoveForm}
                  index={index}
                  returnYear={returnYear}
                  setFieldValue={setFieldValue}
                  isMultiFilling={isMultiFilling}
                  form_index={form_index}
                  formId={formId}
                  isEditing={isEditing}
                  forms={forms}
                  formStatus={formStatus}
                  returnTypeOptions={returnTypeOptions}
                  taxReturnType={taxReturnType}
                  invalidateFields={invalidateFields}
                  setInvalidateFields={setInvalidateFields}
                />
                {is1040TaxReturnType && (
                  <div className={classes.ownerShipWrapper}>
                    <OwnerShip
                      value={values.owners}
                      companyName={values.entity_information?.company_name}
                      setFieldValue={setFieldValue}
                      isMultiOwner={values?.entity_information?.multiple_owners}
                      completed={formStatus === 'complete' && !isEditing}
                      setInvalidateFields={setInvalidateFields}
                    />
                  </div>
                )}

                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.deleteButton}
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    type="submit"
                    className={cn({
                      [classes.disabledOption]: !!invalidateFields.length,
                      [classes.markCompleteButton]:
                        formStatus === 'in_progress' || isEditing,
                      [classes.updateButton]:
                        formStatus === 'complete' && !isEditing,
                      [classes.noAction]: !!isSubmitting || !!isDeleting,
                    })}
                  >
                    {formStatus === 'complete' && !isEditing
                      ? 'Update Information'
                      : 'Mark as Complete'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      );
    } else {
      return (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.deleteButton}
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            Delete
          </Button>
          <Button className={classes.markCompleteButton} disabled={true}>
            Mark as Complete
          </Button>
        </div>
      );
    }
  };

  const handleDelete = () => {
    handleRemoveForm(formId, {
      tempId: tempId,
      form_index: form_index,
      financial_year_id: returnYear,
      document_form_id: currentDocumentId || 1,
    });

    setDeleteModal(false);
  };

  return (
    <>
      {renderChild()}
      {deleteModal && (
        <ConfirmationModal
          isOpen={deleteModal}
          toggle={() => setDeleteModal(prev => !prev)}
          action={() => handleDelete()}
          title={DELETE_TITLE}
          message={DELETE_MESSAGE}
          buttonGroup={[
            { button: 'Cancel' },
            { button: 'Delete Tax Return Section' },
          ]}
        />
      )}
    </>
  );
};

const TaxReturnsForm = ({
  index,
  isCollapsed,
  handleAddForm,
  handleRemoveForm,
  currentDocumentId,
  formStatus,
  documentType,
  isMultiFilling,
  formId,
  forms,
  financialYear,
  form_index,
  handleUpdateForm,
  form,
  tempId,
  setErrorMessage,
  yearData,
}) => {
  const [activeIndex, setActiveIndex] = useState(index);
  const [hasStarted, setHasStarted] = useState(false);
  const [taxReturnType, setTaxReturnType] = useState(documentType);
  const [returnYear, setReturnYear] = useState(financialYear);
  const [isEditing, setIsEditing] = useState(false);
  const formTypes = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'formTypes.data', []);
  });
  const { isSubmitting } = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'taxReturnsFileValidation', {});
  });
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState({
    isOpen: false,
    currentValue: null,
  });
  const { isOpen, currentValue } = isOpenConfirmationModal;

  const returnTypeOptions = formTypes.map(item => {
    return { label: item.document_type, value: item.document_type };
  });

  useEffect(() => {
    if (returnYear) setHasStarted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnYear]);
  const handleCollapseAll = () => {
    setActiveIndex(null);
  };
  const handleExpandAll = () => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (!isCollapsed) {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);
  useEffect(() => {
    setIsEditing(formStatus !== 'complete');
  }, [formStatus, setIsEditing]);

  const handleTaxReturnTypeChange = value => {
    const findSameYear = (forms || []).filter(
      form =>
        form.financial_year_id === returnYear && form.document_type === value
    );
    if (findSameYear.length >= 2) {
      //prevent number of forms on document_form_id
      setErrorMessage(
        'You can add a maximum of two forms with the same return type in particular year'
      );
    } else {
      if (taxReturnType && taxReturnType !== value) {
        setErrorMessage('');
        setIsOpenConfirmationModal({ isOpen: true, currentValue: value });
      } else {
        setTaxReturnType(value);
        const documentForm = formTypes.find(
          type => type.document_type === value
        );

        handleUpdateForm({
          tempId,
          id: formId,
          financial_year_id: returnYear,
          document_form_id: documentForm?.id,
          document_type: documentForm?.document_type,
        });
      }
    }
  };

  const handleReturnYearChange = value => {
    const findSameYear = (forms || []).filter(
      form =>
        form.financial_year_id === value && form.document_type === taxReturnType
    );
    if (findSameYear.length >= 2) {
      //prevent number of forms on document_form_id
      return setErrorMessage(
        'You can add a maximum of two forms with the same return type in particular year'
      );
    }
    setHasStarted(true);
    setReturnYear(value);
    const documentForm = formTypes.find(
      type => type.document_type === taxReturnType
    );
    handleUpdateForm({
      tempId,
      id: formId,
      financial_year_id: value,
      document_form_id: documentForm?.id,
      document_type: documentForm?.document_type,
    });
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <CustomAccordion
          key={index}
          index={index}
          activeIndex={
            isCollapsed ? (activeIndex !== null ? index : null) : activeIndex
          }
          onSelect={selectedIndex => {
            if (activeIndex === index) {
              setActiveIndex(null);
            } else {
              setActiveIndex(selectedIndex);
            }
          }}
          header={
            <div className={classes.accordionHeaderWrapper}>
              {returnYear && (
                <div className={classes.returnYear}>
                  {yearData.find(y => y.id === returnYear)?.year}
                  {!!isMultiFilling && <span>.{form_index}</span>}
                </div>
              )}
              {taxReturnType && (
                <div className={classes.taxReturnType}>
                  {
                    returnTypeOptions.find(y => y.value === taxReturnType)
                      ?.label
                  }{' '}
                  Form
                </div>
              )}
              {hasStarted ? (
                <div
                  className={cn({
                    [classes.inProgress]:
                      formStatus === 'in_progress' || isEditing,
                    [classes.completed]:
                      formStatus === 'complete' && !isEditing,
                  })}
                >
                  {formStatus === 'complete' && !isEditing
                    ? 'Completed'
                    : 'In Progress'}
                </div>
              ) : (
                <div className={classes.hasStarted}>Not Started</div>
              )}
            </div>
          }
          customHeaderStyle={{
            paddingTop: '12px',
            paddingBottom: '12px',
            boxShadow: 'none',
            backgroundColor: '#f6f9fc',
          }}
          dropIconPosition="right"
          customArrowStyle={{
            color: '#525f7f',
            fontSize: 'medium',
          }}
          customChildContainerStyle={{
            minWidth: '200px',
            position: 'relative',
          }}
          customActiveContentStyle={{ padding: '0px 16px' }}
        >
          {!formId && isSubmitting && (
            <Loading wrapperClass={classes.loading} />
          )}
          <div className={classes.questionContainer}>
            <Question
              selectedValue={returnYear}
              options={yearData?.map(y => ({
                label: y?.year,
                value: y?.id,
              }))}
              question="Select year"
              setSelectedValue={handleReturnYearChange}
              disabled={formId}
              completed={formStatus === 'complete' && !isEditing}
            />
            {returnYear && (
              <Question
                selectedValue={taxReturnType}
                options={returnTypeOptions}
                question="Select the tax return type"
                setSelectedValue={handleTaxReturnTypeChange}
                optionClassName={classes.returnTypeBtn}
                disabled={formId}
                completed={formStatus === 'complete' && !isEditing}
              />
            )}
          </div>
          {taxReturnType && isOpen && (
            <ConfirmationModal
              isOpen={isOpen}
              toggle={() => setIsOpenConfirmationModal(prev => !prev)}
              action={() => {
                const documentForm = formTypes.find(
                  type =>
                    type.document_type === isOpenConfirmationModal.currentValue
                );

                handleUpdateForm({
                  tempId,
                  id: formId,
                  financial_year_id: returnYear,
                  document_form_id: documentForm?.id,
                  document_type: documentForm?.document_type,
                });
                setTaxReturnType(currentValue);
                setIsOpenConfirmationModal(false);
              }}
              title={CONFIRMATION_TITLE}
              message={CONFIRMATION_MESSAGE}
              buttonGroup={[
                { button: 'Cancel' },
                { button: 'Change Tax Return Type' },
              ]}
            />
          )}

          <RenderForm
            key={taxReturnType}
            tempId={tempId}
            handleAddForm={handleAddForm}
            handleRemoveForm={handleRemoveForm}
            handleUpdateForm={handleUpdateForm}
            index={index}
            financialYear={financialYear}
            activeIndex={activeIndex}
            currentDocumentId={currentDocumentId}
            taxReturnType={taxReturnType}
            returnYear={returnYear}
            formId={formId}
            isMultiFilling={isMultiFilling}
            form={form}
            forms={forms}
            form_index={form_index}
            formStatus={formStatus}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            returnTypeOptions={returnTypeOptions}
          />
        </CustomAccordion>
      </div>
    </div>
  );
};

export default TaxReturnsForm;
