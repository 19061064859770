import Types from '../types/clientProfile';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

const initialState = {
  clientProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  clientDocuments: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  editClientProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  aboutCompany: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  creditBank: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  agreementState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  downloadFile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  recentTimeEntries: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: null,
  },
  deleteAgreement: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  agreementTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  deliverables: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  activeDeliverable: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  clientTasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  myOpenTasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  timeLogGraph: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  viewedProjects: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  activities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  clientTeam: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  createStudy: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function ClientProfile(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CLIENT_PROFILE_INPROGRESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          isInProgress: true,
          isError: false,
          message: initialState.clientProfile.message,
          id: action.id,
        },
      };
    case Types.FETCH_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          message: initialState.clientProfile.message,
          id: action.id,
        },
      };
    case Types.FETCH_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          isInProgress: false,
          isError: true,
          message: action.message,
          id: action.id,
        },
      };
    case Types.FETCH_AGREEMENTS_INPROGRESS:
      return {
        ...state,
        agreementState: {
          ...state.agreementState,
          isInProgress: true,
        },
      };
    case Types.FETCH_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreementState: {
          ...state.agreementState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_AGREEMENTS_FAILURE:
      return {
        ...state,
        agreementState: {
          ...state.agreementState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_AGREEMENT_INPROGRESS:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          isInProgress: true,
        },
      };
    case Types.CREATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_AGREEMENT_FAILURE:
      return {
        ...state,
        createAgreement: {
          ...state.createAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.EDIT_AGREEMENT_INPROGRESS:
      return {
        ...state,
        editAgreement: {
          ...state.editAgreement,
          isInProgress: true,
        },
      };
    case Types.EDIT_AGREEMENT_SUCCESS:
      return {
        ...state,
        editAgreement: {
          ...state.editAgreement,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.EDIT_AGREEMENT_FAILURE:
      return {
        ...state,
        editAgreement: {
          ...state.editAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_EDIT_AGREEMENT:
      return {
        ...state,
        editAgreement: {
          isInProgress: false,
          isError: false,
          status: 0,
          message: '',
          data: null,
        },
      };
    case Types.UPDATE_AGREEMENT_INPROGRESS:
      return {
        ...state,
        updateAgreement: {
          ...state.updateAgreement,
          isInProgress: true,
        },
      };
    case Types.UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        updateAgreement: {
          ...state.updateAgreement,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_AGREEMENT_FAILURE:
      return {
        ...state,
        updateAgreement: {
          ...state.updateAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_AGREEMENT_DATA:
      return {
        ...state,
        editAgreement: {
          isInprogress: false,
          isError: false,
          status: 0,
          message: '',
          data: null,
        },
      };
    case Types.DOWNLOAD_FILE_INPROGRESS:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          isInProgress: true,
        },
      };
    case Types.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        downloadFile: {
          ...state.downloadFile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_AGREEMENT_INPROGRESS:
      return {
        ...state,
        deleteAgreement: {
          ...state.deleteAgreement,
          isInProgress: true,
        },
      };
    case Types.DELETE_AGREEMENT_SUCCESS:
      return {
        ...state,
        deleteAgreement: {
          ...state.deleteAgreement,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_AGREEMENT_FAILURE:
      return {
        ...state,
        deleteAgreement: {
          ...state.deleteAgreement,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_AGREEMENT_TYPES_INPROGRESS:
      return {
        ...state,
        agreementTypes: {
          ...state.agreementTypes,
          isInProgress: true,
        },
      };
    case Types.FETCH_AGREEMENT_TYPES_SUCCESS:
      return {
        ...state,
        agreementTypes: {
          ...state.agreementTypes,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_AGREEMENT_TYPES_FAILURE:
      return {
        ...state,
        agreementTypes: {
          ...state.agreementTypes,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_TIME_LOG_GRAPH_INPROGRESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_TIME_LOG_GRAPH_SUCCESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_INPROGRESS:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_SUCCESS:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_INPROGRESS:
      return {
        ...state,
        recentTimeEntries: {
          ...state.recentTimeEntries,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_SUCCESS:
      return {
        ...state,
        recentTimeEntries: {
          ...state.recentTimeEntries,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'recentTimeEntries.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
          isError: initialState.recentTimeEntries.isError,
          message: initialState.recentTimeEntries.message,
        },
      };
    case Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE:
      return {
        ...state,
        recentTimeEntries: {
          ...state.recentTimeEntries,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.UPDATE_CLIENT_INPROGRESS:
      return {
        ...state,
        editClientProfile: {
          ...state.editClientProfile,
          isInProgress: true,
        },
      };
    case Types.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        editClientProfile: {
          ...state.editClientProfile,
          isInProgress: false,
          status: 1,
        },
        clientProfile: {
          ...state.clientProfile,
          data: action.data,
        },
      };
    case Types.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        editClientProfile: {
          ...state.editClientProfile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_DELIVERABLES_INPROGRESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_DELIVERABLES_FAILURE:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_DELIVERABLE_INPROGRESS:
      return {
        ...state,
        activeDeliverable: {
          ...state.activeDeliverable,
          isInProgress: true,
        },
      };
    case Types.FETCH_DELIVERABLE_SUCCESS:
      return {
        ...state,
        activeDeliverable: {
          ...state.activeDeliverable,
          isInProgress: false,
          isError: false,
          data: action.data,
        },
      };
    case Types.FETCH_DELIVERABLE_FAILURE:
      return {
        ...state,
        activeDeliverable: {
          ...state.activeDeliverable,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.EDIT_CLIENT_DELIVERABLES_INPROGRESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: true,
          isError: false,
          message: '',
        },
      };

    case Types.EDIT_CLIENT_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          isError: false,
          message: '',
        },
      };

    case Types.EDIT_CLIENT_DELIVERABLES_FAILURE:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_CLIENT_DELIVERABLES_INPROGRESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: true,
          isError: false,
          message: '',
        },
      };

    case Types.DELETE_CLIENT_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          data: action.updatedDeliverable,
          isInProgress: false,
          isError: false,
          message: '',
        },
      };

    case Types.DELETE_CLIENT_DELIVERABLES_FAILURE:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.RESET_ACTIVE_DELIVERABLES:
      return {
        ...state,
        activeDeliverable: {
          isInProgress: false,
          isError: false,
          status: 0,
          message: '',
          data: {},
        },
      };

    case Types.FETCH_CLIENT_PROFILE_TASKS_INPROGRESS:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_PROFILE_TASKS_SUCCESS:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'clientTasks.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_CLIENT_PROFILE_TASKS_FAILURE:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_ACTIVITIES_SUCCESS: {
      let { data } = action;
      let activities = [];
      let existingData = get(state, 'activities.data.data');
      const { data: newActivities, ...rest } = data;
      if (existingData) {
        activities = [...existingData];
        (data.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          status: 1,
          data: {
            ...get(state, 'activities.data', {}),
            data: [...activities],
            ...rest,
          },
        },
      };
    }
    case Types.FETCH_CLIENT_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        clientDocuments: {
          ...state.clientDocuments,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        clientDocuments: {
          ...state.clientDocuments,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        clientDocuments: {
          ...state.clientDocuments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_TEAM_MEMBERS_INPROGRESS:
      return {
        ...state,
        clientTeam: {
          ...state.clientTeam,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        clientTeam: {
          ...state.clientTeam,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'clientTeam.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_CLIENT_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        clientTeam: {
          ...state.clientTeam,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_STUDY_INPROGRESS:
      return {
        ...state,
        createStudy: {
          ...state.createStudy,
          isInProgress: true,
        },
      };
    case Types.CREATE_STUDY_SUCCESS:
      return {
        ...state,
        createStudy: {
          ...state.createStudy,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_STUDY_FAILURE:
      return {
        ...state,
        createStudy: {
          ...state.createStudy,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
