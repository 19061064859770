import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import get from 'lodash/get';
import remove from 'lodash/remove';
import map from 'lodash/map';
import Request from 'api/request';
import { store } from 'store';

/*
Removes the data-value="XX" when mentions are added. Must be removed when sending to API
Eg: 
<span class="mention" data-index="0" data-denotation-char="" data-id="191" data-value="<a href=&quot;#&quot; target=>@James Albert" data-link="#" data-target="" data-user-id="191">
Clean up gives <span class="mention" data-index="0" data-denotation-char="" data-id="191" data-link="#" data-target="" data-user-id="191">
*/
export const cleanUpMentions = content => {
  if (isEmpty(content) || !isString(content)) return content;
  return content.replace(/data-value="(.*?)"/g, '');
};

/*
Adds the data-value="XX" where mentions are added. Need to add this is while sending description taken from the API to Editor. Else the tag will be shown as undefined. 
No need to add when just displaying contents
Eg: 
<span class="mention" data-index="0" data-denotation-char="" data-id="191" data-link="#" data-target="" data-user-id="191">
Add in gives <span class="mention" data-index="0" data-denotation-char="" data-id="191" data-value="<a href=&quot;#&quot; target=>@James Albert" data-link="#" data-target="" data-user-id="191">
*/
export const addInMentions = content => {
  if (isEmpty(content) || !isString(content)) return content;
  return content.replace(
    / data-link(.*?)target="">(.*?)<\/a>/g,
    'data-value="<a href=&quot;#&quot; target=>$2" data-link$1 target="">$2</a>'
  );
};

/*
  This function makes call for getting mentions list
 */
const getMentionList = async searchTerm =>
  await Request.call({
    url: `${get(store.getState(), 'components.mentionUrl')}&q=${searchTerm}`,
    method: 'GET',
  });

/*
  - This function will be used to calcular mention container left position on every render cycle so it doesn't go out of the container
 */
const recalculateLeft = () => {
  const qlEditorContainer = document.querySelector(`.ql-container`);
  // #${containerId}
  const qlEditorWidth = qlEditorContainer.getBoundingClientRect().width;

  const mentionListContainer = document.querySelector(
    `.mention-list-container`
  );

  if (mentionListContainer) {
    const mentionListWidth = mentionListContainer.getBoundingClientRect().width;
    const maxLeftPos = qlEditorWidth - mentionListWidth;

    const containerLeft = parseInt(
      mentionListContainer.style.left.slice(0, -2)
    );
    if (containerLeft > maxLeftPos) {
      mentionListContainer.style.left = `${maxLeftPos}px`;
    }
  }
};

/*
  - This function will be used as source in mentions and will render the list of html elements
  - This function has fourth param as callback function which we can execute after each render cycle
 */
export const getSourceList = async (searchTerm, renderList, mentionChar) => {
  const list = await getMentionList(searchTerm);
  const userList = get(list, 'data', []);
  remove(userList, user => user.id === get(store.getState(), 'auth.user.id'));
  renderList(
    map(userList, user => ({
      ...user,
      value: `@${user.name}`,
      link: '#',
      target: '',
      userId: user.id,
    })),
    searchTerm
  );
  recalculateLeft();
};
