import DatePicker from 'components/FormFields/DatePicker';
import { Field } from 'formik';
import React, { useRef } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/Calendar.svg';
import moment from 'moment';

const FormikDatePicker = ({
  name,
  classes,
  disabled = false,
  onBlur,
  isValidDate,
}) => {
  const datePickerRef = useRef(null);
  const handleClickDatePickerIcon = () => {
    if (datePickerRef.current) {
      const datePickerElement = datePickerRef.current;
      datePickerElement.click();
    }
  };

  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <DatePicker
            {...field}
            isValidDate={isValidDate}
            onChange={dates => {
              const date = moment(dates).format('MM/DD/YYYY');
              form.setFieldValue(field.name, date);
            }}
            onClose={() => {
              if (onBlur) onBlur();
              else return;
            }}
            renderInput={(props, openCalendar, closeCalendar) => (
              <div style={{ position: 'relative' }}>
                <input
                  {...props}
                  placeholder="Select Date"
                  disabled={disabled}
                  ref={datePickerRef}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={handleClickDatePickerIcon}
                >
                  <CalendarIcon />
                </div>
                {meta.touched && meta.error && (
                  <div className={classes?.error}>{meta.error}</div>
                )}
              </div>
            )}
          />
        );
      }}
    </Field>
  );
};

export default FormikDatePicker;
