import React, { useState } from 'react';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  Media,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap';
import Avatar from '../Avatar';
import ContentEditor from '../ContentEditor';
import classes from './Comment.module.scss';
import { withTime } from 'helpers/times';

const Comment = ({
  commentId,
  commentToScroll,
  commentRef,
  avatarClassName,
  user,
  content,
  createdAt,
  dropdownOptions = [],
  isEdited = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleMouseEnter = () => setShowDropdown(true);

  const handleMouseLeave = () => {
    setShowDropdown(false);
    setDropdownOpen(false);
  };

  return (
    <div
      {...(commentId === parseInt(commentToScroll) ? { ref: commentRef } : {})}
      id={commentId}
      className={classes.comment}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Media className="media-comment w-100">
        <Avatar
          url={user.avatar}
          className={cs('media-comment-avatar', avatarClassName)}
        />
        <Media className="w-100">
          <div className="media-comment-text w-100">
            <div className={classes.commentHeading}>
              <div className={classes.commentAuthor}>
                <h6 className="h5 my-0 mr-2" title={user.name}>
                  {user.name}
                </h6>
                <span>
                  {withTime(createdAt, {
                    tz: userTimezone,
                    format: 'date-time',
                  })}
                </span>
                <small>
                  {isEdited ? (
                    <i className="fas fa-pencil-alt text-light ml-1" />
                  ) : null}
                </small>
              </div>
              {showDropdown && dropdownOptions.length ? (
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    caret={false}
                    size="sm"
                    className={cs('text-light ml-1', classes.commentsDropdown)}
                    color=""
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {dropdownOptions.map((item, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            item.onClick();
                          }}
                        >
                          {item.text}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              ) : null}
            </div>
            <ContentEditor
              className="text-sm lh-160 w-100 text-break"
              content={content}
            />
          </div>
        </Media>
      </Media>
    </div>
  );
};

export default Comment;
