import Request from './request';

export const fetchPartnerTeammates = async (page, sort, query) => {
  return Request.call({
    url: '/partner/teams',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
    },
  });
};

export const fetchRoles = async () => {
  return Request.call({
    url: '/partner/roles/list/dropdown',
    method: 'GET',
  });
};

export const fetchStatuses = async () => {
  return Request.call({
    url: '/partner/userStatus/list/dropdown',
    method: 'GET',
  });
};

export const inviteTeamMember = async data => {
  return Request.call({
    url: '/partner/member/invitations/send',
    method: 'POST',
    data,
  });
};

export const updateTeamMember = async (id, data) => {
  return Request.call({
    url: `/partner/user/${id}`,
    method: 'PUT',
    data,
  });
};

export const fetchMember = async id => {
  return Request.call({
    url: `/users/${id}`,
    method: 'GET',
  });
};

export const postMemberStatus = async (userId, status) => {
  return Request.call({
    url: `partner/user/${userId}/${status}`,
    method: 'POST',
  });
};
