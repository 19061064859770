import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import classes from './PartnerTracker.module.scss';
import cx from 'classnames';
import { Card, UncontrolledTooltip } from 'reactstrap';
import InfoCircle from 'assets/img/icons/info-circle-dark-icon.svg';
import NoDataPlaceholder from './NoDataPlaceholder';
import { isEmpty } from 'lodash';

const PartnerStatus = ({ data, percentageData }) => {
  let formattedData = [
    {
      label: 'Onboarding',
      value: data['Onboarding'],
      percentageValue: percentageData['Onboarding'],
      color: '#4FB3C9',
      tooltipContent: 'This Partner has not yet made a referral to Strike.',
    },
    {
      label: 'Referring',
      value: data['Referring'],
      percentageValue: percentageData['Referring'],
      color: '#4FC9AC',
      tooltipContent:
        'This Partner has started making referrals to Strike, but none of the referrals have become signed clients.',
    },
    {
      label: 'Client CPA',
      value: data['Client CPA'],
      percentageValue: percentageData['Client CPA'],
      color: '#5F84CD',
      tooltipContent:
        'This Partner has sent Strike a referral that has signed as a client.',
    },
    {
      label: 'Activated',
      value: data['Activated'],
      percentageValue: percentageData['Activated'],
      color: '#9280A9',
      tooltipContent:
        'This Partner has sent Strike a referral that has signed as a client.',
    },
    {
      label: 'Inactive',
      value: data['Inactive'],
      percentageValue: percentageData['Inactive'],
      color: '#7D7882',
      tooltipContent:
        'This Partner has gone silent and/or has not made a referral to Strike in 6+ months.',
    },
    {
      label: 'Not Qualified',
      value: data['Not Qualified'],
      percentageValue: percentageData['Not Qualified'],
      color: '#BDBDBD',
      tooltipContent:
        'This Partner was deemed as not a good referral partner for Strike.',
    },
  ];

  const allLabels = formattedData.map(d => d.label);
  const allValues = formattedData.map(d => d.value || 0);
  const allColors = formattedData.map(d => d.color);
  const allPercentageValues = formattedData.map(d => d.percentageValue || 0);

  const hasData = !isEmpty(percentageData);

  const doughnutData = {
    options: {
      responsive: false,
      max: 100,
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          generateLabels: () => {
            return formattedData.map((item, index) => {
              return {
                text: `${item.label} (${item.percentageValue?.toFixed(0)}%)`,
                fillStyle: item.color,
                strokeStyle: '#fff',
                lineWidth: 0,
                hidden: item.value <= 0,
                index,
              };
            });
          },
          filter: a => {
            const val = formattedData[a.index];
            return val.value > 0;
          },
        },
        onClick: () => {
          return false;
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      plugins: {
        emptyDoughnut: {
          color: '#7D7882',
          width: 2,
          radiusDecrease: 20,
        },
        labels: { render: 'value', fontColor: 'white' },
      },
      tooltips: {
        callbacks: {
          label(item) {
            return `${allLabels[item.index]} (${
              allPercentageValues[item.index]
            }%): ${allValues[item.index]}`;
          },
        },
      },
    },
    data: {
      labels: allLabels,
      datasets: [
        {
          data: allValues,
          backgroundColor: allColors,
          label: 'Dataset 1',
          borderWidth: 0,
        },
      ],
    },
    plugins: [
      {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
          const { datasets } = chart.data;
          const { color, width, radiusDecrease } = options;
          let hasData = datasets[0]?.data?.filter(Boolean)?.length > 0 || false;
          if (!hasData) {
            const {
              chartArea: { left, top, right, bottom },
              ctx,
            } = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            const r = Math.min(right - left, bottom - top) / 2;

            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || '#7D7882';
            ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
            ctx.stroke();
          }
        },
      },
    ],
  };

  return (
    <Card className={classes.cardWrapper}>
      <div className={classes.pieChart}>
        <div>
          <p>
            Your Partners by Status
            {hasData && (
              <img
                id="status-chart-tooltip"
                src={InfoCircle}
                className="ml-1"
                alt="InfoCircle"
              />
            )}
          </p>
          {hasData && (
            <UncontrolledTooltip
              hideArrow
              boundariesElement="viewport"
              autohide={false}
              className={cx('custom-tooltip', classes.tooltipCustom)}
              placement="right"
              target="status-chart-tooltip"
            >
              {formattedData.map(({ label, tooltipContent, color }, key) => {
                return (
                  <div
                    className={cx(classes.tooltipPoints, {
                      [classes.hide]: !data[label],
                    })}
                    key={key}
                  >
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                        backgroundColor: color,
                        marginRight: 5,
                      }}
                    ></div>
                    <div style={{ width: 'calc(100% - 10px)' }}>
                      <p>
                        <span className={classes.bold}>{label}</span> -{' '}
                        {tooltipContent}
                      </p>
                    </div>
                  </div>
                );
              })}
            </UncontrolledTooltip>
          )}
        </div>
        {hasData ? (
          <Doughnut
            height={190}
            data={doughnutData.data}
            options={doughnutData.options}
            plugins={doughnutData.plugins}
            className="chart-canvas"
            id="partners-by-status"
          />
        ) : (
          <NoDataPlaceholder showEmptyStateHeader={false} />
        )}
      </div>
    </Card>
  );
};

export default PartnerStatus;
