import React from 'react';
import classes from '../TaxReturnsForm.module.scss';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { checkValidToRender } from './formikFields';

export const FieldWrapper = ({ style, children, ...props }) => {
  return (
    <div
      {...props}
      className={cn(
        (props?.className || '')
          .split(' ')
          .reduce(
            (prevValue, currentValue) =>
              prevValue + ` ${classes[currentValue]}`,
            ''
          )
      )}
      style={{ ...style, paddingLeft: (style?.paddingLeft || 0) + 'px' }}
    >
      {children}
    </div>
  );
};

export const GenerateLabel = ({
  obj,
  style = {},
  name,
  dependsOn = [],
  className,
}) => {
  const { values } = useFormikContext();

  const shouldRender = checkValidToRender({ values, dependsOn });

  if (!shouldRender) {
    return null;
  }
  if (obj.type === 'inline_group') {
  }
  return (
    <FieldWrapper className={className} style={style}>
      <label>
        {obj.label}
        {obj?.required && (
          <span style={{ color: '#FB6440', marginLeft: '2px' }}>*</span>
        )}
      </label>
    </FieldWrapper>
  );
};
