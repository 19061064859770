import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Card, CardBody } from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import BaseTable from 'components/Table';
import InvitePartnerForm from 'views/pages/Partners/InvitePartner.Form';
import classes from './partners.module.scss';
import Button from 'components/Button';
import {
  cancelInvitation,
  deletePartner,
  fetchPartnerList,
  invitePartner,
  updatePartnerClient,
  resendInvitation,
} from 'store/actions/partners';

import {
  impersonateUser,
  exitImpersonateUser,
  impersonateUserWithPartnerId,
} from 'store/actions/users';
import { AlertPopupHandler } from 'components/AlertPopup';
import Dropdowns from 'components/Dropdowns';
import { withTime } from 'helpers/times';
import history from 'helpers/history';
import { roles } from 'helpers/permission';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import LeadModal from 'views/pages/dashboards/PartnerDashboard/LeadModal';

const Partners = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isModal, setModal] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resentUsers, setResentUsers] = useState([]);
  const [loadingFor, setLoadingFor] = useState(null);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });

  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [parentId, setParentId] = useState('');
  const [modalType, setModalType] = useState('referral');
  const [currentTab, setCurrentTab] = useState('comment');

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  const partnerState = useSelector(({ partner }) => partner.partnerState);
  const createReducer = useSelector(({ partner }) => partner.invitePartner);
  const deleteReducer = useSelector(({ partner }) => partner.deleteClient);
  const editReducer = useSelector(({ partner }) => partner.editClientProfile);
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const isClient = useSelector(({ auth }) => auth.user.is_client);
  const isPartner = useSelector(({ auth }) => auth.user.is_partner);
  const partnerData = get(partnerState, 'data.data', []);
  const partnerMetadata = get(partnerState, 'data', {});
  const listLoading = get(partnerState, 'isInProgress', false);
  const createLoading = get(createReducer, 'isInProgress', false);
  const editLoading = get(editReducer, 'isInProgress', false);
  const deleteLoading = get(deleteReducer, 'isInProgress', false);

  useEffect(() => {
    const { lead = '', tab = '', type = '', parent = '' } = queryProps;
    if (lead && !openLeadModal) {
      setLeadId(lead);
      setCurrentTab(tab);
      setModalType(type);
      setParentId(parent);
      setOpenLeadModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps.lead]);

  if (isClient || isPartner) {
    history.push('/admin/dashboard');
  }

  const NoDataIndication = () => {
    return (
      <div className="d-flex justify-content-between align-items-center flex-column w-100">
        <span className="display-4">No records found!</span>
      </div>
    );
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const closeModal = () => {
    setModal(false);
    setEditValues(null);
  };

  const handleTableChange = async (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      setPage(1);
    }
    const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
    await dispatch(fetchPartnerList(page, sort, searchText));
  };

  const submitValues = async values => {
    setLoading(true);
    if (editValues?.user_id) {
      const { status } = await dispatch(
        updatePartnerClient(values, editValues?.user_id)
      );
      if (status) {
        const sort =
          sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
        await dispatch(fetchPartnerList(page, sort, ''));
      }
    } else {
      const { status } = await dispatch(invitePartner(values));
      if (status) {
        const sort =
          sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
        await dispatch(fetchPartnerList(page, sort, ''));
      }
    }
    closeModal();
    setLoading(false);
  };

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;

    await dispatch(deletePartner(data.user_id));
    dispatch(fetchPartnerList(page, sort, ''));
    return false;
  };

  const deleteAction = row => e => {
    e.preventDefault();
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Remove Partner',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const EditAction = row => e => {
    setEditValues(row);
    setModal(true);
  };

  const ImpersonateAction = row => async e => {
    e.preventDefault();
    if (row.status !== 'Active') {
      await dispatch(impersonateUserWithPartnerId(row));
    } else {
      const resp = await dispatch(impersonateUser(row.user_id));
      const impersonatedUser = resp.data;
      if (impersonatedUser.is_client && impersonatedUser.is_partner) {
        window.location.href = '/admin/referral/clients';
        return;
      }
    }
    window.location.href = '/admin/dashboard';
  };

  const confirmCancelInvitation = async id => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    await dispatch(cancelInvitation(id, { page, sort, q: '' }));
  };

  const handleCancelInvitation = async id => {
    AlertPopupHandler.open({
      onConfirm: () => confirmCancelInvitation(id),
      confirmBtnText: 'Cancel Invite',
      text:
        'This partner will no longer be able to accept the invite and join your company. Do you want to continue?',
      data: id,
    });
  };

  const handleResendInvitation = async id => {
    setLoadingFor(id);
    await dispatch(resendInvitation(id));
    setResentUsers([...resentUsers, id]);
    setLoadingFor(null);
  };

  const getDropdownOptions = row => {
    switch (row.status) {
      case 'Active':
        return [
          {
            text: 'Impersonate User',
            onClick: ImpersonateAction(row),
          },
          {
            text: 'Edit User',
            onClick: EditAction(row),
          },
          {
            text: 'Remove User',
            onClick: deleteAction(row),
          },
        ];
      case 'Pending':
        return [
          {
            text: 'Impersonate User',
            onClick: ImpersonateAction(row),
          },
          {
            text: 'Cancel Invite',
            onClick: () => handleCancelInvitation(row.user_id),
          },
        ];
      default:
        return [
          {
            text: 'Impersonate User',
            onClick: ImpersonateAction(row),
          },
        ];
    }
  };

  const renderJoinedAt = (cell, row) => {
    const inviteStatus = row.status;

    if (row.contact_status === 'Do not contact') {
      return '-';
    } else {
      switch (inviteStatus) {
        case 'Active':
          return (
            <div className="d-flex justify-content-between w-100 align-items-center">
              {withTime(cell, { tz: userTimezone, fromNow: true })}
            </div>
          );
        case 'Pending': {
          if (resentUsers.findIndex(u => u === row.user_id) === -1) {
            return (
              <Button
                className="text-nowrap"
                color="primary"
                size="sm"
                disabled={loadingFor === row.id}
                loading={loadingFor === row.id}
                onClick={() => handleResendInvitation(row.user_id)}
              >
                Resend Invite
              </Button>
            );
          } else {
            return (
              <div className="d-flex justify-content-between w-100 align-items-center">
                Invitation resent
              </div>
            );
          }
        }
        default: {
          return (
            <Button
              color="primary"
              size="sm"
              disabled={loadingFor === row.id}
              loading={loadingFor === row.id}
              onClick={async () => {
                const { id } = row;
                const { status } = await dispatch(invitePartner(id));
                if (status) {
                  const sort =
                    sortBy.order === 'desc'
                      ? `-${sortBy.dataField}`
                      : sortBy.dataField;
                  await dispatch(fetchPartnerList(page, sort, ''));
                }
              }}
            >
              Invite
            </Button>
          );
        }
      }
    }
  };

  return (
    <>
      {isModal ? (
        <InvitePartnerForm
          isModalOpen={isModal}
          submitValues={submitValues}
          closeModal={closeModal}
          editValues={editValues}
          loading={loading}
        />
      ) : null}
      {openLeadModal ? (
        <LeadModal
          parentId={parentId}
          leadId={leadId}
          isOpen={openLeadModal}
          modalType={modalType}
          closeModal={() => {
            setOpenLeadModal(false);
            history.push(`?`);
          }}
          currentTab={currentTab}
          comment={queryProps?.comment}
        />
      ) : null}
      <div className={classes.partners}>
        <CardsHeader name="Partners" isRoot={true} />
        <div className="px-4">
          <Card>
            <CardBody className="p-0">
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                search={true}
                // headerButtonText="Invite New Partner"
                // headerButtonClassName={classes.headerButton}
                // headerButtonAction={() => setModal(true)}
                bordered={false}
                loading={
                  listLoading || createLoading || editLoading || deleteLoading
                }
                paginationOptions={{
                  page: page,
                  totalSize: partnerMetadata.total,
                  sizePerPage: parseInt(partnerMetadata.per_page),
                }}
                data={partnerData}
                columns={[
                  {
                    dataField: 'name',
                    text: 'PARTNER NAME',
                    sort: true,
                    classes: classes.partnerName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center text-nowrap">
                        {`${row.first_name} ${row.last_name}`}
                      </div>
                    ),
                    headerStyle: {
                      width: '220px',
                    },
                  },
                  {
                    dataField: 'email',
                    text: 'EMAIL ADDRESS',
                    sort: true,
                    classes: classes.partnerName,
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div className="d-flex justify-content-between w-100 align-items-center text-nowrap">
                        {cell ? cell : '-'}
                      </div>
                    ),
                    headerStyle: {
                      width: '330px',
                    },
                  },
                  {
                    dataField: 'company',
                    text: 'COMPANY NAME',
                    sort: true,
                    classes: classes.partnerName,
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div className="d-flex justify-content-between w-100 align-items-center text-nowrap">
                        {cell ? cell : '-'}
                      </div>
                    ),
                    headerStyle: {
                      width: '265px',
                    },
                  },
                  {
                    dataField: 'contact_status',
                    text: 'CONTACT STATUS',
                    sort: false,
                    classes: classes.partnerName,
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div className="d-flex justify-content-between w-100 align-items-center text-nowrap">
                        {cell ? cell : '-'}
                      </div>
                    ),
                    headerStyle: {
                      width: '125px',
                    },
                  },
                  {
                    dataField: 'status',
                    text: 'STATUS',
                    sort: true,
                    classes: classes.partnerName,
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : 'New'}
                      </div>
                    ),
                    headerStyle: {
                      width: '125px',
                    },
                  },
                  {
                    dataField: 'joined_at',
                    text: 'JOINED',
                    sort: true,
                    classes: classes.partnerName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          {renderJoinedAt(cell, row)}
                          <span>
                            <Dropdowns
                              className=" text-light ml-1"
                              text={<i className="fas fa-ellipsis-v" />}
                              size="sm"
                              caret={false}
                              role="button"
                              color=""
                              options={getDropdownOptions(row)}
                            />
                          </span>
                        </div>
                      );
                    },
                    headerStyle: {
                      width: '200px',
                    },
                  },
                ]}
                onTableChange={handleTableChange}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Partners;
