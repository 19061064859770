import React, { useState, useMemo } from 'react';
import { Button } from 'reactstrap';
import Modal from 'components/FormFields/Modal';
import classes from './partnerOnBoarding.module.scss';
import PopUpReferralsImg from 'assets/img/images/min/Popupreferrals.png';
import PopUpClientImg from 'assets/img/images/min/Popupclients.png';
import PopUpClientRevenueShareImg from 'assets/img/images/min/Popupclients-revshare.png';
import PopUpEarningImg from 'assets/img/images/min/Popupearnings.png';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import split from 'lodash/split';
import { setUserPreference } from 'store/actions/profile';

const PartnerOnBoarding = ({ isOpen }) => {
  const [modalCount, setModalCount] = useState(1);
  const dispatch = useDispatch();

  const userName = useSelector(({ auth }) => {
    return (
      get(auth, 'user.first_name', '') || split(get(auth, 'user.name'), ' ')[0]
    );
  });

  const seeEarnings = useSelector(({ auth }) =>
    get(auth, 'user.see_earning', true)
  );
  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const showOnboardingTour = useMemo(() => {
    const onboarding = preferences.find(
      p =>
        p.category === 'partner_onboarding' &&
        p.key === 'partner_onboarding_complete'
    );
    if (onboarding && String(onboarding.value) === 'true') {
      return false;
    }
    return true;
  }, [preferences]);

  const handleModalCount = () => {
    if (!seeEarnings && modalCount === 3) {
      setModalCount(modalCount + 2);
    } else {
      setModalCount(modalCount + 1);
    }
  };

  const updateQuickStart = e => {
    e.stopPropagation();
    dispatch(
      setUserPreference({
        category: 'partner_onboarding',
        key: 'partner_onboarding_complete',
        value: 'true',
      })
    );
  };

  return (
    <>
      {showOnboardingTour && (
        <>
          {modalCount === 1 && (
            <Modal
              isOpen={isOpen}
              className={classes.modalWrapper2}
              centered
              noHeader
            >
              <div className={classes.title}>Welcome, {userName}!</div>
              <div className={classes.description}>
                We understand that new can mean confusing. Let’s help speed up
                the process by showing you the basics!
              </div>
              <div className={classes.btn}>
                <Button color="primary" onClick={handleModalCount}>
                  Next
                </Button>
              </div>
            </Modal>
          )}
          {modalCount === 2 && (
            <Modal
              isOpen={isOpen}
              className={classes.modalWrapper}
              centered
              noHeader
            >
              <div className={classes.title}>Referrals </div>
              <div className={classes.description}>
                The Referrals tab will be the place to track progress and
                communicate with the Strike team. Once you begin referring,
                you’ll see helpful metrics and reporting to stay informed.
              </div>
              <div className={classes.imgContainer}>
                <img src={PopUpReferralsImg} />
              </div>
              <div className={classes.btn}>
                <Button color="primary" onClick={handleModalCount}>
                  Next
                </Button>
              </div>
            </Modal>
          )}
          {modalCount === 3 && (
            <Modal
              isOpen={isOpen}
              className={classes.modalWrapper}
              centered
              noHeader
            >
              <div className={classes.title}>Clients </div>
              <div className={classes.description}>
                The Clients page will present a breakdown of all successfully
                referred Clients. This will show you which phase they are
                currently at in Strike’s workflow and your potential earnings.
              </div>
              <div className={classes.imgContainer}>
                <img
                  src={
                    seeEarnings ? PopUpClientRevenueShareImg : PopUpClientImg
                  }
                />
              </div>
              <div className={classes.btn}>
                <Button color="primary" onClick={handleModalCount}>
                  Next
                </Button>
              </div>
            </Modal>
          )}
          {modalCount === 4 && (
            <Modal
              isOpen={isOpen}
              className={classes.modalWrapper}
              centered
              noHeader
            >
              <div className={classes.title}>Earnings Page</div>
              <div className={classes.description}>
                The Earnings page will present a breakdown of all current and
                potential earnings, as well as helpful metrics to track the
                outcome of your referrals.
              </div>
              <div className={classes.imgContainer}>
                <img src={PopUpEarningImg} />
              </div>
              <div className={classes.btn}>
                <Button color="primary" onClick={handleModalCount}>
                  Next
                </Button>
              </div>
            </Modal>
          )}
          {modalCount === 5 && (
            <Modal
              isOpen={isOpen}
              className={classes.modalWrapper5}
              centered
              style={{ minWidth: '620px' }}
              noHeader
            >
              <div className={classes.title}>It does the work for you.</div>
              <div className={classes.description}>
                Once you start referring, the Strike Portal will make it easy to
                view all the information you need to help keep track of
                everything.
                <br />
                <br />
                What are you waiting for? Let’s get started!
              </div>
              <div className={classes.btn}>
                <Button
                  color="primary"
                  onClick={e => {
                    handleModalCount();
                    updateQuickStart(e);
                  }}
                >
                  Let's go!
                </Button>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default PartnerOnBoarding;
