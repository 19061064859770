import React from 'react';
import classes from './Question.module.scss';
import Button from 'components/Button';
import cx from 'classnames';

const Question = ({
  question,
  options = [],
  required,
  selectedValue,
  setSelectedValue,
  disabled,
  completed,
  optionClassName = '',
}) => {
  return (
    <div className={classes.questionWrapper}>
      <div className={classes.question}>
        {question}:{required && <span className={classes.required}>*</span>}
      </div>
      <div className={classes.optionsWrapper}>
        {options.map((option, index) => {
          return (
            <Button
              type="button"
              key={index}
              name={option?.value}
              value={selectedValue || option?.value}
              onClick={() => {
                setSelectedValue(option?.value);
              }}
              className={cx(classes.option, {
                [classes.selectedOption]: selectedValue === option?.value,
                [classes.disabledOption]:
                  disabled && selectedValue !== option?.value,
                [classes.disabledSelected]:
                  disabled && selectedValue === option?.value,
                [optionClassName]: !!optionClassName,

                [classes.completedOption]:
                  completed && selectedValue !== option?.value,
                [classes.completedSelected]:
                  completed && selectedValue === option?.value,
                [optionClassName]: !!optionClassName,
              })}
            >
              {option?.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Question;
