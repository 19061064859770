import React from 'react';
import { Modal } from 'reactstrap';
import { InlineWidget } from 'react-calendly';
import classes from './Sidebar.module.scss';
import { ReactComponent as CloseIcon } from 'assets/img/icons/close-icon.svg';
import Loading from 'components/Loading';

const CalendlyModal = ({ isOpen, modalClose, isLoading, link }) => {
  return (
    <Modal
      fade={false}
      modalClassName={classes.modalClassName}
      contentClassName={classes.calendlyModal}
      size="lg"
      centered
      id="submitModal"
      isOpen={isOpen}
    >
      <div onClick={modalClose} className={classes.close}>
        <CloseIcon />
      </div>
      {isLoading ? (
        <Loading />
      ) : link ? (
        <InlineWidget
          LoadingSpinner={() => {
            return <Loading />;
          }}
          url={link}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default CalendlyModal;
