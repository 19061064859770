import React, { useEffect, useState } from 'react';
import classes from './TaxReturns.module.scss';
import Loading from 'components/Loading';
import { Breadcrumb, BreadcrumbItem, Card, CardHeader } from 'reactstrap';
import Button from 'components/Button';
import Viewer from 'react-file-viewer';
import { ReactComponent as CommentIcon } from 'assets/img/icons/comment-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { previewDocument } from 'api/documents';
import { ReactComponent as BackIcon } from 'assets/img/icons/chevron-left-icon.svg';
import { useHistory } from 'react-router';
import cx from 'classnames';
import get from 'lodash/get';
import FileValidationSection from './components/FileValidationSection';
import {
  getTaxReturnStatus,
  getFormTypes,
  getFilledForm,
} from 'store/actions/taxReturns';

const TaxReturns = () => {
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const getFormsLoading = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'formTypes.isInProgress', true);
  });
  const formStatusLoading = useSelector(({ taxReturns }) => {
    return get(taxReturns, 'status.isInProgress', true);
  });

  const getForm = async status => {
    if (!status.fully_intact) {
      return;
    }
    await dispatch(getFormTypes());
  };
  useEffect(() => {
    if (docs?.file.id) {
      dispatch(getFilledForm(docs?.file.id));
      dispatch(getTaxReturnStatus(docs?.file.id, getForm));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs?.file.id]);

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const name = get(currentStudy, 'name', '');
  const currentFile = useSelector(({ taxReturns }) =>
    get(taxReturns, 'currentFile.data', null)
  );

  const convertToBase64 = async url => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null;
    }
  };

  const getFileType = name => {
    const extension = name.split('.').pop();
    const type = extension.toLowerCase();
    return type;
  };

  useEffect(() => {
    setLoading(true);
    if (currentFile) {
      const fetch = async () => {
        const getData = async file => {
          const rs = await previewDocument(file?.id);
          if (rs?.status) {
            return { url: rs?.url, file: file };
          }
        };

        const fileData = await getData(currentFile);
        const base64 = await convertToBase64(fileData.url);
        setDocs({
          url: base64,
          file: currentFile,
        });
        setLoading(false);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <BackIcon
            className={classes.leftIcon}
            onClick={() => history.goBack()}
          />
          <div className={classes.studyName}>Tax Returns</div>
          <Breadcrumb
            className={cx('d-none d-md-inline-block md-4', classes.breadcrumb)}
            listClassName="breadcrumb-links"
          >
            <BreadcrumbItem>
              <div style={{ display: 'flex' }}>
                <span className={classes.dashIcon}>&nbsp; - &nbsp;</span>
                <div className={classes.rightStudyName}>
                  {name ? name : '-'} - Tax Returns
                </div>
              </div>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      {loading || getFormsLoading || formStatusLoading ? (
        <Loading />
      ) : (
        docs && (
          <Card>
            <CardHeader className={classes.cardHeader}>
              <h3 className="mb-0">{currentFile?.name}</h3>
              <div>
                <Button
                  className={classes.chatButton}
                  type="button"
                  leftIcon={<CommentIcon />}
                  onClick={() => {}}
                >
                  Chat(2)
                </Button>
              </div>
            </CardHeader>
            <div className={classes.fileContainer}>
              <div className={classes.fileViewerWrapper}>
                <Viewer
                  fileType={getFileType(docs.file.name)}
                  filePath={docs.url}
                />
              </div>
              <FileValidationSection currentDocumentId={docs?.file.id} />
            </div>
          </Card>
        )
      )}
    </div>
  );
};

export default TaxReturns;
