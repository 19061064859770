import React from 'react';
import { GenerateLabel } from './components';
import { generateComponentSchema, getYupObjectSchema } from './schema';
import { getDependsOnObject } from './utils';

const excludedTypeToCreateField = [
  'group',
  'timeline',
  'inline_group',
  'critical_info_label',
];
const excludeGroupToApplyPadding = ['financials', 'entity_information'];

/**
 * generate yup schema & components order with styles/hide
 * @returns {
 *    schema: yup object schema
 *    component: Field component
 * }
 */
export const generateSchemaComponentFromJSON = (schemaObject, isCompleted) => {
  let orderIndex = 0;
  const forEachObject = ({
    fields,
    style = {},
    name = '',
    additionalDepends = [],
    notHavingName,
    additionalClassName = '',
    insideGroup,
    isTimeline,
  }) => {
    const generatedSchema = {}; // store yup schema by group
    const generatedComponent = {}; // store components by group
    let extraResponse = null; // store component-schema to store in parent group

    fields.forEach(obj => {
      let currentOrderIndex = orderIndex++;

      let currentClassName = additionalClassName;
      const currentObjectName =
        obj.type === 'critical_info_label'
          ? 'critical_info_label'
          : obj.name || '';
      const groupExist = !!obj.group && name.split('.').includes(obj.group);

      const isMultiple = obj.multiple;

      const isSameGroup = obj.group === obj.name && !isMultiple;
      // field name for nested field to handle with yup.
      const preFixName = name
        ? name + (obj.group && !groupExist ? '.' + obj.group : '')
        : '';
      const customName =
        obj.type === 'inline_group' ||
        !currentObjectName ||
        (!!obj.group && !isSameGroup)
          ? preFixName
          : (preFixName ? preFixName + '.' : '') + currentObjectName;
      const parentName = [...customName.split('.')].splice(-1)[0];
      const finalName = currentObjectName || parentName;

      let dependsOn = [];
      if (obj.depends_on) {
        dependsOn = [...getDependsOnObject(schemaObject, obj.depends_on)];
      }

      const passAdditionalDepends = [...additionalDepends, ...dependsOn];
      const isInlineGroup = obj.type === 'inline_group';
      if (obj.type === 'timeline') {
        currentClassName = currentClassName + ' ' + 'timeline-group';
      }

      if (obj.type === 'group') {
        style = {
          ...style,
          // paddingLeft: (style.paddingLeft || 0) + 10,
        };
        currentClassName = currentClassName + ' ' + 'group';
      }
      if (obj.type === 'critical_info_label') {
        style = {};
        currentClassName = currentClassName + ' ' + 'critical_info_label';
      }

      if ((obj?.fields || []).length) {
        // group of fields.
        // create schema-component for nested fields.
        const applyPadding =
          obj.group && !excludeGroupToApplyPadding.includes(obj.group);
        const forEachResponse = forEachObject({
          fields: obj.fields,
          style: {
            ...style,
            ...(applyPadding
              ? { paddingLeft: (style.paddingLeft || 0) + 40 }
              : {}),
          },
          name: customName,
          additionalDepends: passAdditionalDepends,
          notHavingName: !currentObjectName,
          additionalClassName:
            currentClassName + (isInlineGroup ? ' inline-group' : ''),
          insideGroup: !!(insideGroup || obj.group),
          isTimeline: obj.type === 'timeline', // handling timeline group for their fields
        });

        // generating component & yup schema for the current field
        const shouldCreateSchemaComponent =
          obj.type &&
          (!excludedTypeToCreateField.includes(obj.type) || isMultiple);
        const currentComponentSchema = shouldCreateSchemaComponent
          ? generateComponentSchema(
              obj,
              {
                ...style,
                // paddingLeft: (style.paddingLeft || 0) + 10,
              },
              // need to create field for the self object with prefix "is"
              obj.group && !isSameGroup && !isMultiple
                ? customName
                  ? customName + '.' + obj.name
                  : obj.name
                : customName,
              passAdditionalDepends,
              currentClassName +
                (obj.group && !groupExist && isTimeline ? ' title' : ''), // className for group fields or any other can add.
              forEachResponse.extraResponse
            )
          : {
              Component: obj.label ? (
                <GenerateLabel
                  obj={obj}
                  name={customName}
                  dependsOn={dependsOn}
                  className={
                    currentClassName +
                    (isInlineGroup ? ' inline-group-title' : ' ')
                  }
                />
              ) : (
                <></>
              ),
              schema: {},
            };

        const getValue = () => {
          const schema = {
            ...Object.assign(
              shouldCreateSchemaComponent
                ? {
                    [finalName]: currentComponentSchema.schema,
                  }
                : {}
            ),
            fields: {
              ...forEachResponse.schema,
              ...(forEachResponse.extraResponse?.schema || {}),
            },
          };
          const component = {
            Component: currentObjectName ? (
              currentComponentSchema.Component
            ) : (
              <></>
            ),
            order: currentOrderIndex,
            fields: {
              ...forEachResponse.Component,
              ...(forEachResponse.extraResponse?.Component || {}),
            }, // merge multiple fields for the same parent. ex: form_3800_exists fields,
          };
          return {
            component,
            schema: schema,
          };
        };

        /**
         * flat nested group
         * ex: form_3800: {form_3800_exists:false,total_rnd_carry_forwards:'',is_esb_carry_forward:'' }
         * store those in extraResponse & merge with the parent group
         */
        const { component, schema } = getValue();

        const { fields = {}, ...rest } = component;
        const { fields: schemaFields = {}, ...schemaRest } = schema;
        if (
          isInlineGroup ||
          (insideGroup && !obj.group) ||
          !currentObjectName
        ) {
          extraResponse = {
            ...forEachResponse,
            ...extraResponse,
            schema: {
              ...(isInlineGroup ? schemaFields : {}),
              ...forEachResponse.schema,
              ...(extraResponse?.schema || {}),
            },
            Component: {
              ...(isInlineGroup ? { [finalName]: rest, ...fields } : {}),
              ...forEachResponse.Component,
              ...(extraResponse?.Component || {}),
            },
          };
        } else {
          if (finalName) {
            if (obj.group) {
              if (isMultiple) {
                generatedComponent[finalName] = {
                  Component: component.Component,
                  order: currentOrderIndex,
                };
                generatedSchema[finalName] = schema[finalName];
              } else if (groupExist) {
                extraResponse = {
                  Component: { [finalName]: rest, ...fields },
                  schema: { ...schemaRest, ...schemaFields },
                };
              } else {
                generatedComponent[obj.group] = {
                  fields: { ...fields, [finalName]: rest },
                };
                generatedSchema[obj.group] = getYupObjectSchema({
                  ...schemaFields,
                  ...schemaRest,
                });
                // generatedComponent[obj.group][finalName] = rest;
              }
            } else {
              generatedComponent[finalName] = component;
              generatedSchema[finalName] = getYupObjectSchema({
                ...schemaFields,
                ...schemaRest,
              });
            }
          }
        }
      } else {
        // generate single field schema & component.
        const created = generateComponentSchema(
          obj,
          {
            ...style,
            // paddingLeft: (style.paddingLeft || 0) + 10,
          },
          customName,
          passAdditionalDepends,
          currentClassName +
            (obj.group && !groupExist && isTimeline ? ' title' : '')
        );
        generatedSchema[finalName] = created.schema;
        generatedComponent[finalName] = {
          Component: created.Component,
          order: currentOrderIndex,
        };
      }
    });

    return {
      schema: generatedSchema,
      Component: generatedComponent,
      notHavingName,
      extraResponse,
    };
  };
  return forEachObject({
    fields: schemaObject,
    additionalClassName: isCompleted ? 'completed' : '',
  });
};
