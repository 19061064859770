import Types from '../types/partnerTeams';
import {
  fetchPartnerTeammates,
  fetchRoles,
  fetchStatuses,
  inviteTeamMember as inviteTeamMemberApi,
  updateTeamMember as updateTeamMemberApi,
  fetchMember as fetchMemberApi,
  postMemberStatus as postMemberStatusApi,
} from 'api/partnerTeams';
import { get } from 'lodash';
import { NotificationHandler } from 'components/Notifications';
export const fetchPartnerTeams = (page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNER_TEAM_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchPartnerTeammates(page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTNER_TEAM_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTNER_TEAM_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNER_TEAM_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchPartnerTeamRoles = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNER_TEAM_ROLE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchRoles();

      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');

      if (status) {
        dispatch({
          type: Types.FETCH_PARTNER_TEAM_ROLE_SUCCESS,
          data: data || [],
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTNER_TEAM_ROLE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNER_TEAM_ROLE_FAILURE,
        message: error,
      });
    }
  };
};
export const fetchPartnerTeamStatuses = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNER_TEAM_STATUS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchRoles();

      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');

      if (status) {
        dispatch({
          type: Types.FETCH_PARTNER_TEAM_STATUS_SUCCESS,
          data: data || [],
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTNER_TEAM_STATUS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNER_TEAM_STATUS_FAILURE,
        message: error,
      });
    }
  };
};
export const inviteTeamMember = data => {
  return async dispatch => {
    dispatch({
      type: Types.POST_INVITE_TEAM_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await inviteTeamMemberApi(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.POST_INVITE_TEAM_MEMBER_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.POST_INVITE_TEAM_MEMBER_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.POST_INVITE_TEAM_MEMBER_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateTeamMember = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_TEAM_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateTeamMemberApi(id, data);
      await dispatch(fetchMember(id));
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.UPDATE_TEAM_MEMBER_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.UPDATE_TEAM_MEMBER_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.UPDATE_TEAM_MEMBER_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const fetchMember = id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchMemberApi(id);

      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');

      if (status) {
        dispatch({
          type: Types.FETCH_MEMBER_SUCCESS,
          data: data || [],
        });
      } else {
        dispatch({
          type: Types.FETCH_MEMBER_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MEMBER_FAILURE,
        message: error,
      });
    }
  };
};

export const postMemberStatus = (userId, currentStatus) => {
  return async dispatch => {
    dispatch({
      type: Types.POST_MEMBER_STATUS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await postMemberStatusApi(userId, currentStatus);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.POST_MEMBER_STATUS_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.POST_MEMBER_STATUS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.POST_MEMBER_STATUS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};
